import { useAtomValue } from 'jotai'
import { ContentWrapper } from '../../components/content-wrapper/content-wrapper'
import { sideBarMenuAtom } from '../../components/header/header'
import clsx from 'clsx'
import { CardInfo } from '../../components/card-info/card-info'
import { useQuery } from '@tanstack/react-query'
import { TablePeopleDashboard } from '../../components/table-people-dashboard/table-people-dashboard'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/shared/components/ui/select'
import { SearchTextField } from '@/shared/components/ui/search-text-field'
import { useState } from 'react'
import { useGetDashboardPeople } from '@/shared/queries/dashboard/people/use-dashboard-people'
import {
  GetDashboardPeopleEngagementResponse,
  useGetDashboardPeopleEngagement,
} from '@/shared/queries/dashboard/people/use-people'
import useDebouncedState from '@/shared/hooks/use-debounced-state'
import { useGetDashboardPeopleTeams } from '@/shared/queries/dashboard/people/use-teams-people'
import { columns } from './columns'
import { CardInfoSkeleton } from '../../components/card-info-skeleton/card-info-skeleton'
import { ComponentError } from '@/shared/components/ui/component-error'
import { Data } from '@/features/dashboard/components/table-people-dashboard/table-people-dashboard'
import { Route as PeopleRoute } from '@/routes/_authenticated/dashboard/people/$peopleId'
import { useNavigate } from '@tanstack/react-router'

export function DashboardPeoples() {
  const sideBarMenu = useAtomValue(sideBarMenuAtom)
  const [selectedTeam, setSelectedTeam] = useState<string | undefined>('')
  const [currentPage, setCurrentPage] = useState(1)

  const {
    state: searchValue,
    setState: setSearchValue,
    debouncedState: debouncedSearch,
  } = useDebouncedState({
    initialState: '',
  })

  const [currentColumnFilter, setCurrentColumnFilter] = useState({
    sortBy: 'name',
    sort: 'asc',
  })

  const {
    data,
    isLoading: isLoadingDashboardPeople,
    isFetching: isFetchingDashboardPeople,
    isError: isErrorDashboardPeople,
  } = useQuery(
    useGetDashboardPeople({
      page: currentPage,
      name: debouncedSearch,
      teamId: selectedTeam,
      sort: currentColumnFilter.sort,
      sortBy: currentColumnFilter.sortBy,
    })
  )
  const { data: engagement } = useQuery(useGetDashboardPeopleEngagement())
  const { data: teams } = useQuery(useGetDashboardPeopleTeams())

  const handleTeamChange = (value: string) => {
    if (value === 'all') {
      setSelectedTeam(undefined)
    } else {
      setSelectedTeam(value)
    }
    setSearchValue('')
    setCurrentPage(1)
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    setSelectedTeam('')
    setCurrentPage(1)
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const totalPages = data ? data.totalPages : 1

  const currentSelectedTeam = teams?.data.find(
    (team) => team.id === selectedTeam
  )

  function renderCardInfo({
    isLoading,
    isError,
    engagement,
  }: {
    isLoading: boolean
    isError: boolean
    engagement: GetDashboardPeopleEngagementResponse | undefined
  }) {
    if (isError) {
      return (
        <>
          {[0, 1, 2].map((index) => (
            <div key={index} className="w-full">
              <ComponentError size={30} />
            </div>
          ))}
        </>
      )
    }

    if (isLoading) {
      return (
        <div className="flex w-full flex-col gap-2 xl:flex-row">
          {[0, 1, 2].map((index) => (
            <div key={index} className="w-full">
              <CardInfoSkeleton />
            </div>
          ))}
        </div>
      )
    }

    return (
      <>
        <CardInfo
          title="Usuários com estudo"
          value={engagement?.totalUsersStuding ?? 0}
        />
        <CardInfo
          title="Usuários com trilhas finalizadas"
          value={engagement?.totalProgramsCompleted ?? 0}
        />
        <CardInfo
          title="Usuários sem estudo"
          value={engagement?.totalUsersNotStuding ?? 0}
        />
      </>
    )
  }

  const navigate = useNavigate({
    from: PeopleRoute.fullPath,
  })
  const navigateToPeopleDetails = (row: Data) => {
    navigate({
      to: `${row.id}`,
    })
  }

  return (
    <ContentWrapper>
      <div className="flex w-full gap-3 lg:max-h-[483px] lg:flex-row xl:max-h-[411px]">
        <div
          className={clsx('flex h-full w-full flex-col gap-3', {
            'lg:w-full': sideBarMenu,
            'lg:full': !sideBarMenu,
          })}
        >
          <div className="flex flex-col gap-3 xl:flex-row">
            {renderCardInfo({
              engagement,
              isLoading: isLoadingDashboardPeople,
              isError: isErrorDashboardPeople,
            })}
          </div>
        </div>
      </div>

      <div
        className={clsx('flex w-full flex-col', {
          'border-0': isLoadingDashboardPeople,
          'rounded-xl border border-gray-100': !isLoadingDashboardPeople,
        })}
      >
        {!isLoadingDashboardPeople && !isErrorDashboardPeople ? (
          <div className="flex w-full flex-col items-center p-3 lg:flex-row lg:justify-between">
            <div className="flex h-full max-h-max w-full flex-col items-center justify-end gap-2 lg:h-8 lg:max-h-8 lg:flex-row">
              <div className="flex h-8 max-h-8 w-full lg:max-w-[250px]">
                <SearchTextField
                  onChange={handleSearchChange}
                  value={searchValue}
                  placeholder="Pesquisar"
                />
              </div>
              <div className="flex w-full flex-col gap-2 sm:flex-row lg:justify-end">
                <div className="flex w-full lg:w-auto">
                  <Select value={selectedTeam} onValueChange={handleTeamChange}>
                    <SelectTrigger className="max-h-[32px] min-h-[32px] min-w-[240px] max-w-[240px] rounded-md border border-gray-150 px-3 py-0">
                      <div className="flex w-full items-center justify-between gap-2">
                        <div className="flex items-center justify-start">
                          <span className="line-clamp-1 text-label-lg text-gray-300">
                            {currentSelectedTeam?.name ?? 'Times'}
                          </span>
                        </div>
                      </div>
                    </SelectTrigger>
                    <SelectContent className="flex min-w-[240px] max-w-[240px]">
                      <SelectItem
                        className={clsx('flex items-center px-3 py-1', {
                          'bg-blue-500 text-white hover:bg-blue-500':
                            selectedTeam === 'all',
                          'bg-white': selectedTeam !== 'all',
                        })}
                        key="default"
                        value="all"
                      >
                        Todos os times
                      </SelectItem>
                      {teams?.data.map((team) => (
                        <SelectItem
                          className={clsx(
                            'line-clamp-1 flex items-center px-3 py-1',
                            {
                              'bg-blue-500 text-white hover:bg-blue-500':
                                team.id === selectedTeam,
                              'bg-white': team.id !== selectedTeam,
                            }
                          )}
                          key={team.id}
                          value={team.id}
                        >
                          {team.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div
          className={clsx({
            'cursor-wait [&>*]:opacity-50': isFetchingDashboardPeople,
          })}
        >
          <TablePeopleDashboard
            onRowClick={navigateToPeopleDetails}
            columns={columns}
            data={data?.data ?? []}
            isError={isErrorDashboardPeople}
            isLoading={isLoadingDashboardPeople}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onFilterChange={({ sortBy }) => {
              const isSameFilter = sortBy === currentColumnFilter.sortBy
              const newSort = isSameFilter
                ? currentColumnFilter.sort === 'asc'
                  ? 'desc'
                  : 'asc'
                : 'asc'

              setCurrentColumnFilter({
                sortBy,
                sort: newSort,
              })
            }}
          />
        </div>
      </div>
    </ContentWrapper>
  )
}
