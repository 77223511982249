import { ChartContainer, ChartTooltip } from '@/shared/components/ui/chart'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { atom, useSetAtom } from 'jotai'

export const accessDialogAtom = atom(false)
export const selectedDateAtom = atom<string | null>(null)

interface ChartBarProps {
  data: { month: string; total: number; date: string }[] | []
  width?: number | string
  height?: number
  margin?: { left?: number; right?: number; top?: number; bottom?: number }
}

export function ChartBar({
  data,
  width = '100%',
  height = 350,
  margin = { left: -22, right: 6, top: 10, bottom: 0 },
}: ChartBarProps) {
  const setIsOpenAccessDialog = useSetAtom(accessDialogAtom)
  const setSelectedDate = useSetAtom(selectedDateAtom)

  const handleBarClick = (data: { payload: { date: string } }) => {
    const selectedDate = data.payload.date
    setSelectedDate(selectedDate)
    setIsOpenAccessDialog(true)
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="rounded border border-border bg-background p-2 shadow-md">
          <p className="text-body-sm text-gray-500">
            Dia: {payload[0].payload.date}
          </p>
          <p className="text-body-sm text-gray-400">
            Número de acessos: {payload[0].payload.total}
          </p>
        </div>
      )
    }
    return null
  }

  return (
    <ChartContainer
      config={{}}
      style={{
        width,
        height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} margin={margin}>
          <CartesianGrid vertical={false} />
          <defs>
            <linearGradient id="graphChartColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="18.72%" stopColor="#4C61B5" stopOpacity={1} />
              <stop offset="100%" stopColor="#2D4A8C" stopOpacity={1} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="month"
            tickLine={false}
            axisLine={false}
            tickMargin={10}
            tickFormatter={(value) => value}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            tickMargin={12}
            tickFormatter={(value) => (Number.isInteger(value) ? value : '')}
          />
          <ChartTooltip cursor={false} content={<CustomTooltip />} />
          <Bar
            className="cursor-pointer"
            dataKey="total"
            fill="url(#graphChartColor)"
            radius={4}
            onClick={(data) => handleBarClick(data)}
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  )
}
