import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import { keepPreviousData } from '@tanstack/react-query'

// Define the mock data structure using zod
const dashboardWatchedHoursResponseSchema = z.array(
  z.object({
    date: z.string(),
    hourCount: z.number(),
    userCount: z.number(),
    label: z.string(),
    miniLabel: z.string(),
  })
)

export type DashboardWatchedHours = z.infer<
  typeof dashboardWatchedHoursResponseSchema
>

export async function getDashboardWatchedHours({
  period,
}: {
  period: 'month' | 'year' | 'quarter'
}) {
  // Uncomment the following code to use the actual API call
  const url = apiUrls.dashboard.contents.getDashboardWatchedHours
  const token = Cookies.get('auth-cookie') ?? ''

  const params: Record<string, any> = { period }

  try {
    const { data } = await axios.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data)
    const parsedData = dashboardWatchedHoursResponseSchema.parse(data.data)
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetDashboardWatchedQueryKey =
  'get-dashboard-watched-hours-query-key'

export const useGetDashboardWatchedHours = ({
  period = 'month',
}: {
  period?: 'month' | 'year' | 'quarter'
}) => ({
  queryKey: [useGetDashboardWatchedQueryKey, period],
  queryFn: () => getDashboardWatchedHours({ period }),
  placeholderData: keepPreviousData,
})
