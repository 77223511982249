import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui/table'
import { PaginationDashboard } from '../pagination-dashboard/pagination-dashboard'
import { TableSkeleton } from './skeleton'
import { ComponentError } from '@/shared/components/ui/component-error'
import { useState } from 'react'
import { ExternalLink } from 'lucide-react'

type Category = {
  categoryId: string
  name: string
}

export type Data = {
  id: string
  name: string
  type: 'BOOK' | 'COURSE'
  banner: string
  author: string | null
  totalInProgress: number
  totalCompleted: number
  totalSubscribed: number
  categories: Category[]
}

export type Column = {
  header: string | (() => JSX.Element)
  accessor: string
  width?: string | '100%'
  render: (row: Data) => JSX.Element
}

type CustomTableProps = {
  columns: Column[]
  data: Data[]
  currentPage: number
  totalPages: number
  isLoading: boolean
  isError: boolean
  onPageChange: (page: number) => void
  onFilterChange: (params: { sortBy: string }) => void
  onRowClick: (row: Data) => void
}

export function TableFormationDashboard({
  columns,
  data,
  isLoading,
  currentPage,
  totalPages,
  isError,
  onPageChange,
  onFilterChange,
  onRowClick,
}: CustomTableProps) {
  const emptyRows = 10 - data.length

  if (isError)
    return (
      <div className="flex h-full min-h-[558px] flex-col">
        <ComponentError />
      </div>
    )

  if (isLoading) return <TableSkeleton />

  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null)

  return (
    <div className="min-h-[558px] overflow-x-auto">
      <Table className="min-w-full">
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHead
                key={column.accessor}
                onClick={(event) => {
                  onFilterChange({ sortBy: column.accessor })
                  event.stopPropagation()
                }}
                style={{ width: column.width, maxWidth: column.width }}
              >
                <span className="text-title-sm text-gray-400">
                  {typeof column.header === 'function'
                    ? column.header()
                    : column.header}
                </span>
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.length ? (
            data.map((row, rowIndex) => (
              <TableRow
                className={`cursor-pointer ${rowIndex === hoveredRowIndex ? 'bg-blue-50' : ''}`}
                key={rowIndex}
                onClick={() => onRowClick(row)}
                onMouseEnter={() => setHoveredRowIndex(rowIndex)}
                onMouseLeave={() => setHoveredRowIndex(null)}
              >
                {columns.map((column, colIndex) => (
                  <TableCell
                    key={column.accessor}
                    className="h-16 max-h-16 min-h-16 px-3"
                  >
                    <div className="flex items-center justify-between">
                      {column.render(row)}
                      {rowIndex === hoveredRowIndex && colIndex === 0 && (
                        <ExternalLink size={18} className="text-gray-500" />
                      )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className="h-32 border-b-0">
              <TableCell
                className="text-center align-middle"
                colSpan={columns.length}
              >
                <span className="text-body-md">
                  Nenhuma formação encontrada
                </span>
              </TableCell>
            </TableRow>
          )}
          {Array.from({ length: emptyRows }).map((_, index) => (
            <TableRow key={`empty-${index}`} className="border-b-0">
              {columns.map((column) => (
                <TableCell
                  key={`empty-${column.accessor}`}
                  className="h-12 max-h-12 min-h-12 border-b-0 px-3"
                >
                  &nbsp;
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={columns.length}>
              <PaginationDashboard
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  )
}
