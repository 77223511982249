import { ArrowDownUp } from 'lucide-react'
import { ChipGroupContent } from '../../components/chip-group-content/chip-group-content'
import { Column, Data } from '../../components/table-dashboard/table-dashboard'
import { BannerTableFormation } from './components/banner-table'

export const columns: Column[] = [
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-6">
        <ArrowDownUp size={18} />
        <span className="title-sm text-gray-400">Formações</span>
      </div>
    ),
    accessor: 'name',
    width: '40%',
    render: (row: Data) => <BannerTableFormation row={row} />,
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Categoria</span>
      </div>
    ),
    accessor: 'categories.name',
    width: '30%',
    render: (row: Data) => (
      <span>
        <ChipGroupContent
          chipItems={row.categories.map((category) => ({
            id: category.categoryId,
            name: category.name,
          }))}
          noItemLabel="Sem categoria"
        />
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Inscritos</span>
      </div>
    ),
    accessor: 'subscribed',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">{row.totalSubscribed}</span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Em andamento</span>
      </div>
    ),
    accessor: 'progress',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">{row.totalInProgress}</span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Concluídos</span>
      </div>
    ),
    accessor: 'completed',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">{row.totalCompleted}</span>
    ),
  },
]
