import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

const searchParamsSchema = z.object({
  redirect: z.string().optional(),
})

type SearchParams = z.infer<typeof searchParamsSchema>

export const Route = createFileRoute('/_not-authenticated/global-loading/')({
  validateSearch: (search: Record<string, unknown>): SearchParams =>
    searchParamsSchema.parse(search),
  onCatch: (error) => {
    console.error(error)
  },
})
