type CardInfoProps = {
  title: string
  value: number
  type?: 'hours' | 'number'
}

export function CardInfo({ title, value, type = 'number' }: CardInfoProps) {
  const isTypeHours = type === 'hours'
  return (
    <div className="flex h-full max-h-[83px] w-full flex-col rounded-xl border border-gray-100 p-4 shadow-sm">
      <div className="flex h-full w-full flex-col gap-2">
        <span className="text-body-xs text-gray-500">{title}</span>
        <span className="text-headline-xs text-gray-500">
          {value}
          {isTypeHours ? 'h' : null}
        </span>
      </div>
    </div>
  )
}
