import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/shared/components/tooltip'
import { Button } from '@/shared/components/ui/button'
import { Trash } from 'lucide-react'
import RadarChart from '../chart/chart'

export type CardProps = {
  onClick: () => void
  openModalDelete: () => void
  index: number
  series: number[]
}

export function Card({ onClick, openModalDelete, series, index }: CardProps) {
  return (
    <div className="not:last:mr-0 mr-4 flex items-center justify-between">
      <div className="flex h-[406px] w-[276px] flex-col items-center justify-between rounded-[10px] bg-blue-700 text-white shadow-lg">
        <div className="flex h-12 w-full items-center justify-between bg-white bg-opacity-10">
          <span className="pl-3 text-label-md text-white sm:text-title-lg">
            Diagnóstico {index}
          </span>
          <div className="mr-1 flex gap-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <button
                    onClick={() => {
                      openModalDelete()
                    }}
                  >
                    <Trash />
                  </button>
                </TooltipTrigger>
                <TooltipContent>
                  <span>Deletar Diagnóstico</span>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        <div className="flex h-auto w-full select-none items-start justify-center">
          <RadarChart
            series={series}
            hiddenCategories
            height="210px"
            width="200px"
            size={100}
            theme="dark"
          />
        </div>
        <div className="h-22 px-3 py-4">
          <Button
            variant="tonal"
            onClick={onClick}
            className="flex h-12 w-[252px] items-center justify-center rounded-lg bg-coral-400 text-white disabled:cursor-not-allowed disabled:border-0 disabled:border-white disabled:bg-brand-white-opacity-25 disabled:text-brand-white-opacity-65"
          >
            <span className="text-body-md sm:text-label-lg">
              Visualizar Diagnóstico
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}
