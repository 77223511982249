import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from './api-urls'

const cannotOptions = z.enum([
  // features
  'timeline-home',
  'shortcuts-home',
  'journey-home',
  // Conteúdos
  'contents',
  'contents.original-programs',
  'contents.pills',
  'contents.courses',
  'contents.library',
  'contents.tools',
  'contents.my-company',
  'contents.live-programs',
  // Páginas
  'ranking',
  'bookmarks',
  'control-panel',
  'assessment',
  'dashboard',
  'settings',
  'journey-pillars',
  'journey-artifical-intelligence',
  'journey-monitoring',
  // Outros
  'invites',
  'weekly-goal',
  'individual-assessment',
] as const)

const canOptions = z.enum([
  'signature:cancel',
  'Program:createPublic',
  'dashboard',
  'superadmin',
  'Online:readTacticalManagementCourse',
  'AlterData:*',
  'manager',
  'Assessment:createAssessmentForCompany',
  'CollectData:*',
])

export type CannotOptions = z.infer<typeof cannotOptions>
export type CanOptions = z.infer<typeof canOptions>

const featuresSchema = z.object({
  cannot: z.array(cannotOptions),
  can: z.array(canOptions),
})

const onboardingStepSchema = z.object({
  isStarted: z.boolean(),
  isSkipped: z.boolean(),
  isCompleted: z.boolean(),
})

const onboardingStepsStatusSchema = z.object({
  UPDATE_MANAGER_ROLE: onboardingStepSchema.optional(),
  PLAN_GENERATOR: onboardingStepSchema.optional(),
  PLAN_ASSIGNMENT: onboardingStepSchema.optional(),
  PRODUCT_TOUR: onboardingStepSchema.optional(),
  PROGRAM_SETUP: onboardingStepSchema.optional(),
})

const userSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  email: z.string(),
  status: z.boolean(),
  picture: z.string().nullable(),
  role: z.enum(['SUPERADMIN', 'ADMIN', 'COLABORADOR']),
  roleId: z.string(),
  roleName: z.enum(['SUPERADMIN', 'ADMIN', 'COLABORADOR']),
  features: featuresSchema,
  onboardingStepsStatus: onboardingStepsStatusSchema,
})

const companySchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  responsibleEmail: z.string(),
  isDemo: z.boolean(),
  isTrial: z.boolean(),
  planName: z.string(),
  planType: z.enum(['FREE', 'PAID']).or(z.string()),
  logoUrl: z.string().or(z.string()),
  phone: z.string().nullable(),
  status: z.boolean(),
  currentSignature: z.object({
    expirationDate: z.string(),
    hiringDate: z.string(),
    licenseQuantity: z.number(),
    plan: z.object({
      id: z.string(),
      price: z.number(),
      name: z.string(),
      durationInDays: z.number(),
      description: z.string(),
      type: z.string(),
    }),
  }),
})

const schema = z.object({
  user: userSchema,
  company: companySchema,
})

export async function getMe() {
  const url = apiUrls.me.getMe

  const token = Cookies.get('auth-cookie') ?? ''
  const { data } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return schema.parse(data)
}

type useMeType = {
  enabled?: boolean
}

export const useMeQueryOptions = ({ enabled = true }: useMeType) => ({
  queryKey: ['me'],
  queryFn: () => getMe(),
  enabled,
})
