import z from 'zod'
import axios from 'axios'

const url = 'https://jsonplaceholder.typicode.com/users'

const schema = z.object({
  email: z.string().email(),
  id: z.number(),
  name: z.string(),
})

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

async function getUsers({ userId }: { userId: number }) {
  await sleep(1000)
  const { data } = await axios.get(`${url}/${userId}`)
  return schema.parse(data)
}

export const useUserQueryOption = (userId: number) => {
  return {
    queryKey: ['users', userId],
    queryFn: () => getUsers({ userId }),
  }
}
