import { EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import './styles/embla.css'
import './styles/base.css'
import {
  NextButton,
  PrevButton,
} from './carousel-arrow-buttons/carousel-arrow-buttons'
import { Info, User } from 'lucide-react'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/shared/components/tooltip'
import clsx from 'clsx'
import { sideBarMenuAtom } from '@/features/dashboard/components/header/header'
import { useAtomValue } from 'jotai'

type Team = {
  id: string
  name: string
}

type User = {
  id: string
  name: string
  createdAt: string | null
  lastLogin: string | null
  picture: string | null
  books: {
    in_progress: number
    completed: number
  }
  courses: {
    in_progress: number
    completed: number
  }
  teams?: Team[] | null
}

type PropType = {
  users: User[] | undefined
  total: number
  isLoading: boolean
  onNext: () => void
  onPrev: () => void
  currentPage: number
  totalPages: number
  onUserClick: (userId: string) => void
}

export function Carousel({
  users,
  total,
  isLoading,
  onNext,
  onPrev,
  currentPage,
  totalPages,
  onUserClick,
}: PropType) {
  const sideBarMenu = useAtomValue(sideBarMenuAtom)
  const optionsCarousel: EmblaOptionsType = {
    align: 'start',
    slidesToScroll: 'auto',
    dragFree: true,
  }

  const [emblaRef] = useEmblaCarousel(optionsCarousel)

  const itemsPerPage = 5
  const startIndex = 0
  const displayedUsers =
    users?.slice(startIndex, startIndex + itemsPerPage) || []

  if (isLoading) {
    return (
      <div className="flex min-h-[300px] animate-pulse rounded-xl bg-gray-200" />
    )
  }

  return (
    <section className="embla flex min-h-[250px] flex-col justify-between rounded-xl border border-gray-150 p-4">
      <div className="mb-4 flex justify-between">
        <span className="text-title-md">Pessoas do time</span>
        <div className="flex items-center justify-center gap-1">
          <span className="text-body-xs text-gray-250">Pessoas ({total})</span>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Info size={12} />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Quantidade de usuários cadastrados</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <div className="flex-grow overflow-hidden" ref={emblaRef}>
        <div className="embla__container gap-3">
          {!displayedUsers.length ? (
            <div className="flex h-full items-center justify-center">
              <span className="text-body-md text-[#808080]">
                Nenhuma pessoa vinculada a este time
              </span>
            </div>
          ) : (
            displayedUsers.map((user) => (
              <div
                className="embla__slide cursor-pointer overflow-hidden"
                key={user.id}
                onClick={() => onUserClick(user.id)}
              >
                <div
                  className={clsx(
                    'flex h-[180px] max-h-[180px] flex-col items-center justify-center gap-4 rounded-[8px] border p-6 transition-colors hover:bg-gray-50',
                    {
                      'w-[350px] max-w-[350px]': !sideBarMenu,
                      'w-[305px] max-w-[305px]': sideBarMenu,
                    }
                  )}
                >
                  {user.picture ? (
                    <div
                      className="embla__slide__thumb flex h-[72px] max-h-[72px] w-[72px] max-w-[72px] items-center justify-center rounded-full bg-cover transition-all ease-in-out"
                      style={{
                        backgroundImage: `url(${user.picture})`,
                        backgroundSize: 'cover',
                        objectPosition: 'contain',
                      }}
                    />
                  ) : (
                    <div className="flex h-[72px] max-h-[72px] w-[72px] max-w-[72px] items-center justify-center rounded-full bg-gray-50 bg-cover transition-all ease-in-out">
                      <User className="text-gray-400" size={30} />
                    </div>
                  )}
                  <div className="flex w-full justify-center">
                    <span className="line-clamp-2 h-[39px] max-h-[39px] text-center text-body-md text-[#808080]">
                      {user.name}
                    </span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="embla__controls mt-auto flex w-full items-center">
        <div className="embla__buttons flex w-full justify-end">
          <PrevButton onClick={onPrev} disabled={currentPage === 0} />
          <NextButton
            onClick={onNext}
            disabled={currentPage === totalPages - 1}
          />
        </div>
      </div>
    </section>
  )
}
