import { Header } from '@/features/diagnosis/components/header/header'
import { createFileRoute, useBlocker } from '@tanstack/react-router'
import { atom, useAtom } from 'jotai'
import { BusinessManagement } from '../../../../features/diagnosis/pages/business-management'
import { CultureAndLeadership } from '../../../../features/diagnosis/pages/culture-and-leadership'
import { CustomerExperience } from '../../../../features/diagnosis/pages/customer-experience'
import { MarketingAndGrowth } from '../../../../features/diagnosis/pages/marketing-and-growth'
import { Sales } from '../../../../features/diagnosis/pages/sales'
import ConfirmationDialog from '@/shared/components/ui/confirmation-dialog'

const questionsRoute = '/diagnosis/(questions)/questions'

export const Route = createFileRoute(`/_authenticated${questionsRoute}`)({
  component: Questions,
})

export type AnswerProps = {
  questionId: string
  questionType: string
  questionName: string
  topicId: string
  topicName: string
  answer: string | number
}

export type InputProps = {
  categoryId: string
  categoryName: string
  answers: AnswerProps[]
}

export type AnswerQuestionsStateProps = {
  organizationId: string
  companyId: string
  inputs: InputProps[]
}

export type NavigationOnStepsDiagnosisProps =
  | 'BusinessManagement'
  | 'MarketingAndGrowth'
  | 'Sales'
  | 'CultureAndLeadership'
  | 'CustomerExperience'

export const navigationOnStepsDiagnosisAtom =
  atom<NavigationOnStepsDiagnosisProps>('BusinessManagement')

export const answerQuestionsAtom = atom<AnswerQuestionsStateProps>({
  organizationId: '',
  companyId: '',
  inputs: [
    {
      categoryId: '',
      categoryName: '',
      answers: [
        {
          questionId: '',
          questionType: '',
          questionName: '',
          topicId: '',
          topicName: '',
          answer: '',
        },
      ],
    },
  ],
})

const renderStepComponent = (step: NavigationOnStepsDiagnosisProps) => {
  switch (step) {
    case 'BusinessManagement':
      return <BusinessManagement />
    case 'MarketingAndGrowth':
      return <MarketingAndGrowth />
    case 'Sales':
      return <Sales />
    case 'CultureAndLeadership':
      return <CultureAndLeadership />
    case 'CustomerExperience':
      return <CustomerExperience />
    default:
      return <BusinessManagement />
  }
}

export function Questions() {
  const [currentStep, setStep] = useAtom(navigationOnStepsDiagnosisAtom)

  const { status, proceed, reset } = useBlocker({
    condition: true,
  })

  const handleProceed = () => {
    proceed() // Continua a navegação
    setStep('BusinessManagement') // Volta para a primeira etapa
  }

  const handleCancel = () => {
    reset() // Cancela o bloqueio de navegação
  }

  return (
    <>
      <div className="flex h-full min-h-[calc(100vh-64px)] w-full flex-col bg-white px-4">
        <Header />

        <div className="flex h-full w-full flex-col pt-[80px]">
          {renderStepComponent(currentStep)}
        </div>
      </div>
      {status === 'blocked' && (
        <ConfirmationDialog
          isLoading={false}
          onClick={handleProceed} // Chama a navegação pendente
          open={status === 'blocked'}
          handleClose={handleCancel} // Cancela a saída
          title="Você tem certeza?"
          message="Se você sair agora, os dados preenchidos não serão salvos."
          cancelLabel="Cancelar"
          okLabel="Sair"
        />
      )}
    </>
  )
}
