import { ChartAreaLinear } from '@/shared/components/chart-area-linear'

import { CardShadow } from '@/features/dashboard/components/card-shadow/card-shadow'
import { ComponentError } from '@/shared/components/ui/component-error'
import { useProgramsWatchedHours } from '@/shared/queries/dashboard/programs/use-programs-watched-hours'
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { SectionHoursProgramsSkeleton } from './loading'

const pathname = location.pathname

export function SectionHoursPrograms() {
  const {
    data: dashboardWatchedHoursData,
    isLoading,
    isError,
  } = useQuery(useProgramsWatchedHours())

  if (isError)
    return (
      <div className="flex h-full min-h-[316px] w-full flex-col items-center justify-center">
        <ComponentError />
      </div>
    )
  if (isLoading) return <SectionHoursProgramsSkeleton />

  return (
    <div
      className={clsx('flex w-full flex-col gap-3 overflow-hidden', {
        'h-full': pathname === '/dashboard/contents',
        'h-[316px]': pathname !== '/dashboard/contents',
      })}
    >
      <CardShadow>
        <div className="mb-3 flex w-full items-center justify-between">
          <div className="flex w-full items-center">
            <span className="items-center text-title-md text-gray-500">
              Horas assistidas em trilhas
            </span>
          </div>
        </div>
        <div className="flex h-full w-full">
          <ChartAreaLinear
            height={200}
            data={
              dashboardWatchedHoursData?.map((item) => ({
                date: item.date,
                hourCount: item.hourCount,
                label: item.label,
                userCount: item.userCount,
                miniLabel: item.miniLabel,
              })) ?? []
            }
          />
        </div>
      </CardShadow>
    </div>
  )
}
