const baseUrl = `${import.meta.env.VITE_API_URL}`

const legacyBaseUrl = `${import.meta.env.VITE_LEGACY_API_URL}`

export const apiUrls = {
  login: {
    postLogin: `${legacyBaseUrl}/login` as const,
  },
  me: {
    getMe: `${baseUrl}/users/me` as const,
  },
  diagnosis: {
    getDiagnosis: `${baseUrl}/diagnosis` as const,
    getAllQuestionsDiagnosis: `${baseUrl}/diagnosis/questions/all` as const,
    createDiagnosis: `${baseUrl}/diagnosis` as const,
    deleteDiagnosis: `${baseUrl}/diagnosis` as const,
  },
  dashboard: {
    contents: {
      getDashboardContents: `${baseUrl}/dashboard/contents` as const,
      getDashboardContentsConsumed:
        `${baseUrl}/dashboard/contents/consumed` as const,
      getDashboardContentsCategories:
        `${baseUrl}/dashboard/contents/categories` as const,
      getDashboardWatchedHours:
        `${baseUrl}/dashboard/contents/watched-hours` as const,
      getDashboardContentsInterestAreas:
        `${baseUrl}/dashboard/contents/interest-areas` as const,
    },
    getUserVisit: `${baseUrl}/dashboard/user-visits` as const,
    getUserVisitUsers: `${baseUrl}/dashboard/user-visits/users` as const,
    getDashboardEngagement: `${baseUrl}/dashboard/engagement` as const,
    people: {
      getDashboardPeopleEngagement:
        `${baseUrl}/dashboard/users/engagements` as const,
      getDashboardPeople: `${baseUrl}/dashboard/users` as const,
      getDashboardPeopleTeams: `${baseUrl}/dashboard/users/teams` as const,
    },

    programs: {
      getUserProgress: `${baseUrl}/dashboard/programs/user-progress` as const,
      getProgramsCount: `${baseUrl}/dashboard/programs/count` as const,
      getProgramsWatchedHours:
        `${baseUrl}/dashboard/programs/watched-hours` as const,
    },
    books: {
      getDashboardBooks: `${baseUrl}/dashboard/books` as const,
    },
    teams: {
      getTeams:
        'https://api.g4staging.com/skills/backend/dashboard/teams' as const,
    },
  },
}
