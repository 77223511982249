import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import { keepPreviousData } from '@tanstack/react-query'

const teamSchema = z.object({
  id: z.string(),
  name: z.string(),
})

const dataSchema = z.object({
  id: z.string(),
  name: z.string(),
  progress: z.number(),
  picture: z.string().nullable(),
  lastSeen: z.string().nullable(),
  createdAt: z.string(),
  teams: z.array(teamSchema),
})

const linkSchema = z.object({
  rel: z.string(),
  href: z.string(),
})

const dashboardContentUsersResponseSchema = z.object({
  currentPage: z.number(),
  totalPages: z.number(),
  totalItems: z.number(),
  data: z.array(dataSchema),
  links: z.array(linkSchema),
})

export type TeamDashboardFormationUsersProps = z.infer<typeof teamSchema>

export type GetContentUsersResponse = z.infer<
  typeof dashboardContentUsersResponseSchema
>

export async function getContentUsers({
  id,
  page,
  size,
  name,
  teamId,
  progress,
  sort,
  sortBy,
}: {
  id: string
  page: number
  size: number
  progress?: string
  name?: string
  teamId?: string
  sort?: string
  sortBy?: string
}) {
  const url = apiUrls.dashboard.contents.getDashboardContents
  const token = Cookies.get('auth-cookie') ?? ''

  const params: Record<string, any> = { page, size }
  if (progress) params.progress = progress
  if (name) params.name = name
  if (teamId) params.teamId = teamId
  if (sort) {
    params.sort = sort
    params.sortBy = sortBy
  }

  try {
    const { data } = await axios.get(`${url}/${id}/users`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data) // Adicionando log para inspecionar a resposta
    const parsedData = dashboardContentUsersResponseSchema.parse(data) // Acessando a propriedade data dentro do objeto data
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetContentUsersQueryKey =
  'get-dashboard-content-users-query-key'

export const useGetContentUsers = ({
  id,
  page = 1,
  size = 10,
  name,
  teamId,
  progress,
  sort,
  sortBy,
}: {
  id: string
  page?: number
  size?: number
  name?: string
  teamId?: string
  progress?: string | undefined
  sort?: string
  sortBy?: string
}) => ({
  queryKey: [
    useGetContentUsersQueryKey,
    id,
    page,
    size,
    name,
    teamId,
    progress,
    sort,
    sortBy,
  ],
  queryFn: () =>
    getContentUsers({ page, size, name, progress, sort, sortBy, id, teamId }),
  placeholderData: keepPreviousData,
})
