import {
  navigationOnStepsDiagnosisAtom,
  NavigationOnStepsDiagnosisProps,
} from '@/routes/_authenticated/diagnosis/(questions)/questions'
import { useSetAtom } from 'jotai'

export function Header() {
  const setStep = useSetAtom(navigationOnStepsDiagnosisAtom)

  const steps: { name: string; step: NavigationOnStepsDiagnosisProps }[] = [
    { name: 'Gestão de Negócio', step: 'BusinessManagement' },
    { name: 'Marketing e Growth', step: 'MarketingAndGrowth' },
    { name: 'Vendas', step: 'Sales' },
    { name: 'Cultura e Liderança', step: 'CultureAndLeadership' },
    { name: 'Experiência do Cliente', step: 'CustomerExperience' },
  ]

  return (
    <div
      className="fixed left-0 top-0 z-10 mt-[56px] flex h-20 w-full flex-col items-center justify-center bg-white"
      style={{ boxShadow: 'rgba(34, 34, 34, 0.09) 0px 2px 8px' }}
    >
      <div className="flex items-center justify-center gap-4">
        {steps.map(({ name, step }) => (
          <span
            key={step}
            onClick={() => setStep(step)}
            style={{ cursor: 'pointer' }}
          >
            {name}
          </span>
        ))}
      </div>
    </div>
  )
}
