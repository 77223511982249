import useBreakpoint from '@/shared/hooks/use-breakpoint'
import clsx from 'clsx'
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { CardInfo } from '../../components/card-info/card-info'
import { ContentWrapper } from '../../components/content-wrapper/content-wrapper'

import { SearchTextField } from '@/shared/components/ui/search-text-field'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/shared/components/ui/select'
import useDebouncedState from '@/shared/hooks/use-debounced-state'
import {
  GetDashboardContentsConsumedResponse,
  useGetDashboardContentsConsumed,
} from '@/shared/queries/dashboard/contents/use-dashboar-contents-consumed'
import { useGetDashboardContentCategories } from '@/shared/queries/dashboard/contents/use-dashboard-content-categories'
import { useGetDashboardContents } from '@/shared/queries/dashboard/contents/use-dashboard-contents'
import { useGetDashboardContentsInterestAreas } from '@/shared/queries/dashboard/contents/use-dashboard-interest-areas'
import { useGetDashboardWatchedHours } from '@/shared/queries/dashboard/contents/use-dashboard-watched-hours'
import { useQuery } from '@tanstack/react-query'
import { CardInfoSkeleton } from '../../components/card-info-skeleton/card-info-skeleton'
import { sideBarMenuAtom } from '../../components/header/header'
import { TableDashboard } from '../../components/table-dashboard/table-dashboard'
import {
  pageSectionAreasInterest,
  SectionAreasInterest,
} from '../geral/components/section-areas-interest/section-areas-interest'
import {
  SectionHoursConsumed,
  selectedPeriodWatchedHours,
} from '../geral/components/section-hours-consumed/section-hours-consumed'
import { columns } from './columns'
import { ComponentError } from '@/shared/components/ui/component-error'

export function DashboardContents() {
  const sideBarMenu = useAtomValue(sideBarMenuAtom)
  const breakpoint = useBreakpoint()
  const [pageSize, setPageSize] = useState(() => (breakpoint === 'lg' ? 10 : 7))
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    ''
  )
  const [selectedType, setSelectedType] = useState<string | undefined>('')

  const selectedOption = useAtomValue(selectedPeriodWatchedHours)
  const types = [
    {
      id: 'course',
      name: 'Curso',
    },
    {
      id: 'book',
      name: 'Livro',
    },
  ]

  const [currentPage, setCurrentPage] = useState(1)

  const page = useAtomValue(pageSectionAreasInterest)

  const optionsTypeFilter = [
    { id: 'course', name: 'Curso' },
    { id: 'book', name: 'Livro' },
  ]

  const {
    state: searchValue,
    setState: setSearchValue,
    debouncedState: debouncedSearch,
  } = useDebouncedState({
    initialState: '',
  })

  const [currentColumnFilter, setCurrentColumnFilter] = useState<{
    sortBy: string
    sort: 'asc' | 'desc'
  }>({
    sortBy: 'name',
    sort: 'asc',
  })

  const {
    data,
    isLoading: isLoadingDashboardContents,
    isFetching: isFetchingDashboardContents,
    isError: isErrorDashboardContents,
  } = useQuery(
    useGetDashboardContents({
      page: currentPage,
      name: debouncedSearch,
      categoryId: selectedCategory,
      type: selectedType?.toUpperCase(),
      sort: currentColumnFilter.sort,
      sortBy: currentColumnFilter.sortBy,
    })
  )
  const { isFetching: isFetchingDashboardWatchedHours } = useQuery(
    useGetDashboardWatchedHours({
      period: selectedOption,
    })
  )

  const {
    data: dataConsumed,
    isLoading,
    isError: isErrorContentsConsumed,
  } = useQuery(useGetDashboardContentsConsumed())

  const { isFetching: isFetchingDashboardContentsInterestAreas } = useQuery(
    useGetDashboardContentsInterestAreas({
      page,
      size: pageSize,
    })
  )

  const { data: categories, isError: isErrorDashboardContentCategories } =
    useQuery(useGetDashboardContentCategories())

  useEffect(() => {
    if (breakpoint === 'lg') {
      setPageSize(10)
    } else {
      setPageSize(7)
    }
  }, [breakpoint])

  const handleCategoryChange = (value: string) => {
    if (value === 'all') {
      setSelectedCategory(undefined)
    } else {
      setSelectedCategory(value)
    }
    setSelectedType('')
    setSearchValue('')
    setCurrentPage(1)
  }

  const handleTypeChange = (value: string) => {
    if (value === 'all') {
      setSelectedType(undefined)
    } else {
      setSelectedType(value)
    }
    setSelectedCategory('')
    setSearchValue('')
    setCurrentPage(1)
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    setSelectedCategory('')
    setSelectedType('')
    setCurrentPage(1)
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const currentSelectedCategory = categories?.data.find(
    (category) => category.id === selectedCategory
  )
  const currentSelectedType = types?.find((type) => type.id === selectedType)

  const toggleSortOrder = (currentSort: 'asc' | 'desc'): 'asc' | 'desc' => {
    return currentSort === 'asc' ? 'desc' : 'asc'
  }

  const totalPages = data ? data.totalPages : 1

  const isError = isErrorDashboardContents || isErrorDashboardContentCategories

  function renderCardInfo({
    isLoading,
    isError,
    dataConsumed,
  }: {
    isLoading: boolean
    isError: boolean
    dataConsumed: GetDashboardContentsConsumedResponse | undefined
  }) {
    if (isError) {
      return (
        <>
          {[0, 1].map((index) => (
            <div key={index} className="w-full">
              <ComponentError size={30} />
            </div>
          ))}
        </>
      )
    }

    if (isLoading) {
      return (
        <div className="flex w-full flex-col gap-2 xl:flex-row">
          {[0, 1].map((index) => (
            <div key={index} className="w-full">
              <CardInfoSkeleton />
            </div>
          ))}
        </div>
      )
    }

    return (
      <>
        <CardInfo
          title="Usuários com consumo nos últimos 30 dias"
          value={dataConsumed?.totalUsers ?? 0}
        />
        <CardInfo
          title="Horas assistidas nos últimos 30 dias"
          value={dataConsumed?.totalHours ?? 0}
          type="hours"
        />
      </>
    )
  }

  return (
    <ContentWrapper>
      <div className="flex h-[930px] max-h-[930px] w-full flex-col gap-3 lg:max-h-[483px] lg:flex-row xl:max-h-[411px]">
        <div
          className={clsx('flex h-full w-full flex-col gap-3', {
            'lg:w-8/12': sideBarMenu,
            'lg:w-4/5': !sideBarMenu,
          })}
        >
          <div className="flex flex-col gap-3 xl:flex-row">
            {renderCardInfo({
              dataConsumed,
              isError: isErrorContentsConsumed,
              isLoading,
            })}
          </div>

          <div
            className={clsx('flex h-full w-full flex-col', {
              'cursor-wait [&>*]:opacity-50': isFetchingDashboardWatchedHours,
            })}
          >
            <SectionHoursConsumed />
          </div>
        </div>

        <div
          className={clsx('flex h-full w-full', {
            'lg:w-1/3': sideBarMenu,
            'lg:w-1/5': !sideBarMenu,
            'cursor-wait [&>*]:opacity-50':
              isFetchingDashboardContentsInterestAreas,
          })}
        >
          <SectionAreasInterest pageSize={pageSize} />
        </div>
      </div>

      <div
        className={clsx('flex w-full flex-col', {
          'border-0': isLoadingDashboardContents,
          'rounded-xl border border-gray-100': !isLoadingDashboardContents,
        })}
      >
        {!isLoadingDashboardContents && !isError ? (
          <div className="flex w-full flex-col items-center p-3 lg:flex-row lg:justify-between">
            <div className="flex h-full max-h-max w-full flex-col items-center justify-end gap-2 lg:h-8 lg:max-h-8 lg:flex-row">
              <div className="flex h-8 max-h-8 w-full lg:max-w-[250px]">
                <SearchTextField
                  onChange={handleSearchChange}
                  value={searchValue}
                  placeholder="Pesquisar"
                />
              </div>
              <div className="flex w-full flex-col gap-2 sm:flex-row lg:justify-end">
                <div className="flex w-full lg:w-auto">
                  <Select
                    value={selectedCategory}
                    onValueChange={handleCategoryChange}
                  >
                    <SelectTrigger className="max-h-[32px] min-h-[32px] w-full rounded-lg border border-gray-150 px-3 py-0 lg:min-w-[240px] lg:max-w-[240px]">
                      <div className="flex w-full items-center justify-between gap-2">
                        <div className="flex items-center justify-start">
                          <span className="line-clamp-1 text-label-lg text-gray-300">
                            {currentSelectedCategory?.name ?? 'Categorias'}
                          </span>
                        </div>
                      </div>
                    </SelectTrigger>
                    <SelectContent className="z-[999px] flex w-full lg:min-w-[240px] lg:max-w-[240px]">
                      <SelectItem
                        className={clsx('flex items-center px-3 py-1', {
                          'bg-blue-500 text-white hover:bg-blue-500':
                            selectedCategory === 'all',
                          'bg-white': selectedCategory !== 'all',
                        })}
                        key="default"
                        value="all"
                      >
                        Todas as Categorias
                      </SelectItem>
                      {categories?.data.map((category) => (
                        <SelectItem
                          className={clsx('flex items-center px-3 py-1', {
                            'bg-blue-500 text-white hover:bg-blue-500':
                              category.id === selectedCategory,
                            'bg-white': category.id !== selectedCategory,
                          })}
                          key={category.id}
                          value={category.id}
                        >
                          {category.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex w-full lg:w-auto">
                  <Select value={selectedType} onValueChange={handleTypeChange}>
                    <SelectTrigger className="max-h-[32px] min-h-[32px] rounded-lg border border-gray-150 px-3 py-0 lg:min-w-[164px] lg:max-w-[164px]">
                      <div className="flex w-full items-center justify-between gap-2">
                        <div className="flex items-center justify-start">
                          <span className="text-label-lg text-gray-300">
                            {currentSelectedType?.name ?? 'Tipos'}
                          </span>
                        </div>
                      </div>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem
                        className={clsx('flex items-center px-3 py-1', {
                          'bg-blue-500 text-white hover:bg-blue-500':
                            selectedType === 'all',
                          'bg-white': selectedType !== 'all',
                        })}
                        key="default"
                        value="all"
                        onClick={() => setSelectedType('all')}
                      >
                        Todos os Tipos
                      </SelectItem>
                      {optionsTypeFilter.map((option) => (
                        <SelectItem
                          className={clsx('flex items-center px-3 py-1', {
                            'bg-blue-500 text-white hover:bg-blue-500':
                              option.id === selectedType,
                            'bg-white': option.id !== selectedType,
                          })}
                          key={option.id}
                          value={option.id}
                          onClick={() => setSelectedType(option.id)}
                        >
                          {option.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div
          className={clsx({
            'cursor-wait [&>*]:opacity-50': isFetchingDashboardContents,
          })}
        >
          <TableDashboard
            columns={columns}
            data={data?.data ?? []}
            isError={isError}
            isLoading={isLoadingDashboardContents}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onFilterChange={({ sortBy }) => {
              const isSameFilter = sortBy === currentColumnFilter.sortBy
              const newSort = isSameFilter
                ? toggleSortOrder(currentColumnFilter.sort)
                : 'asc'

              setCurrentColumnFilter({
                sortBy,
                sort: newSort,
              })
            }}
          />
        </div>
      </div>
    </ContentWrapper>
  )
}
