import { Button } from '@/shared/components/ui/button'
import { Link, useRouter } from '@tanstack/react-router'

type Props = {
  reset: () => void
}

export function UserErrorPage({ reset }: Props) {
  const router = useRouter()
  function resetErrorBoundary() {
    return router.invalidate()
  }
  return (
    <div className="flex min-h-[100dvh] w-full flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <div className="mx-auto h-12 w-12 text-primary" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
          Opa, algo deu errado! - Página de 1 usuário
        </h1>
        <p className="mt-4 text-muted-foreground">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
          reiciendis placeat iusto quaerat minus possimus eveniet aliquid quis
          nam non.
        </p>
        <div className="mt-6 flex flex-col gap-2">
          <Link to="/users">
            <Button
              onClick={() => {
                console.log('reset error boundary')
                reset()
              }}
            >
              Voltar para a página de usuários
            </Button>
          </Link>
          <Button onClick={resetErrorBoundary}>Tentar novamente</Button>
        </div>
      </div>
    </div>
  )
}
