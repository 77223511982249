import { useQuery } from '@tanstack/react-query'
import { CardShadow } from '../../../../components/card-shadow/card-shadow'
import { CircleInfo } from './components/circle-info/circle-info'
import LinearGradientFill from './components/linear-gradient-fill/linear-gradient-fill'
import PieChartInfo from './components/pie-chart-info/pie-chart-info'
import { SectionEngajamentSkeleton } from './loading'
import { useGetDashboardEngagement } from '@/shared/queries/dashboard/use-dashboard-engagement'

const generateChartData = (percentage: number) => {
  return [
    { data: percentage, fill: 'url(#grad1)' },
    { data: 100 - percentage, fill: '#E6EAF0' },
  ]
}

export function RenderError() {
  return (
    <div className="flex h-full w-full flex-col gap-6">
      <CardShadow>
        <div className="flex h-full w-full flex-col items-center justify-center">
          <span className="text-body-sm text-gray-500">
            Erro ao carregar dados
          </span>
        </div>
      </CardShadow>
    </div>
  )
}

export function SectionEngajament() {
  const { data, isLoading } = useQuery(useGetDashboardEngagement())

  if (isLoading) return <SectionEngajamentSkeleton />

  if (!data) return <RenderError />

  const licencesChartData = generateChartData(data.licences.percentage)
  const finishedProgramChartData = generateChartData(
    data.usersWhoHaveFinishedProgram.percentage
  )
  const notStudyingChartData = generateChartData(
    data.userWhoAreNotStudying.percentage
  )

  return (
    <div className="flex w-full flex-col gap-6">
      <CardShadow>
        <div className="flex w-full">
          <LinearGradientFill />
          <span className="items-center text-title-md text-gray-500">
            Engajamento
          </span>
        </div>
        <div className="flex h-full w-full flex-col items-center justify-center gap-6 md:flex-row md:justify-between">
          <PieChartInfo
            data={licencesChartData}
            percentage={data.licences.percentage}
            description="Usuários cadastrados"
            value={data.licences.count}
          />
          <PieChartInfo
            data={finishedProgramChartData}
            percentage={data.usersWhoHaveFinishedProgram.percentage}
            description="Usuários que finalizaram uma trilha"
            value={data.usersWhoHaveFinishedProgram.count}
          />
        </div>
        <div className="flex h-full w-full flex-col items-center justify-center gap-6 md:flex-row md:justify-between">
          <CircleInfo
            value={data.consumedHours}
            description="Horas consumidas na plataforma"
          />
          <PieChartInfo
            data={notStudyingChartData}
            percentage={data.userWhoAreNotStudying.percentage}
            description="Usuários que não estão estudando"
            value={data?.userWhoAreNotStudying?.count ?? 0}
          />
        </div>
      </CardShadow>
    </div>
  )
}

export default SectionEngajament
