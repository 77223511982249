import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../api-urls'

const questionSchema = z.object({
  categoryId: z.string(),
  createdAt: z.string(), // Use z.string() para datas no formato ISO
  createdBy: z.string(),
  deletedAt: z.string().nullable(),
  deletedBy: z.string().nullable(),
  id: z.string(),
  order: z.number(),
  title: z.string(),
  topicId: z.string(),
  topicName: z.string(),
  type: z.enum(['agreement-choice', 'open-ai-input']),
  updatedAt: z.string().nullable(),
  updatedBy: z.string().nullable(),
})

const categorySchema = z.object({
  id: z.string(),
  categoryName: z.string(),
  questions: z.array(questionSchema),
})

const schema = z.array(categorySchema)

export type GetAllQuestionsDiagnosisResponseProps = z.infer<typeof schema>

export async function getAllQuestionsDiagnosis() {
  const url = apiUrls.diagnosis.getAllQuestionsDiagnosis
  const token = Cookies.get('auth-cookie') ?? ''

  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return schema.parse(data)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Erro específico do Axios
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      // Outros erros
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetDiagnosisQueryKey = 'get-diagnosis-query-key'

export const useGetAllQuestionsDiagnosis = () => ({
  queryKey: [useGetDiagnosisQueryKey],
  queryFn: () => getAllQuestionsDiagnosis(),
})
