import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import { keepPreviousData } from '@tanstack/react-query'

const rankSchema = z.object({
  position: z.number(),
  points: z.number(),
})

const teamSchema = z.object({
  id: z.string(),
  name: z.string(),
})

const personSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  lastLogin: z.string(),
  createdAt: z.string(),
  picture: z.string().nullable(),
  rank: rankSchema,
  teams: z.array(teamSchema),
  totalLessonsCompleted: z.number(),
  userTotalHour: z.string(),
})

export type PersonDetailProps = z.infer<typeof personSchema>

export async function getPeopleDetail({ id }: { id: string }) {
  const url = apiUrls.dashboard.people.getDashboardPeople
  const token = Cookies.get('auth-cookie') ?? ''

  try {
    const { data } = await axios.get(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data)
    const parsedData = personSchema.parse(data.data)
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetPeopleDetailQueryKey = 'get-people-detail-query-key'

export const usePeopleDetail = ({ id }: { id: string }) => ({
  queryKey: [useGetPeopleDetailQueryKey, id],
  queryFn: () => getPeopleDetail({ id }),
  placeholderData: keepPreviousData,
})
