type CardShadowProps = {
  children: React.ReactNode
}

export function CardShadow({ children }: CardShadowProps) {
  return (
    <div className="flex h-full w-full flex-col gap-5 rounded-xl border border-gray-100 p-6 shadow-sm md:gap-0">
      {children}
    </div>
  )
}
