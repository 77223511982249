import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import { keepPreviousData } from '@tanstack/react-query'

const teamSchema = z.object({
  teamId: z.string(),
  totalUsers: z.number(),
  totalPrograms: z.number(),
  teamName: z.string(),
  totalProgramsInProgress: z.number(),
  totalProgramsCompleted: z.number(),
})

const linkSchema = z.object({
  rel: z.string(),
  href: z.string(),
})

const teamsResponseSchema = z.object({
  data: z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    totalItems: z.number(),
    data: z.array(teamSchema),
    links: z.array(linkSchema),
  }),
})

export type GetTeamsResponse = z.infer<typeof teamsResponseSchema>

export async function getTeams({
  page,
  size,
  name,
  sort,
  sortBy,
}: {
  page: number
  size: number
  name?: string
  sort?: string
  sortBy?: string
}) {
  const url = apiUrls.dashboard.teams.getTeams
  const token = Cookies.get('auth-cookie') ?? ''

  const params: Record<string, any> = { page, size }
  if (name) params.name = name
  if (sort) {
    params.sort = sort
    params.sortBy = sortBy
  }

  try {
    const { data } = await axios.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data) // Log to inspect the response
    const parsedData = teamsResponseSchema.parse(data)
    return parsedData.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Axios request error:',
        error.response?.data || error.message
      )
    } else {
      console.error('Unexpected error:', error)
    }
    throw error
  }
}

export const useGetTeamsQueryKey = 'get-teams-query-key'

export const useGetTeams = ({
  page = 1,
  size = 10,
  name,
  sort,
  sortBy,
}: {
  page: number
  size?: number
  name?: string
  sort?: string
  sortBy?: string
}) => ({
  queryKey: [useGetTeamsQueryKey, page, size, name, sort, sortBy],
  queryFn: () => getTeams({ page, size, name, sort, sortBy }),
  placeholderData: keepPreviousData,
})
