import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/shared/components/tooltip'
import useBreakpoint from '@/shared/hooks/use-breakpoint'
import { useLocation, useNavigate } from '@tanstack/react-router'
import clsx from 'clsx'
import { useSetAtom } from 'jotai'
import { ChevronLeft, Ellipsis } from 'lucide-react'
import { sideBarMenuAtom } from '../header/header'
import { Button } from './button-sidebar'
import { getMenuList } from './menu-list'
import { ScrollArea } from './scroll-area'

interface MenuProps {
  isOpen: boolean | undefined
}

export function Menu({ isOpen }: MenuProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const pathname = location.pathname
  const menuList = getMenuList(pathname)
  const setSideBarMenu = useSetAtom(sideBarMenuAtom)

  function goToLegacySklHome() {
    window.location.href = import.meta.env.VITE_LEGACY_APP_URL
  }

  const breakpoint = useBreakpoint()

  return (
    <ScrollArea className="[&>div>div[style]]:!block">
      <nav className="h-full w-full">
        <ul className="flex flex-col items-start space-y-1">
          <Button
            variant={'ghost'}
            className="mb-1 h-10 w-full justify-start"
            onClick={goToLegacySklHome}
          >
            <ChevronLeft size={16} className="mr-[6px]" />
            <p
              className="text-label-lg text-gray-500"
              style={{
                fontFamily: 'Noto Sans',
              }}
            >
              Dashboard
            </p>
          </Button>

          {menuList.map(({ groupLabel, menus }, index) => (
            <li
              className={clsx('w-full', {
                'pt-5': groupLabel,
                'pt-0': !groupLabel,
              })}
              key={index}
            >
              {(isOpen && groupLabel) || isOpen === undefined ? (
                <p className="max-w-[248px] truncate px-4 pb-2 text-sm font-medium text-muted-foreground">
                  {groupLabel}
                </p>
              ) : !isOpen && isOpen !== undefined && groupLabel ? (
                <TooltipProvider>
                  <Tooltip delayDuration={100}>
                    <TooltipTrigger className="w-full">
                      <div className="flex w-full items-center justify-center">
                        <Ellipsis className="h-5 w-5" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="right">
                      <p>{groupLabel}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <p className="pb-2"></p>
              )}
              {menus.map(
                ({ href, label, icon: Icon, active, submenus }, index) =>
                  !submenus || submenus.length === 0 ? (
                    <div className="w-full min-w-[200px]" key={index}>
                      <TooltipProvider disableHoverableContent>
                        <Tooltip delayDuration={100}>
                          <TooltipTrigger asChild>
                            <Button
                              variant={active ? 'secondary' : 'ghost'}
                              className="mb-1 h-10 w-full justify-start"
                              onClick={() => {
                                navigate({
                                  to: href,
                                  replace: true,
                                })
                                if (breakpoint === 'xs') {
                                  setSideBarMenu(false)
                                }
                              }}
                            >
                              <span
                                className={clsx({
                                  '': !isOpen,
                                  'mr-4': isOpen && Icon,
                                })}
                              >
                                {Icon ? <Icon size={18} /> : null}
                              </span>
                              <p
                                className={clsx(
                                  'max-w-[200px] truncate font-[Montserrat] text-label-md text-gray-500',
                                  {
                                    'translate-x-0 opacity-100': isOpen,
                                    '-translate-x-96 opacity-0': !isOpen,
                                  }
                                )}
                              >
                                {label}
                              </p>
                            </Button>
                          </TooltipTrigger>
                          {isOpen === false && (
                            <TooltipContent side="right">
                              {label}
                            </TooltipContent>
                          )}
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  ) : null
              )}
            </li>
          ))}
        </ul>
      </nav>
    </ScrollArea>
  )
}
