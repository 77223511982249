import { BodyQuestions } from '@/features/diagnosis/components/body-questions/body-questions'
import { useGetAllQuestionsDiagnosis } from '@/shared/queries/diagnosis/use-all-questions-diagnosis'
import { useQuery } from '@tanstack/react-query'

export function CustomerExperience() {
  const { data } = useQuery(useGetAllQuestionsDiagnosis())

  if (!data || data.length === 0) return null

  const customerExperienceData = data[4]

  return <BodyQuestions category={customerExperienceData} />
}
