import useDebouncedState from '@/shared/hooks/use-debounced-state'
import { InputAdornment, TextField } from '@mui/material'
import { MouseEventHandler, useEffect } from 'react'

import { Search, X } from 'lucide-react'
import './styles.css'

interface Props {
  placeholder?: string
  value: string
  debounceDurationInMs?: number
  size?: 'small' | 'medium'
  disabled?: boolean
  onChange: (value: string) => void
  onClick?: MouseEventHandler<HTMLDivElement>
}

export function SearchTextField({
  placeholder = '',
  value,
  debounceDurationInMs = 0,
  disabled = false,
  size = 'medium',
  onChange,
  onClick,
}: Props) {
  const { state, setState } = useDebouncedState({
    initialState: value,
    debounceTime: debounceDurationInMs,
    callback: onChange,
  })

  useEffect(() => {
    setState(value)
  }, [value])

  return (
    <div className="flex w-full rounded-lg bg-white hover:bg-blue-100 active:bg-gray-50">
      <TextField
        variant="outlined"
        placeholder={placeholder}
        onChange={(e) => setState(e.target.value)}
        value={state}
        className="cuzao"
        size={size}
        onClick={onClick}
        sx={{
          width: '100%',
          height: '100%',
          maxWidth: '100%',

          fieldset: {
            fontSize: '14px',
            fontStyle: 'normal',
            lineHeight: '140%',
            letterSpacing: '0.14px',
            borderRadius: '8px',
            border: '1px solid #DDE2ED !important',
          },
          div: {
            fontSize: '14px !important',
          },
        }}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search size={16} />
            </InputAdornment>
          ),
          endAdornment:
            value.length > 0 ? (
              <InputAdornment position="end">
                <button className="p-1" onClick={() => setState('')}>
                  <X size={16} />
                </button>
              </InputAdornment>
            ) : null,
        }}
      />
    </div>
  )
}
