import { QueryClient } from '@tanstack/react-query'

import { AuthenticatedLayout } from '@/layout/authenticated/page'
import { Auth } from '@/utils.auth'
import { createRootRouteWithContext } from '@tanstack/react-router'

interface MyRouterContext {
  queryClient: QueryClient
  authStore: Auth
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: AuthenticatedLayout,
})
