import { ArrowDownUp, Flag } from 'lucide-react'
import {
  Column,
  Data,
} from '../../components/table-teams-dashboard/table-teams-dashboard'

export const columns: Column[] = [
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-6">
        <ArrowDownUp size={18} />
        <span className="title-sm text-gray-400">Time</span>
      </div>
    ),
    accessor: 'name',
    width: '350px',
    render: (row: Data) => (
      <div className="flex cursor-pointer items-center gap-3">
        <div className="flex h-9 w-9 items-center justify-center rounded-[6px] bg-brand-coral-150">
          <Flag className="h-5 w-5 font-bold text-coral-400" />
        </div>
        <span className="line-clamp-1 px-3 text-body-md text-gray-500">
          {row.teamName}
        </span>
      </div>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Integrantes</span>
      </div>
    ),
    accessor: 'totalUsers',
    width: '192px',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">{row.totalUsers}</span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Trilhas</span>
      </div>
    ),
    accessor: 'totalPrograms',
    width: '192px',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">{row.totalPrograms}</span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Trilhas em andamento</span>
      </div>
    ),
    accessor: 'progress',
    width: '192px',
    render: (row: Data) => (
      <span className="text-center text-body-md text-gray-500">
        {row.totalProgramsInProgress}
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Trilhas em concluídas</span>
      </div>
    ),
    accessor: 'completed',
    width: '192px',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">
        {row.totalProgramsCompleted}
      </span>
    ),
  },
]
