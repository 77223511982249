import clsx from 'clsx'
import { useState } from 'react'
import { ContentWrapper } from '../../components/content-wrapper/content-wrapper'

import { ComponentError } from '@/shared/components/ui/component-error'
import { SearchTextField } from '@/shared/components/ui/search-text-field'
import useDebouncedState from '@/shared/hooks/use-debounced-state'
import {
  GetProgramsCountResponse,
  useProgramsCount,
} from '@/shared/queries/dashboard/programs/use-programs-count'
import { useGetUserProgress } from '@/shared/queries/dashboard/programs/use-user-progress'
import { useQuery } from '@tanstack/react-query'
import { CardInfoSkeleton } from '../../components/card-info-skeleton/card-info-skeleton'
import { CardProgress } from '../../components/card-progress/card-progress'
import { TableProgramsDashboard } from '../../components/table-programs-dashboard/table-programs-dashboard'
import { columns } from './columns'
import { SectionHoursPrograms } from './components/section-hours-programs/section-hours-programs'

export function DashboardPrograms() {
  const [currentPage, setCurrentPage] = useState(1)

  const {
    state: searchValue,
    setState: setSearchValue,
    debouncedState: debouncedSearch,
  } = useDebouncedState({
    initialState: '',
  })

  const [currentColumnFilter, setCurrentColumnFilter] = useState<{
    sortBy: string
    sort: 'asc' | 'desc'
  }>({
    sortBy: 'name',
    sort: 'asc',
  })

  const {
    data,
    isLoading: isLoadingDashboardPeople,
    isFetching: isFetchingDashboardPeople,
    isError: isErrorDashboardPeople,
  } = useQuery(
    useGetUserProgress({
      page: currentPage,
      input: debouncedSearch,
      sort: currentColumnFilter.sort,
      sortBy: currentColumnFilter.sortBy,
    })
  )

  const {
    data: dataCount,
    isLoading: isLoadingCount,
    isError: isErrorCount,
  } = useQuery(useProgramsCount())

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    setCurrentPage(1)
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const toggleSortOrder = (currentSort: 'asc' | 'desc'): 'asc' | 'desc' => {
    return currentSort === 'asc' ? 'desc' : 'asc'
  }

  const totalPages = data ? data.totalPages : 1

  function renderCardInfo({
    isLoading,
    isError,
    dataCount,
  }: {
    isLoading: boolean
    isError: boolean
    dataCount?: GetProgramsCountResponse
  }) {
    if (isError) {
      return (
        <>
          {[0, 1].map((index) => (
            <div key={index} className="w-full">
              <ComponentError size={30} />
            </div>
          ))}
        </>
      )
    }

    if (isLoading) {
      return (
        <div className="flex w-full flex-col gap-2 xl:flex-row">
          {[0, 1].map((index) => (
            <div key={index} className="w-full">
              <CardInfoSkeleton />
            </div>
          ))}
        </div>
      )
    }

    return (
      <>
        <CardProgress
          title="Trilhas em andamento"
          value={dataCount?.inProgress.filteredProgramsCount ?? 0}
          percentage={dataCount?.inProgress.percentage ?? 0}
          data={dataCount}
          isLoading={isLoadingCount}
        />
        <CardProgress
          title="Trilhas finalizadas"
          value={dataCount?.finished.filteredProgramsCount ?? 0}
          percentage={dataCount?.finished.percentage ?? 0}
          data={dataCount}
          isLoading={isLoadingCount}
        />
      </>
    )
  }

  return (
    <ContentWrapper>
      <div className="flex h-[940px] max-h-[940px] w-full flex-col gap-3 lg:max-h-[483px] lg:flex-row xl:max-h-[411px]">
        <div className={clsx('flex h-full w-full flex-col gap-3', {})}>
          <div className="flex flex-col gap-3 xl:flex-row">
            {renderCardInfo({
              isError: isErrorCount,
              isLoading: isLoadingCount,
              dataCount,
            })}
          </div>

          <div className="flex h-full w-full flex-col">
            <SectionHoursPrograms />
          </div>
        </div>
      </div>

      <div
        className={clsx('flex w-full flex-col', {
          'border-0': isLoadingDashboardPeople,
          'rounded-xl border border-gray-100': !isLoadingDashboardPeople,
        })}
      >
        {!isLoadingDashboardPeople && !isErrorDashboardPeople ? (
          <div className="flex w-full flex-col items-center p-3 lg:flex-row lg:justify-between">
            <div className="flex h-full max-h-max w-full flex-col items-center justify-start gap-2 lg:h-8 lg:max-h-8 lg:flex-row">
              <div className="flex h-8 max-h-8 w-full lg:max-w-[250px]">
                <SearchTextField
                  onChange={handleSearchChange}
                  value={searchValue}
                  placeholder="Pesquisar"
                />
              </div>
            </div>
          </div>
        ) : null}

        <div
          className={clsx({
            'cursor-wait [&>*]:opacity-50': isFetchingDashboardPeople,
          })}
        >
          <TableProgramsDashboard
            columns={columns}
            data={data?.data ?? []}
            isError={isErrorDashboardPeople}
            isLoading={isLoadingDashboardPeople}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onFilterChange={({ sortBy }) => {
              const isSameFilter = sortBy === currentColumnFilter.sortBy
              const newSort = isSameFilter
                ? toggleSortOrder(currentColumnFilter.sort)
                : 'asc'

              setCurrentColumnFilter({
                sortBy,
                sort: newSort,
              })
            }}
          />
        </div>
      </div>
    </ContentWrapper>
  )
}
