import { ChartLine } from 'lucide-react'
import { motion } from 'framer-motion'

export function SectionHoursConsumedSkeleton() {
  return (
    <div
      role="status"
      className="flex h-[316px] max-h-[316px] w-full animate-pulse items-center rounded-xl border border-gray-100 bg-gray-200 shadow-sm lg:max-h-[294px] lg:min-h-[294px] xl:h-[316px] xl:max-h-[316px]"
    >
      <div className="flex h-full w-full items-center justify-center">
        <motion.div
          className="rounded-xl bg-gray-200 p-4"
          animate={{
            y: [0, -5, 0], // Animação de pulo
          }}
          transition={{
            duration: 0.5,
            repeat: Infinity,
            repeatType: 'loop',
          }}
        >
          <ChartLine className="text-gray-300" />
        </motion.div>
      </div>
    </div>
  )
}
