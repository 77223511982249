import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../api-urls'

// Tipagem do estado usando zod
const answerSchema = z.object({
  questionId: z.string(),
  questionType: z.string(),
  questionName: z.string(),
  topicId: z.string(),
  topicName: z.string(),
  answer: z.union([z.string(), z.number()]),
})

const inputSchema = z.object({
  categoryId: z.string(),
  categoryName: z.string(),
  answers: z.array(answerSchema),
})

const answerQuestionsStateSchema = z.object({
  organizationId: z.string(),
  companyId: z.string(),
  inputs: z.array(inputSchema),
})

type AnswerQuestionsStateProps = z.infer<typeof answerQuestionsStateSchema>

// Tipagem da resposta usando zod
const topicSchema = z.object({
  topicName: z.string(),
  topicScore: z.number(),
})

const outputSchema = z.object({
  id: z.string(),
  categoryId: z.string(),
  aiOutput: z.string(),
  categoryName: z.string(),
  categoryScore: z.string(),
  topics: z.array(topicSchema),
})

const responseSchema = z.object({
  id: z.string(),
  companyId: z.string(),
  outputs: z.array(outputSchema),
  organizationId: z.string(),
  createdAt: z.string(),
  createdBy: z.string(),
  deletedAt: z.null(),
  deletedBy: z.null(),
})

type responseType = z.infer<typeof responseSchema>

// Função para criar o diagnóstico
async function createDiagnosis(payload: AnswerQuestionsStateProps) {
  const url = apiUrls.diagnosis.createDiagnosis
  const token = Cookies.get('auth-cookie') ?? ''
  try {
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    // Log detalhado da resposta para inspeção
    console.log('Resposta completa da API:', response)
    console.log('Dados da resposta:', response.data)

    return responseSchema.parse(response.data)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useCreateDiagnosisQueryKey = 'use-create-diagnosis-query-key'

type useCreateDiagnosisType = {
  onSuccess?: (data: responseType) => void
  onError?: () => void
}

export function useCreateDiagnosis({
  onSuccess,
  onError,
}: useCreateDiagnosisType = {}) {
  return useMutation({
    mutationFn: createDiagnosis,
    mutationKey: [useCreateDiagnosisQueryKey],
    onSuccess: onSuccess,
    onError: onError,
  })
}
