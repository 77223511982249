import { ChartPieDonutText } from '@/shared/components/chart-pie-donut-text'

interface ChartInfoProps {
  data: { data: number; fill: string }[]
  percentage: number
  description: string
  value: number
}

export function PieChartInfo({
  data,
  percentage,
  description,
  value,
}: ChartInfoProps) {
  return (
    <div className="flex w-full items-center justify-start gap-4">
      <ChartPieDonutText data={data} percentage={percentage} />
      <div className="flex w-full max-w-[116px] flex-col justify-center gap-1">
        <span className="text-body-xs text-gray-400">{description}</span>
        <span className="text-headline-xs">{value}</span>
      </div>
    </div>
  )
}

export default PieChartInfo
