import { ComponentError } from '@/shared/components/ui/component-error'
import { Carousel } from '../../team-detail/components/carousel'
import { ArrowLeft, Flag } from 'lucide-react'
import { Button } from '@/shared/components/ui/button'
import { ContentWrapper } from '@/features/dashboard/components/content-wrapper/content-wrapper'
import { useGetDashboardPeople } from '@/shared/queries/dashboard/people/use-dashboard-people'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from '@tanstack/react-router'
import clsx from 'clsx'
import { useState } from 'react'

export function TeamDetails() {
  const { teamId } = useParams({
    from: '/_authenticated/dashboard/teams/$teamId',
  })

  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(0)
  const { data, isLoading, isFetching, isError } = useQuery(
    useGetDashboardPeople({
      page: currentPage + 1,
      size: 5,
      teamId,
      sort: 'asc',
      sortBy: 'name',
    })
  )

  const handleBack = () => {
    navigate({ to: '/dashboard/teams' })
  }

  const navigateToUserDetails = (userId: string) => {
    navigate({ to: `/dashboard/people/${userId}` })
  }

  const users = data ? data.data : []
  const totalItems = data ? data.totalItems : 0
  const totalPages = Math.ceil(totalItems / 5)

  const teamDetails =
    users.length > 0 && users[0].teams
      ? users[0].teams.find((item) => item.id === teamId)
      : null

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))
  }

  return (
    <ContentWrapper>
      <div className="flex w-full flex-col justify-start gap-4">
        <div className="flex items-center justify-start gap-4">
          <Button
            onClick={handleBack}
            variant="outlined"
            className="color-gray-500 h-8 text-title-sm"
          >
            <ArrowLeft size={18} />
            Voltar
          </Button>
          <span className="text-center text-title-md text-gray-500">
            Detalhamento do Time
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-body-xs text-gray-250">Time</span>
          {isLoading ? (
            <div className="flex animate-pulse items-center gap-2">
              <div className="flex h-[36px] w-[36px] rounded-md bg-gray-200" />
              <div className="h-5 w-32 rounded bg-gray-200" />
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <div className="flex h-[36px] w-[36px] items-center justify-center rounded-md bg-[#F7E9E9]">
                <Flag stroke="#FF6559" />
              </div>
              <span className="text-title-md text-gray-500">
                {teamDetails?.name ?? 'Time sem nome'}
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        className={clsx('w-full', {
          'cursor-wait [&>*]:opacity-50': isFetching,
        })}
      >
        {isError ? (
          <div className="embla flex h-[300px] w-full flex-col">
            <ComponentError />
          </div>
        ) : (
          <Carousel
            users={users}
            total={totalItems}
            isLoading={isLoading}
            onNext={handleNextPage}
            onPrev={handlePrevPage}
            currentPage={currentPage}
            totalPages={totalPages}
            onUserClick={navigateToUserDetails}
          />
        )}
      </div>
    </ContentWrapper>
  )
}
