import { accessDialogAtom, ChartBar } from '@/shared/components/chart-bar'
import { ComponentError } from '@/shared/components/ui/component-error'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/shared/components/ui/select'
import { useGetUserVisits } from '@/shared/queries/dashboard/general/use-general'
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { atom, useAtom, useAtomValue } from 'jotai' // Importando Jotai para gerenciar o estado
import { CardShadow } from '../../../../components/card-shadow/card-shadow'
import { SectionAccessSkeleton } from './loading'
import { UserAccessListDialog } from './components/user-access-list-dialog/user-access-list-dialog'

// Criando o atom para o estado selecionado
export const selectedOptionSectionAccessAtom = atom('7')

export function SectionAccess() {
  const [selectedOption, setSelectedOption] = useAtom(
    selectedOptionSectionAccessAtom
  ) // Usando o atom

  const isOpenAccessDialog = useAtomValue(accessDialogAtom)

  const {
    data: apiData,
    isLoading,
    isError,
    isFetching: isFetchingDashboardContentsInterestAreas,
  } = useQuery(
    useGetUserVisits({
      interval: Number(selectedOption),
    })
  )

  if (isError)
    return (
      <div className="flex h-full min-h-[316px] w-full flex-col items-center justify-center">
        <ComponentError />
      </div>
    )
  if (isLoading) return <SectionAccessSkeleton />

  return (
    <>
      <div
        className={clsx('flex h-[316px] w-full flex-col gap-3', {
          'cursor-wait [&>*]:opacity-50':
            isFetchingDashboardContentsInterestAreas,
        })}
      >
        <CardShadow>
          <div className="mb-3 flex w-full items-center justify-between">
            <div className="flex w-full items-center">
              <span className="items-center text-title-md text-gray-500">
                Acessos
              </span>
            </div>

            <div className="flex w-full justify-end">
              <Select value={selectedOption} onValueChange={setSelectedOption}>
                <SelectTrigger className="max-h-[32px] min-h-[32px] min-w-[164px] max-w-[164px] rounded-md border border-gray-150 px-3 py-0">
                  <div className="flex w-full items-center justify-between gap-2">
                    <div className="flex items-center justify-start">
                      <span className="text-label-lg text-gray-300">
                        {selectedOption} dias
                      </span>
                    </div>
                  </div>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={'7'}>7 dias</SelectItem>
                  <SelectItem value={'14'}>14 dias</SelectItem>
                  <SelectItem value={'30'}>30 dias</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="flex w-full">
            <ChartBar
              data={
                apiData
                  ? apiData.data.map((item) => ({
                      month: item.labelXAxis,
                      date: item.label,
                      total: item.users ? item.users.length : 0,
                    }))
                  : []
              }
              height={200}
            />
          </div>
        </CardShadow>
      </div>
      {isOpenAccessDialog ? <UserAccessListDialog /> : null}
    </>
  )
}
