import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import { keepPreviousData } from '@tanstack/react-query'

const dataSchema = z.object({
  id: z.string(),
  name: z.string(),
  total: z.number(),
})

const linkSchema = z.object({
  rel: z.string(),
  href: z.string(),
})

const dashboardContentsResponseSchema = z.object({
  currentPage: z.number(),
  totalPages: z.number(),
  totalAreas: z.number(),
  data: z.array(dataSchema),
  links: z.array(linkSchema),
})

export type GetDashboardContentsInterestAreasResponse = z.infer<
  typeof dashboardContentsResponseSchema
>

export async function getDashboardContentsInterestAreas({
  page,
  size,
}: {
  page: number
  size: number
}) {
  const url = apiUrls.dashboard.contents.getDashboardContentsInterestAreas
  const token = Cookies.get('auth-cookie') ?? ''

  const params: Record<string, any> = { page, size }

  try {
    const { data } = await axios.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data) // Adicionando log para inspecionar a resposta
    const parsedData = dashboardContentsResponseSchema.parse(data.data) // Acessando a propriedade data dentro do objeto data
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetDashboardContentsInterestAreasQueryKey =
  'get-dashboard-contents-interest-areas-query-key'

export const useGetDashboardContentsInterestAreas = ({
  page = 1,
  size = 10,
}: {
  page?: number
  size?: number
}) => ({
  queryKey: [useGetDashboardContentsInterestAreasQueryKey, page, size],
  queryFn: () => getDashboardContentsInterestAreas({ page, size }),
  placeholderData: keepPreviousData,
})
