import { useUsersQueryOptions } from '@/shared/queries/use-users'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'

export function UsersPage() {
  const { data } = useSuspenseQuery(useUsersQueryOptions)

  return (
    <div className="flex flex-col gap-2">
      {data.map((user) => (
        <Link
          to="/users/$userId"
          params={{ userId: user.id - 1 }}
          key={user.id}
        >
          <span className="text-2xl">
            {user.id} - {user.email} - {user.name}
          </span>
        </Link>
      ))}
    </div>
  )
}
