import { Outlet } from '@tanstack/react-router'
import clsx from 'clsx'
import { Header } from './components/header/header'
import { SidebarDashboard } from './components/sidebar/sidebar'

export function DashboardPage() {
  return (
    <div className={clsx('flex h-screen w-full flex-col overflow-hidden')}>
      <div className="flex h-full w-full flex-col overflow-hidden">
        <Header />
        <div className="flex h-full w-full overflow-hidden">
          <SidebarDashboard />

          <Outlet />
        </div>
      </div>
    </div>
  )
}
