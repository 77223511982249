import { GetProgramsCountResponse } from '@/shared/queries/dashboard/programs/use-programs-count'
import { RenderError } from '../../pages/geral/components/section-engajament/section-engajament'

import { CardInfoSkeleton } from '../card-info-skeleton/card-info-skeleton'
import { ChartPieDonutText } from './chart-pie-donut-text'

type CardInfoProps = {
  title: string
  value: number
  percentage: number
  data?: GetProgramsCountResponse
  isLoading?: boolean
}

export function CardProgress({
  title,
  value,
  percentage,
  data,
  isLoading,
}: CardInfoProps) {
  const generateChartData = (percentage: number) => {
    return [
      { data: percentage, fill: '#2D4A8C' },
      { data: 100 - percentage, fill: '#E6EAF0' },
    ]
  }

  if (isLoading) return <CardInfoSkeleton />

  if (!data) return <RenderError />

  const notStudyingChartData = generateChartData(percentage)

  return (
    <div className="flex h-full max-h-[93px] w-full flex-col rounded-xl border border-gray-100 p-4 shadow-sm">
      <div className="flex h-full w-full gap-4">
        <ChartPieDonutText
          data={notStudyingChartData}
          percentage={percentage}
        />

        <div className="flex h-full w-full flex-col justify-center gap-2">
          <span className="text-body-xs text-gray-500">{title}</span>
          <span className="text-headline-xs text-gray-500">{value}</span>
        </div>
      </div>
    </div>
  )
}
