import z from 'zod'
import axios from 'axios'

const url = 'https://jsonplaceholder.typicode.com/users'

const schema = z
  .object({
    email: z.string().email(),
    id: z.number(),
    name: z.string(),
  })
  .array()

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

async function fetchFakeUsers() {
  await sleep(500)
  //   throw new Error("Teste");
  const { data } = await axios(url)
  console.log({ data })
  return schema.parse(data)
}

export const useUsersQueryOptions = {
  queryKey: ['fake-users'],
  queryFn: fetchFakeUsers,
}
