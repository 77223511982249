import clsx from 'clsx'
import { useState } from 'react'
import { ContentWrapper } from '../../components/content-wrapper/content-wrapper'

import { SearchTextField } from '@/shared/components/ui/search-text-field'
import useDebouncedState from '@/shared/hooks/use-debounced-state'
import { useQuery } from '@tanstack/react-query'
import { Info } from 'lucide-react'
import {
  Data,
  TableTeamsDashboard,
} from '../../components/table-teams-dashboard/table-teams-dashboard'
import { columns } from './columns'
import { useGetTeams } from '@/shared/queries/dashboard/teams/use-get-all-teams'
import { Route as TeamRoute } from '@/routes/_authenticated/dashboard/teams/$teamId'
import { useNavigate } from '@tanstack/react-router'

export function DashboardTeams() {
  const [, setSelectedCategory] = useState<string | undefined>('')
  const [, setSelectedType] = useState<string | undefined>('')

  const [currentPage, setCurrentPage] = useState(1)

  const {
    state: searchValue,
    setState: setSearchValue,
    debouncedState: debouncedSearch,
  } = useDebouncedState({
    initialState: '',
  })

  const [currentColumnFilter, setCurrentColumnFilter] = useState<{
    sortBy: string
    sort: 'asc' | 'desc'
  }>({
    sortBy: 'name',
    sort: 'asc',
  })

  const {
    data,
    isLoading: isLoadingDashboardPeople,
    isFetching: isFetchingDashboardPeople,
    isError: isErrorDashboardPeople,
  } = useQuery(
    useGetTeams({
      page: currentPage,
      name: debouncedSearch,
      sort: currentColumnFilter.sort,
      sortBy: currentColumnFilter.sortBy,
    })
  )

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    setSelectedCategory('')
    setSelectedType('')
    setCurrentPage(1)
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const toggleSortOrder = (currentSort: 'asc' | 'desc'): 'asc' | 'desc' => {
    return currentSort === 'asc' ? 'desc' : 'asc'
  }

  const totalPages = data ? data.totalPages : 1

  const navigate = useNavigate({
    from: TeamRoute.fullPath,
  })
  const navigateToTeamDetails = (row: Data) => {
    navigate({
      to: `${row.teamId}`,
    })
  }

  return (
    <ContentWrapper>
      <div
        className={clsx('flex w-full flex-col', {
          'border-0': isLoadingDashboardPeople,
          'rounded-xl border border-gray-100': !isLoadingDashboardPeople,
        })}
      >
        {!isLoadingDashboardPeople && !isErrorDashboardPeople ? (
          <div className="flex min-h-24 w-full flex-col items-center p-3 lg:flex-row lg:justify-between">
            <div className="flex h-full max-h-max w-full items-start justify-start gap-2 lg:flex-col">
              <div className="mb-2 flex w-full items-center justify-between">
                <span className="text-title-md text-gray-500">Times</span>
                <div className="flex items-center justify-end gap-1">
                  <span className="text-body-xs text-gray-250">
                    Times ({data?.totalItems})
                  </span>
                  <Info className="text-gray-400" size={16} />
                </div>
              </div>
              <div className="flex h-full w-full items-center justify-center lg:max-w-[250px]">
                <SearchTextField
                  onChange={handleSearchChange}
                  value={searchValue}
                  placeholder="Pesquisar"
                />
              </div>
            </div>
          </div>
        ) : null}

        <div
          className={clsx({
            'cursor-wait [&>*]:opacity-50': isFetchingDashboardPeople,
          })}
        >
          <TableTeamsDashboard
            columns={columns}
            onRowClick={navigateToTeamDetails}
            data={data?.data ?? []}
            isError={isErrorDashboardPeople}
            isLoading={isLoadingDashboardPeople}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onFilterChange={({ sortBy }) => {
              const isSameFilter = sortBy === currentColumnFilter.sortBy
              const newSort = isSameFilter
                ? toggleSortOrder(currentColumnFilter.sort)
                : 'asc'

              setCurrentColumnFilter({
                sortBy,
                sort: newSort,
              })
            }}
          />
        </div>
      </div>
    </ContentWrapper>
  )
}
