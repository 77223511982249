import { Label, Pie, PieChart } from 'recharts'

import { ChartContainer } from '@/shared/components/ui/chart'

interface ChartPieDonutProps {
  data: { data: number; fill: string }[]
  width?: number
  height?: number
  innerRadius?: number
  outerRadius?: number
  percentage: number
}

export function ChartPieDonutText({
  data,
  width = 84,
  height = 84,
  innerRadius = 30,
  outerRadius = 40,
  percentage,
}: ChartPieDonutProps) {
  return (
    <ChartContainer
      config={{}}
      className="aspect-square"
      style={{ width, height }}
    >
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          dataKey="data"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          strokeWidth={5}
          startAngle={90}
          endAngle={-270}
        >
          <Label
            content={({ viewBox }) => {
              if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                return (
                  <text
                    x={viewBox.cx}
                    y={viewBox.cy}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    <tspan
                      x={viewBox.cx}
                      y={viewBox.cy}
                      className="fill-gray-250 text-title-sm"
                    >
                      {percentage}%
                    </tspan>
                  </text>
                )
              }
            }}
          />
        </Pie>
      </PieChart>
    </ChartContainer>
  )
}
