import { UsersPage } from '@/features/users/page'
import { useUsersQueryOptions } from '@/shared/queries/use-users'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_authenticated/users/')({
  // beforeLoad: async ({ context }) => {
  //   if (!context.isAuthenticated) {
  //     throw redirect({
  //       to: "/sso",
  //       search: {
  //         returnTo: location.href,
  //       },
  //     });
  //   }
  // },
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(useUsersQueryOptions),
  component: UsersPage,
  // errorComponent: ({reset}) => <UsersErrorPage reset={reset} />,
})
