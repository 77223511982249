import { Tooltip, TooltipProps } from '@mui/material'
import clsx from 'clsx'
import { Check } from 'lucide-react'

export type ButtonValue = number

type AnswerButtonProps = {
  isChecked: boolean
  onClick: () => void
}

function AnswerButtonTooltip(props: TooltipProps) {
  return (
    <Tooltip
      {...props}
      arrow
      sx={{
        '& .MuiTooltip-arrow': {
          color: '#484C50',
        },
        '& .MuiTooltip-tooltip': {
          backgroundColor: '#484C50',
        },
      }}
    />
  )
}

const renderTextTooltip = (value: number) => {
  if (value === 1) return 'Discordo totalmente'
  if (value === 2) return 'Discordo parcialmente'
  if (value === 3) return 'Não concordo e nem discordo'
  if (value === 4) return 'Concordo parcialmente'
  if (value === 5) return 'Concordo totalmente'
}

function AnswerButton1({ isChecked, onClick }: AnswerButtonProps) {
  return (
    <AnswerButtonTooltip
      title={renderTextTooltip(1)}
      enterDelay={500}
      enterNextDelay={500}
    >
      <button
        className={clsx(
          'mx-2 flex h-[52px] w-[52px] items-center justify-center rounded-full border-2 transition-colors duration-300 ease-in-out',
          {
            'hover:bg-[#FF6559]': true,
            'border-[#FF6559]': true,
            'bg-[#FF6559]': isChecked,
          }
        )}
        onClick={onClick}
      >
        {isChecked && <Check color="#fff" />}
      </button>
    </AnswerButtonTooltip>
  )
}

function AnswerButton2({ isChecked, onClick }: AnswerButtonProps) {
  return (
    <AnswerButtonTooltip
      title={renderTextTooltip(2)}
      enterDelay={500}
      enterNextDelay={500}
    >
      <button
        className={clsx(
          'mx-2 flex h-11 w-11 items-center justify-center rounded-full border-2 transition-colors duration-300 ease-in-out',
          {
            'hover:bg-[#FF6559]': true,
            'border-[#FF6559]': true,
            'bg-[#FF6559]': isChecked,
          }
        )}
        onClick={onClick}
      >
        {isChecked && <Check color="#fff" />}
      </button>
    </AnswerButtonTooltip>
  )
}

function AnswerButton3({ isChecked, onClick }: AnswerButtonProps) {
  return (
    <AnswerButtonTooltip
      title={renderTextTooltip(3)}
      enterDelay={500}
      enterNextDelay={500}
    >
      <button
        className={clsx(
          'mx-2 flex h-10 w-10 items-center justify-center rounded-full border-2 transition-colors duration-300 ease-in-out',
          {
            'hover:bg-[#727880]': true,
            'border-[#727880]': true,
            'bg-[#727880]': isChecked,
          }
        )}
        onClick={onClick}
      >
        {isChecked && <Check color="#fff" />}
      </button>
    </AnswerButtonTooltip>
  )
}

function AnswerButton4({ isChecked, onClick }: AnswerButtonProps) {
  return (
    <AnswerButtonTooltip
      title={renderTextTooltip(4)}
      enterDelay={500}
      enterNextDelay={500}
    >
      <button
        className={clsx(
          'mx-2 flex h-11 w-11 items-center justify-center rounded-full border-2 transition-colors duration-300 ease-in-out',
          {
            'hover:bg-[#2d4a8c]': true,
            'border-[#2d4a8c]': true,
            'bg-[#2d4a8c]': isChecked,
          }
        )}
        onClick={onClick}
      >
        {isChecked && <Check color="#fff" />}
      </button>
    </AnswerButtonTooltip>
  )
}

function AnswerButton5({ isChecked, onClick }: AnswerButtonProps) {
  return (
    <AnswerButtonTooltip
      title={renderTextTooltip(5)}
      enterDelay={500}
      enterNextDelay={500}
    >
      <button
        className={clsx(
          'mx-2 flex h-[52px] w-[52px] items-center justify-center rounded-full border-2 transition-colors duration-300 ease-in-out',
          {
            'hover:bg-[#2d4a8c]': true,
            'border-[#2d4a8c]': true,
            'bg-[#2d4a8c]': isChecked,
          }
        )}
        onClick={onClick}
      >
        {isChecked && <Check color="#fff" />}
      </button>
    </AnswerButtonTooltip>
  )
}

// Componente para renderizar todos os cinco botões
export function AnswerButtonGroup({
  selectedValue,
  onClick,
}: {
  selectedValue: ButtonValue | null
  onClick: (value: ButtonValue) => void
}) {
  return (
    <div className="flex items-center justify-center">
      <AnswerButton1
        isChecked={selectedValue === 1}
        onClick={() => onClick(1)}
      />
      <AnswerButton2
        isChecked={selectedValue === 2}
        onClick={() => onClick(2)}
      />
      <AnswerButton3
        isChecked={selectedValue === 3}
        onClick={() => onClick(3)}
      />
      <AnswerButton4
        isChecked={selectedValue === 4}
        onClick={() => onClick(4)}
      />
      <AnswerButton5
        isChecked={selectedValue === 5}
        onClick={() => onClick(5)}
      />
    </div>
  )
}
