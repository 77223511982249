import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import { keepPreviousData } from '@tanstack/react-query'

const userProgressSchema = z.object({
  userId: z.string(),
  userName: z.string(),
  programId: z.string(),
  programName: z.string(),
  startDate: z.string(),
  endDate: z.string().nullable(),
  daysInProgress: z.number(),
  progress: z.number(),
  picture: z.string().nullable(),
})

const linkSchema = z.object({
  rel: z.string(),
  href: z.string(),
})

const userProgressResponseSchema = z.object({
  currentPage: z.number(),
  totalPages: z.number(),
  totalItems: z.number(),
  data: z.array(userProgressSchema),
  links: z.array(linkSchema),
})

export type GetUserProgressResponse = z.infer<typeof userProgressResponseSchema>

export async function getUserProgress({
  page,
  size,
  input,
  sort,
  sortBy,
}: {
  page: number
  size: number
  input?: string
  sort?: string
  sortBy?: string
}) {
  const url = apiUrls.dashboard.programs.getUserProgress
  const token = Cookies.get('auth-cookie') ?? ''

  const params: Record<string, any> = { page, size }
  if (input) params.input = input
  if (sort) {
    params.sort = sort
    params.sortBy = sortBy
  }

  try {
    const { data } = await axios.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('API Response:', data)

    const parsedData = userProgressResponseSchema.parse(data)
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetUserProgressQueryKey = 'get-user-progress-query-key'

export const useGetUserProgress = ({
  page = 1,
  size = 10,
  input,
  sort,
  sortBy,
}: {
  page: number
  size?: number
  input?: string
  sort?: string
  sortBy?: string
}) => ({
  queryKey: [useGetUserProgressQueryKey, page, size, input, sort, sortBy],
  queryFn: () => getUserProgress({ page, size, input, sort, sortBy }),
  placeholderData: keepPreviousData,
})
