import clsx from 'clsx'
import { Check, Clock } from 'lucide-react'
import { ProgramsCardSkeleton } from './programs-card-skeleton'
import { useQuery } from '@tanstack/react-query'
import { useCourseDetail } from '@/shared/queries/dashboard/courses/use-course-detail'
import { useParams } from '@tanstack/react-router'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/shared/components/tooltip'

import pluralize from 'pluralize'
import { ComponentError } from '@/shared/components/ui/component-error'

export function ProgramsCard() {
  const { courseId } = useParams({
    from: '/_authenticated/dashboard/courses/$courseId',
  })

  const {
    data: courseDetailData,
    isLoading: isLoadingCourseDetail,
    isError: isErrorCourseDetail,
  } = useQuery(useCourseDetail({ id: courseId }))

  function SquareIcon({ children }: { children: React.ReactNode }) {
    return (
      <div className="flex h-9 w-9 items-center justify-center rounded-[6px] bg-brand-coral-150 text-coral-400">
        {children}
      </div>
    )
  }

  pluralize.addPluralRule(/mentor$/i, 'mentores')

  if (isErrorCourseDetail)
    return (
      <div className="flex h-max max-h-[204px] min-h-[204px] w-full flex-col">
        <ComponentError />
      </div>
    )
  if (isLoadingCourseDetail) return <ProgramsCardSkeleton />

  return (
    <div className="flex h-max w-full flex-col md:max-h-[204px] md:min-h-[204px] md:flex-row">
      <div className="flex h-max w-full flex-col gap-4 rounded-xl md:flex-row">
        <div
          className={clsx(
            'flex w-full rounded-xl border border-gray-150 p-4 md:max-h-[204px] md:min-h-[204px]',
            {
              'h-max max-h-max': courseDetailData?.type === 'COURSE',
            }
          )}
        >
          <div className="flex w-full flex-col gap-4 md:flex-row">
            <div
              className={clsx(
                'flex max-h-[240px] w-full rounded-[8px] md:max-w-[306px]',
                {
                  'bg-none': courseDetailData?.type === 'COURSE',
                }
              )}
            >
              <img
                src={courseDetailData?.banner}
                alt={courseDetailData?.name}
                className={clsx('rounded-[8px]', {
                  'object-cover': courseDetailData?.type === 'COURSE',
                })}
              />
            </div>
            <div className="flex w-full flex-col gap-4">
              <div className="flex w-full flex-col items-start">
                <span className="text-body-xs text-gray-250">Formação</span>
                <span className="line-clamp-1 text-title-md text-gray-500">
                  {courseDetailData?.name}
                </span>
              </div>

              <div className="flex w-full flex-col items-start">
                <span className="text-body-xs text-gray-250">
                  {`${pluralize('Mentor', courseDetailData?.mentors.length)}`}
                </span>

                <TooltipProvider>
                  <Tooltip delayDuration={100}>
                    <TooltipTrigger>
                      <span className="line-clamp-1 text-title-md text-gray-500 md:line-clamp-2">
                        {courseDetailData?.mentors?.length
                          ? courseDetailData.mentors
                              .map((mentor) => mentor.name)
                              .join(', ')
                          : 'Sem mentor'}
                      </span>
                    </TooltipTrigger>

                    <TooltipContent
                      align="end"
                      className="flex max-w-[300px] items-center"
                    >
                      {courseDetailData?.mentors?.length
                        ? courseDetailData.mentors
                            .map((mentor) => mentor.name)
                            .join(', ')
                        : 'Sem mentor'}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>

              <div className="flex items-center gap-2 text-title-md text-gray-500">
                <SquareIcon>
                  <Clock />
                </SquareIcon>
                {`${courseDetailData?.totalModules} ${pluralize('Módulo', courseDetailData?.totalModules)} - ${courseDetailData?.totalLessons} ${pluralize('aula', courseDetailData?.totalLessons)}`}
              </div>
            </div>
          </div>
        </div>

        <div className="flex h-full w-full flex-row gap-4 md:max-w-[190px] md:flex-col">
          <div className="flex h-full max-h-[94px] w-full flex-col items-start gap-[6px] rounded-xl border border-gray-150 p-4 shadow-sm">
            <span className="text-body-xs text-gray-250">Concluíram</span>
            <div className="flex items-center justify-center gap-2">
              <SquareIcon>
                <Check />
              </SquareIcon>
              <div className="flex items-center gap-1">
                <span className="text-title-md text-gray-500">
                  {courseDetailData?.totalCompleted}
                </span>

                <span className="text-title-md text-gray-500">
                  {pluralize('pessoa', courseDetailData?.totalCompleted)}
                </span>
              </div>
            </div>
          </div>

          <div className="flex h-full max-h-[94px] w-full flex-col items-start gap-[6px] rounded-xl border border-gray-150 p-4 shadow-sm">
            <span className="text-body-xs text-gray-250">Duração</span>
            <div className="flex items-center justify-center gap-2">
              <SquareIcon>
                <Clock />
              </SquareIcon>
              <span className="text-title-md text-gray-500">
                {courseDetailData?.duration}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
