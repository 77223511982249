import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from '@/shared/components/ui/pagination/pagination'
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import React from 'react'

type PaginationDashboardProps = {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

export const PaginationDashboard: React.FC<PaginationDashboardProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  }

  return (
    <Pagination className="p-3">
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            onClick={handlePrevious}
            className="flex cursor-pointer items-center justify-center"
          >
            <ChevronLeftIcon size={20} />
            <span className="text-body-md text-gray-500">Anterior</span>
          </PaginationPrevious>
        </PaginationItem>
        <PaginationItem className="flex items-center justify-center">
          <span className="text-body-md font-semibold text-gray-500">
            Página {currentPage} de {totalPages}
          </span>
        </PaginationItem>
        <PaginationItem>
          <PaginationNext
            onClick={handleNext}
            className="flex cursor-pointer items-center justify-center"
          >
            <span className="text-body-md text-gray-500">Próximo</span>
            <ChevronRightIcon size={20} />
          </PaginationNext>
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )
}
