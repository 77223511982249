import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'

const programsWatchedHoursSchema = z.array(
  z.object({
    date: z.string(),
    hourCount: z.number(),
    userCount: z.number(),
    label: z.string(),
    miniLabel: z.string(),
  })
)

export type GetProgramsWatchedHoursResponse = z.infer<
  typeof programsWatchedHoursSchema
>

export async function getProgramsWatchedHours() {
  const url = apiUrls.dashboard.programs.getProgramsWatchedHours
  const token = Cookies.get('auth-cookie') ?? ''

  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('API Response:', data)

    const parsedData = programsWatchedHoursSchema.parse(data)
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useProgramsWatchedHoursQueryKey =
  'programs-watched-hours-query-key'

export const useProgramsWatchedHours = () => ({
  queryKey: [useProgramsWatchedHoursQueryKey],
  queryFn: getProgramsWatchedHours,
})
