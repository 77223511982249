import ConfirmationDialog from '@/shared/components/ui/confirmation-dialog'
import { toast } from '@/shared/helpers/toast-sonner'
import { useDeleteDiagnosis } from '@/shared/queries/diagnosis/use-delete-diagnosis'
import {
  useGetDiagnosis,
  useGetDiagnosisQueryKey,
} from '@/shared/queries/diagnosis/use-diagnosis'
import {
  ASSESSMENT_RESULT_ROUTE,
  ASSESSMENT_ROUTE,
  ASSESSMENT_ROUTE_QUESTIONS,
} from '@/shared/routes'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useAtom } from 'jotai'
import { Card } from './components/card/card'
import { Carousel } from './components/carousel'
import { CreateAssessmentCard } from './components/create-assessment-card/create-assessment-card'
import {
  assessmentIdSelectedAtom,
  openInAssessmentConfirmationDialogAtom,
} from './diagnosis-atom-states'

export function AssessmentPage() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [
    isOpenInAssessmentConfirmationDialog,
    setOpenInAssessmentConfirmationDialog,
  ] = useAtom(openInAssessmentConfirmationDialogAtom)

  const [assessmentIdSelected, setAssessmentIdSelectedAtom] = useAtom(
    assessmentIdSelectedAtom
  )

  const { data } = useQuery(
    useGetDiagnosis({
      organizationId: 'cdd9517b-ed58-48aa-b4ba-e748ee54e83a',
    })
  )

  const { mutate: deleteDiagnosis, isPending } = useDeleteDiagnosis({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useGetDiagnosisQueryKey],
      })
      toast.success('Diagnóstico excluído com sucesso')
      setAssessmentIdSelectedAtom(null)
      setOpenInAssessmentConfirmationDialog(false)
    },
    onError: () => {
      toast.error('Erro ao excluir diagnóstico')
    },
  })

  function handleDelete() {
    deleteDiagnosis({ id: assessmentIdSelected ?? '' })
  }

  function goToCreateAssessment() {
    navigate({
      from: ASSESSMENT_ROUTE,
      to: ASSESSMENT_ROUTE_QUESTIONS,
    })
  }

  function goToResultAssessment() {
    navigate({
      from: ASSESSMENT_ROUTE,
      to: ASSESSMENT_RESULT_ROUTE,
    })
  }

  return (
    <div className="my-8 flex h-full w-full flex-col overflow-hidden">
      <ConfirmationDialog
        title="Excluir diagnóstico de Maturidade"
        message="Você tem certeza que deseja excluir o diagnóstico?"
        onClick={handleDelete}
        isLoading={isPending}
        loadingText=""
        open={isOpenInAssessmentConfirmationDialog}
        handleClose={() => setOpenInAssessmentConfirmationDialog(false)}
      />

      <div className="flex min-h-[calc(100vh-64px)] w-full flex-col bg-white p-4 sm:px-9 sm:py-8">
        <div className="flex flex-col items-start justify-center sm:flex-row sm:items-center sm:justify-between">
          <h5 className="text-title-xl text-gray-450 sm:text-title-2xl">
            Diagnóstico de Maturidade
          </h5>
          <span className="hidden text-body-md text-body-gray-400 md:flex md:text-title-md lg:text-title-lg">
            Acompanhe o nível de maturidade da sua empresa
          </span>
        </div>

        <div className="mt-6 flex pb-6">
          <Carousel>
            <CreateAssessmentCard onClick={goToCreateAssessment} />
            {data?.map((diagnosis, index) => (
              <Card
                series={diagnosis.outputs?.map((output) =>
                  Number(output.categoryScore)
                )}
                key={diagnosis.id}
                onClick={goToResultAssessment}
                index={index + 1}
                openModalDelete={() => {
                  setOpenInAssessmentConfirmationDialog(true)
                  setAssessmentIdSelectedAtom(diagnosis.id)
                }}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}
