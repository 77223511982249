import { Carousel } from './components/carousel'

export function AssessmentLoading() {
  return (
    <div
      role="status"
      className="flex min-h-[calc(100vh-64px)] w-full animate-pulse flex-col bg-white p-4 sm:px-9 sm:py-8"
    >
      <div className="flex w-full justify-between">
        <div className="max-w[290px] mb-4 h-[33px] max-h-[33px] w-[290px] rounded-lg bg-gray-200"></div>
        <div className="hidden h-[33px] max-h-[33px] w-[400px] max-w-[400px] rounded-lg bg-gray-200 md:flex"></div>
      </div>
      <Carousel>
        {[1, 2, 3, 4, 5].map((index) => (
          <div
            key={index}
            className="flex h-[406px] w-[276px] min-w-[276px] flex-col items-center justify-between rounded-[10px] bg-gray-200 shadow-lg"
          ></div>
        ))}
      </Carousel>
    </div>
  )
}
