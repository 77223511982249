import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import Cookies from 'js-cookie'
import axios from 'axios'

const dashboardContentCategoriesResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
})

const dashboardContentsCategoriesResponseSchema = z.object({
  data: z.array(dashboardContentCategoriesResponseSchema),
})

export type GetDashboardPeopleTeamsResponse = z.infer<
  typeof dashboardContentsCategoriesResponseSchema
>

export async function getDashboardContentsCategories() {
  const url = apiUrls.dashboard.contents.getDashboardContentsCategories
  const token = Cookies.get('auth-cookie') ?? ''

  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data) // Adicionando log para inspecionar a resposta
    const parsedData = dashboardContentsCategoriesResponseSchema.parse(data) // Acessando a propriedade data dentro do objeto data
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetDashboardContentCategoriesQueryKey =
  'get-dashboard-content-categories-query-key'

export const useGetDashboardContentCategories = () => ({
  queryKey: [useGetDashboardContentCategoriesQueryKey],
  queryFn: () => getDashboardContentsCategories(),
})
