import { ContentWrapper } from '@/features/dashboard/components/content-wrapper/content-wrapper'
import { Button } from '@/shared/components/ui/button'
import { useNavigate } from '@tanstack/react-router'
import { ArrowLeft } from 'lucide-react'
import { PeopleDetailsCard } from '../components/people-details-card'
export function PeopleDetailPage() {
  const navigate = useNavigate()

  function handleGoBackToPeople() {
    navigate({
      to: '/dashboard/people',
      replace: true,
    })
  }

  return (
    <ContentWrapper>
      <div className="flex w-full items-center gap-3">
        <Button
          variant="outlined"
          className="flex max-h-8 items-center justify-center"
          onClick={handleGoBackToPeople}
        >
          <ArrowLeft size={18} />
          <span className="text-label-lg text-gray-500">Voltar</span>
        </Button>

        <span className="text-title-md text-gray-500">
          Detalhamento de Pessoas
        </span>
      </div>
      <PeopleDetailsCard />
    </ContentWrapper>
  )
}
