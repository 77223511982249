import { PaginationDashboard } from '@/features/dashboard/components/pagination-dashboard/pagination-dashboard'
import { Dialog } from '@/shared/components/ui/dialog-ui'
import clsx from 'clsx'
import { UserRound, X } from 'lucide-react'

import { Route as PeopleRoute } from '@/routes/_authenticated/dashboard/people/index'

import { useState } from 'react'

import { useNavigate } from '@tanstack/react-router'
import './styles.css'

interface UserListDialogProps {
  isOpen: boolean
  onClose: () => void
  title: string
  data?: any
  isLoading?: boolean
  isFetching?: boolean
  emptyState?: {
    title: string
    description: string
  }
  onPageChange: (page: number) => void
}

export function UserListDialog({
  isOpen,
  onClose,
  title,
  data,
  isLoading,
  isFetching,
  emptyState = {
    title: 'Nenhum usuário encontrado',
    description: 'Tente novamente mais tarde',
  },
  onPageChange,
}: UserListDialogProps) {
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = data ? data.totalPages : 1

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    onPageChange(page)
  }

  const navigate = useNavigate({
    from: '/dashboard/people',
  })

  const navigateToPeopleDetails = (row: any) => {
    navigate({
      to: `${PeopleRoute.fullPath}/${row.id}`,
    })
    onClose()
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="flex h-[550px] flex-col bg-white">
        <div className="flex items-center justify-between border-b p-4">
          <h2 className="text-title-md text-gray-500">{title}</h2>
          <button
            onClick={onClose}
            className="rounded-full p-1 hover:bg-gray-100"
          >
            <X className="h-5 w-5 text-gray-400" />
          </button>
        </div>

        {isLoading ? (
          <div className="flex flex-1 flex-col gap-4 p-4">
            {Array.from({ length: 5 }).map((_, index) => (
              <div
                key={index}
                className="flex animate-pulse items-center gap-6"
              >
                <div className="h-12 w-12 rounded-full bg-gray-100" />
                <div className="h-4 w-48 rounded bg-gray-100" />
              </div>
            ))}
          </div>
        ) : (
          <div
            className={clsx(
              'flex flex-1 flex-col overflow-hidden',
              isFetching && 'opacity-50'
            )}
          >
            {data && data.data.length > 0 ? (
              <>
                <div className="custom-scrollbar flex-1 overflow-y-auto px-1">
                  {data.data.map((user: any) => (
                    <div
                      className="flex w-full items-center gap-6 border border-blue-100 p-3"
                      key={user.id}
                      onClick={() => navigateToPeopleDetails(user)}
                    >
                      {user.picture ? (
                        <img
                          className="h-12 w-12 rounded-full"
                          src={user.picture}
                          alt={user.name}
                        />
                      ) : (
                        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-50">
                          <UserRound className="h-6 w-6 text-gray-200" />
                        </div>
                      )}
                      <span className="line-clamp-1 text-body-md text-gray-400">
                        {user.name}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="border-t p-4">
                  <PaginationDashboard
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </>
            ) : (
              <div className="flex flex-1 flex-col items-center justify-center gap-4 p-6">
                <UserRound className="h-16 w-16 text-gray-200" />
                <div className="text-center">
                  <p className="text-lg font-medium text-gray-400">
                    {emptyState.title}
                  </p>
                  <p className="mt-1 text-sm text-gray-300">
                    {emptyState.description}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Dialog>
  )
}
