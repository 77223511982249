import { Data } from '@/features/dashboard/components/table-dashboard/table-dashboard'
import clsx from 'clsx'

type BannerTable = {
  row: Data
}

export function BannerTable({ row }: BannerTable) {
  return (
    <div className="flex items-center gap-3">
      <img
        src={row.banner}
        alt={row.name}
        className={clsx(
          'aspect-video h-[32px] w-[60px] min-w-[60px] max-w-[60px] rounded-[6px]',
          {
            'bg-gray-600 object-contain': row.type === 'BOOK',
            'object-cover': row.type === 'COURSE',
          }
        )}
      />
      <span className="line-clamp-1 px-3 text-body-md text-gray-500">
        {row.name}
      </span>
    </div>
  )
}
