import { useActionsQueryOption } from '@/shared/queries/actions/use-actions'
import { createFileRoute } from '@tanstack/react-router'

type ActionsFilters = {
  name?: string
}

export const Route = createFileRoute('/_authenticated/actions/')({
  validateSearch: (search): ActionsFilters => {
    return {
      name: search.name as string,
    }
  },

  loader: ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData(useActionsQueryOption({ name: '' }))
  },
})
