type CircleInfoProps = {
  value: number
  description: string
}

export function CircleInfo({ value, description }: CircleInfoProps) {
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format(value)

  return (
    <div className="flex w-full gap-4">
      <div className="flex h-[84px] max-h-[84px] w-full max-w-[84px] items-center justify-center rounded-full bg-gray-100">
        <div className="justfiy-center flex flex-col items-center">
          <span className="text-title-md text-body-gray-500">
            {formattedValue}h
          </span>
        </div>
      </div>
      <div className="flex w-full max-w-[116px] flex-col justify-center">
        <span className="text-body-xs text-gray-400">{description}</span>
      </div>
    </div>
  )
}
