import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../api-urls'

const diagnosisSchema = z.object({
  id: z.string(),
})

export type DeleteAssessmentFormType = z.infer<typeof diagnosisSchema>

async function deleteDiagnosis({ id }: { id: string }) {
  const url = apiUrls.diagnosis.deleteDiagnosis
  const token = Cookies.get('auth-cookie') ?? ''
  try {
    const { data } = await axios.delete(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useDeleteDiagnosisQueryKey = 'use-delete-diagnosis-query-key'

type deleteDiagnosisProps = {
  onSuccess?: () => void
  onError?: () => void
}

export function useDeleteDiagnosis({
  onSuccess,
  onError,
}: deleteDiagnosisProps) {
  return useMutation({
    mutationFn: deleteDiagnosis,
    mutationKey: ['use-delete-diagnosis-query-key'],
    onSuccess: onSuccess,
    onError: onError,
  })
}
