import { toast as sonnerToast } from 'sonner'

const toast = {
  success(message: string) {
    sonnerToast.success(message)
  },
  error(message: string) {
    sonnerToast.error(message)
  },
  warning(message: string) {
    sonnerToast.warning(message)
  },
}

export { toast }
