import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import Cookies from 'js-cookie'
import axios from 'axios'

const dashboardPeopleEngagementResponseSchema = z.object({
  totalUsersNotStuding: z.number(),
  totalUsersStuding: z.number(),
  totalProgramsCompleted: z.number(),
})

export type GetDashboardPeopleEngagementResponse = z.infer<
  typeof dashboardPeopleEngagementResponseSchema
>

export async function getDashboardPeopleEngagement() {
  const url = apiUrls.dashboard.people.getDashboardPeopleEngagement
  const token = Cookies.get('auth-cookie') ?? ''

  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data) // Adicionando log para inspecionar a resposta
    const parsedData = dashboardPeopleEngagementResponseSchema.parse(data) // Acessando a propriedade data dentro do objeto data
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetDashboardPeopleEngagementQueryKey =
  'get-dashboard-people-engagement-query-key'

export const useGetDashboardPeopleEngagement = () => ({
  queryKey: [useGetDashboardPeopleEngagementQueryKey],
  queryFn: () => getDashboardPeopleEngagement(),
})
