import { Link } from '@tanstack/react-router'
import { Button } from './ui/button'

type Props = {
  reset: () => void
  error: Error
}

export function ErrorPage({ reset, error }: Props) {
  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
  }
  return (
    <div className="flex min-h-[100dvh] w-full flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <div className="mx-auto h-12 w-12 text-primary" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
          Opa, algo deu errado!
        </h1>
        <p className="mt-4 text-muted-foreground">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus
          itaque veniam optio eveniet, cumque iste tempora architecto libero eos
          necessitatibus?
        </p>
        <div className="mt-6 flex flex-col gap-2">
          {/* <Button onClick={reset}>Tentar novamente</Button> */}
          <Link to="/" onClick={reset}>
            <Button variant="outlined">Voltar para a página inicial</Button>
          </Link>
          <Button
            variant="text"
            onClick={() => {
              copyToClipboard(error.message)
            }}
          >
            Copiar erro
          </Button>
        </div>
      </div>
    </div>
  )
}
