import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts'
import { ChartContainer, ChartTooltip } from '@/shared/components/ui/chart'
import { DashboardWatchedHours } from '../queries/dashboard/contents/use-dashboard-watched-hours'

interface ChartAreaLinearProps {
  data: DashboardWatchedHours
  width?: number | string
  height?: number
  margin?: { left?: number; right?: number; top?: number; bottom?: number }
}

export function ChartAreaLinear({
  data,
  width = '100%',
  height = 350,
  margin = { left: -22, right: 6, top: 10, bottom: 0 },
}: ChartAreaLinearProps) {
  const transformedData = data.map((item) => ({
    date: item.date,
    hourCount: item.hourCount,
    userCount: item.userCount,
    label: item.label,
    miniLabel: item.miniLabel,
  }))

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="rounded border border-border bg-background p-2 shadow-md">
          <p className="text-label-lg text-gray-500">
            {payload[0].payload.label}
          </p>
          <p className="text-body-sm text-gray-400">
            Horas finalizadas: {payload[0].value}h
          </p>
          <p className="text-body-sm text-gray-400">
            N° de usuários: {payload[0].payload.userCount}
          </p>
        </div>
      )
    }
    return null
  }

  return (
    <ChartContainer
      config={{}}
      style={{
        width,
        height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={transformedData} margin={margin}>
          <defs>
            <linearGradient id="graphAreaColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="18.72%" stopColor="#EBEEFF" stopOpacity={1} />
              <stop offset="100%" stopColor="#FFF" stopOpacity={1} />
            </linearGradient>
          </defs>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="miniLabel"
            tickLine={false}
            axisLine={false}
            tickMargin={12}
            tickFormatter={(value) => value}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            tickMargin={12}
            domain={[0, 'dataMax + 20']}
            tickFormatter={(value) => value.toFixed(0)}
          />
          <ChartTooltip cursor={false} content={<CustomTooltip />} />
          <Area
            dataKey="hourCount"
            type="linear"
            fill="url(#graphAreaColor)"
            fillOpacity={1}
            stroke="#2D4A8C"
            strokeWidth={3}
          />
        </AreaChart>
      </ResponsiveContainer>
    </ChartContainer>
  )
}
