import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import { keepPreviousData } from '@tanstack/react-query'

const userSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  picture: z.string().nullable(),
})

const dataSchema = z.object({
  visitDate: z.string(),
  label: z.string(),
  labelXAxis: z.string(),
  users: z.array(userSchema).optional(),
})

const dashboardGeneralUserVisitsResponseSchema = z.object({
  data: z.array(dataSchema),
})

export type UserDashboardGeneralProps = z.infer<typeof userSchema>

export type GetFormationUsersResponse = z.infer<
  typeof dashboardGeneralUserVisitsResponseSchema
>

export async function getUserVisit({ interval }: { interval: number }) {
  const url = apiUrls.dashboard.getUserVisit
  const token = Cookies.get('auth-cookie') ?? ''

  const params: Record<string, any> = { interval }
  try {
    const { data } = await axios.get(`${url}`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data) // Adicionando log para inspecionar a resposta
    const parsedData = dashboardGeneralUserVisitsResponseSchema.parse(data) // Acessando a propriedade data dentro do objeto data
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetUserVisitsQueryKey = 'get-dashboard-user-visits-query-key'

export const useGetUserVisits = ({ interval }: { interval: number }) => ({
  queryKey: [useGetUserVisitsQueryKey, interval],
  queryFn: () => getUserVisit({ interval }),
  placeholderData: keepPreviousData,
})
