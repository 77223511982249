import { Dialog as DialogUI } from '@mui/material'
import { ReactElement } from 'react'

interface DialogUIProps {
  isOpen: boolean
  onClose: () => void
  children: ReactElement
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  borderRadius?: string
  fullWidth?: boolean
  fullScreen?: boolean
}

export function Dialog({
  isOpen,
  onClose,
  borderRadius = '8px',
  maxWidth = 'sm',
  fullWidth = true,
  fullScreen,
  children,
}: DialogUIProps) {
  return (
    <DialogUI
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius,
        },
      }}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
    >
      {children}
    </DialogUI>
  )
}
