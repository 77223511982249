import { BodyQuestions } from '@/features/diagnosis/components/body-questions/body-questions'
import { useGetAllQuestionsDiagnosis } from '@/shared/queries/diagnosis/use-all-questions-diagnosis'
import { useQuery } from '@tanstack/react-query'

export function CultureAndLeadership() {
  const { data } = useQuery(useGetAllQuestionsDiagnosis())

  if (!data || data.length === 0) return null

  const cultureAndLeadershipData = data[3]

  return <BodyQuestions category={cultureAndLeadershipData} />
}
