export function CardInfoSkeleton() {
  return (
    <div className="flex h-full max-h-[83px] w-full animate-pulse flex-col rounded-xl border border-gray-100 bg-gray-200 p-4 shadow-sm">
      <div className="flex h-full w-full flex-col gap-2">
        <span className="h-4 w-1/2 rounded"></span>
        <span className="h-6 w-full rounded"></span>
      </div>
    </div>
  )
}
