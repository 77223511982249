import { useGetDashboardContentsInterestAreas } from '@/shared/queries/dashboard/contents/use-dashboard-interest-areas'
import { useQuery } from '@tanstack/react-query'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import clsx from 'clsx'
import { atom, useAtom } from 'jotai'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { CardShadow } from '../../../../components/card-shadow/card-shadow'
import { SectionAreasInterestSkeleton } from './loading'
import { ComponentError } from '@/shared/components/ui/component-error'

type SectionAreasInterestProps = {
  pageSize: number
}

export const pageSectionAreasInterest = atom(1)

export function SectionAreasInterest({ pageSize }: SectionAreasInterestProps) {
  type Data = {
    name: string
    total: number
  }

  const columnHelper = createColumnHelper<Data>()

  const columns = [
    columnHelper.accessor('name', {
      header: 'Categoria',
      cell: (info) => {
        const rowIndex = info.row.index
        return (
          <div className="flex items-center gap-2">
            <span className="text-gray-400">
              {rowIndex + 1 + (page - 1) * pageSize}
            </span>
            <span>{info.getValue()}</span>
          </div>
        )
      },
    }),
    columnHelper.accessor('total', {
      header: 'Pessoas',
      cell: (info) => info.getValue(),
    }),
  ]

  const [page, setPage] = useAtom(pageSectionAreasInterest)

  const {
    data: apiData,
    isLoading,
    isError,
    isFetching: isFetchingDashboardContentsInterestAreas,
  } = useQuery(
    useGetDashboardContentsInterestAreas({
      page,
      size: pageSize,
    })
  )

  const table = useReactTable({
    data: apiData?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize,
      },
    },
  })

  const handleNextPage = () => {
    setPage((old) => Math.min(old + 1, apiData?.totalPages || 1))
  }

  const handlePreviousPage = () => {
    setPage((old) => Math.max(old - 1, 1))
  }

  if (isError)
    return (
      <div className="flex h-full min-h-[375px] w-full flex-col items-center justify-center">
        <ComponentError />
      </div>
    )
  if (isLoading) return <SectionAreasInterestSkeleton />

  return (
    <CardShadow>
      <div
        className={clsx('flex h-full w-full flex-col', {
          'cursor-wait [&>*]:opacity-50':
            isFetchingDashboardContentsInterestAreas,
        })}
      >
        <h2 className="mb-6 text-title-md text-gray-500">Áreas de interesse</h2>
        <div className="flex-grow">
          <table className="min-w-full table-auto">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={clsx('text-body-sm text-gray-250', {
                        'text-left': index === 0,
                        'text-right': index === 1,
                      })}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="border-b border-gray-200">
                  <td colSpan={columns.length}>
                    <div className="flex justify-between">
                      {row.getVisibleCells().map((cell) => (
                        <div
                          key={cell.id}
                          className="py-[6px] text-body-xs text-gray-500"
                        >
                          <div className="flex items-center gap-2">
                            <span>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex items-center justify-end">
          <button
            onClick={handlePreviousPage}
            disabled={page === 1}
            className="text-gray-500 disabled:opacity-50"
          >
            <ChevronLeft size={20} />
          </button>
          <button
            onClick={handleNextPage}
            disabled={page === (apiData?.totalPages || 1)}
            className="text-gray-500 disabled:opacity-50"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </div>
    </CardShadow>
  )
}
