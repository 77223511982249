import {
  GetAllQuestionsDiagnosisResponseProps,
  useGetAllQuestionsDiagnosis,
} from '@/shared/queries/diagnosis/use-all-questions-diagnosis'
import { useQuery } from '@tanstack/react-query'
import { BodyQuestions } from '../components/body-questions/body-questions'

export function BusinessManagement() {
  const { data } = useQuery<GetAllQuestionsDiagnosisResponseProps>(
    useGetAllQuestionsDiagnosis()
  )

  if (!data || data.length === 0) return null

  const businessManagementData = data[0]

  return <BodyQuestions category={businessManagementData} />
}
