export function PeopleDetailsSkeleton() {
  return (
    <div className="animte-pulse flex h-max w-full flex-col md:max-h-[204px] md:min-h-[204px] md:flex-row">
      <div className="flex h-max w-full flex-col gap-4 rounded-xl md:flex-row">
        <div className="flex h-[351px] max-h-[351px] w-full rounded-xl border border-gray-150 bg-gray-200 p-4 md:max-h-[204px] md:min-h-[204px]">
          <div className="flex w-full flex-col gap-4 md:flex-row">
            <div className="flex max-h-[240px] w-full rounded-[8px] md:max-w-[306px]" />
          </div>
        </div>

        <div className="flex w-full flex-row gap-4 md:max-w-[190px] md:flex-col">
          <div className="flex h-[94px] max-h-[94px] w-full flex-col items-start gap-[6px] rounded-xl border border-gray-150 bg-gray-200 p-4 shadow-sm" />

          <div className="flex h-[94px] max-h-[94px] w-full flex-col items-start gap-[6px] rounded-xl border border-gray-150 bg-gray-200 p-4 shadow-sm" />
        </div>
        <div className="flex w-full flex-row gap-4 md:max-w-[190px] md:flex-col">
          <div className="flex h-[94px] max-h-[94px] w-full flex-col items-start gap-[6px] rounded-xl border border-gray-150 bg-gray-200 p-4 shadow-sm" />

          <div className="flex h-[94px] max-h-[94px] w-full flex-col items-start gap-[6px] rounded-xl border border-gray-150 bg-gray-200 p-4 shadow-sm" />
        </div>
      </div>
    </div>
  )
}
