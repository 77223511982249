import { ArrowDownUp } from 'lucide-react'
import { ChipGroupContent } from '../../components/chip-group-content/chip-group-content'
import { Column, Data } from '../../components/table-dashboard/table-dashboard'
import { BannerTable } from './components/banner-table'

const mapTypeForRendering = (type: string) => {
  const typeMapping: Record<string, string> = {
    COURSE: 'Curso',
    BOOK: 'Livro',
  }
  return typeMapping[type] || type
}

export const columns: Column[] = [
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-6">
        <ArrowDownUp size={18} />
        <span className="text-title-sm text-gray-400">Conteúdos</span>
      </div>
    ),
    accessor: 'name',
    width: '40%',
    render: (row: Data) => <BannerTable row={row} />,
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="text-title-sm text-gray-400">Categoria</span>
      </div>
    ),
    accessor: 'categories.name',
    width: '30%',
    render: (row: Data) => (
      <span>
        <ChipGroupContent
          chipItems={row.categories.map((category) => ({
            id: category.categoryId,
            name: category.name,
          }))}
          noItemLabel="Sem categoria"
        />
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="text-title-sm text-gray-400">Tipo</span>
      </div>
    ),
    accessor: 'type',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">
        {mapTypeForRendering(row.type)}
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="text-title-sm text-gray-400">Iniciados</span>
      </div>
    ),
    accessor: 'progress',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">{row.totalInProgress}</span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="text-title-sm text-gray-400">Concluído</span>
      </div>
    ),
    accessor: 'completed',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">{row.totalCompleted}</span>
    ),
  },
]
