import { Outlet } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
// import { Navbar } from './components/navbar'

export function AuthenticatedLayout() {
  return (
    <>
      <TanStackRouterDevtools />
      {/* <Navbar /> */}
      <Outlet />
    </>
  )
}
