import { EmblaCarouselType } from 'embla-carousel'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { ComponentPropsWithRef, useCallback, useEffect, useState } from 'react'

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean
  nextBtnDisabled: boolean
  onPrevButtonClick: () => void
  onNextButtonClick: () => void
}

export function usePrevNextButtons(
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  }
}

type PropType = ComponentPropsWithRef<'button'>

export function PrevButton(props: PropType) {
  const { children, ...restProps } = props

  return (
    <button
      className="nav-button bg-white-100 group flex min-h-9 min-w-9 cursor-pointer items-center justify-center rounded-xl p-1"
      type="button"
      {...restProps}
    >
      <ArrowLeft
        className="text-[#57535d] group-disabled:text-gray-100"
        style={{ width: '20px', height: '20px' }}
      />
      {children}
    </button>
  )
}

export function NextButton(props: PropType) {
  const { children, ...restProps } = props

  return (
    <button
      className="nav-button bg-white-100 group flex min-h-9 min-w-9 cursor-pointer items-center justify-center rounded-xl p-1"
      type="button"
      {...restProps}
    >
      <ArrowRight
        className="text-[#57535d] group-disabled:text-gray-100"
        style={{ width: '20px', height: '20px' }}
      />
      {children}
    </button>
  )
}
