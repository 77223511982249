import { useMeQueryOptions } from '@/shared/queries/use-me'
import { createFileRoute, redirect } from '@tanstack/react-router'
import cookies from 'js-cookie'

export const Route = createFileRoute('/_authenticated')({
  onCatch: (error) => {
    console.log({ error })
  },
  beforeLoad: async ({ context: { queryClient } }) => {
    const fullRedirectPath = `${location.pathname}${location.search}`

    const token = cookies.get('auth-cookie') ?? ''

    if (!token) {
      return redirect({
        to: '/sso',
        search: {
          redirect: fullRedirectPath,
          errorCode: 'token-inexistente',
        },
      })
    }

    await queryClient.prefetchQuery(
      useMeQueryOptions({
        enabled: true,
      })
    )
  },
})
