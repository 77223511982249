import { useState } from 'react'
import { Route as CourseRoute } from '@/routes/_authenticated/dashboard/courses/$courseId'
import { ContentWrapper } from '../../components/content-wrapper/content-wrapper'
import { columns } from './columns'
import { useQuery } from '@tanstack/react-query'
import { useGetDashboardContents } from '@/shared/queries/dashboard/contents/use-dashboard-contents'
import useDebouncedState from '@/shared/hooks/use-debounced-state'
import clsx from 'clsx'
import { SearchTextField } from '@/shared/components/ui/search-text-field'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/shared/components/ui/select'
import { useGetDashboardContentCategories } from '@/shared/queries/dashboard/contents/use-dashboard-content-categories'
import {
  Data,
  TableFormationDashboard,
} from '../../components/table-formation-dashboard/table-dashboard'
import { useNavigate } from '@tanstack/react-router'

export function DashboardFormations() {
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    ''
  )
  const [currentColumnFilter, setCurrentColumnFilter] = useState<{
    sortBy: string
    sort: 'asc' | 'desc'
  }>({
    sortBy: 'name',
    sort: 'asc',
  })

  const {
    state: searchValue,
    setState: setSearchValue,
    debouncedState: debouncedSearch,
  } = useDebouncedState({
    initialState: '',
  })

  const {
    data,
    isLoading: isLoadingDashboardContents,
    isFetching: isFetchingDashboardContents,
    isError,
  } = useQuery(
    useGetDashboardContents({
      page: currentPage,
      name: debouncedSearch,
      categoryId: selectedCategory,
      type: 'COURSE',
      sort: currentColumnFilter.sort,
      sortBy: currentColumnFilter.sortBy,
    })
  )

  const { data: categories } = useQuery(useGetDashboardContentCategories())
  const totalPages = data ? data.totalPages : 1

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    setSelectedCategory('')
    setCurrentPage(1)
  }

  const handleCategoryChange = (value: string) => {
    if (value === 'all') {
      setSelectedCategory(undefined)
    } else {
      setSelectedCategory(value)
    }
    setSearchValue('')
    setCurrentPage(1)
  }

  const navigate = useNavigate({
    from: CourseRoute.fullPath,
  })
  const navigateToCourseDetails = (row: Data) => {
    navigate({
      to: `${row.id}`,
    })
  }

  const currentSelectedCategory = categories?.data.find(
    (category) => category.id === selectedCategory
  )

  const toggleSortOrder = (currentSort: 'asc' | 'desc'): 'asc' | 'desc' => {
    return currentSort === 'asc' ? 'desc' : 'asc'
  }

  return (
    <ContentWrapper>
      <div
        className={clsx('flex w-full flex-col', {
          'border-0': isLoadingDashboardContents,
          'rounded-xl border border-gray-100': !isLoadingDashboardContents,
        })}
      >
        {!isLoadingDashboardContents && !isError ? (
          <div className="flex w-full flex-col items-center p-3 lg:flex-row lg:justify-between">
            <div className="flex h-full max-h-max w-full flex-col items-center justify-end gap-2 lg:h-8 lg:max-h-8 lg:flex-row">
              <div className="flex h-8 max-h-8 w-full lg:max-w-[250px]">
                <SearchTextField
                  onChange={handleSearchChange}
                  value={searchValue}
                  placeholder="Pesquisar"
                />
              </div>
              <div className="flex w-full flex-col gap-2 sm:flex-row lg:justify-end">
                <div className="flex w-full lg:w-auto">
                  <Select
                    value={selectedCategory}
                    onValueChange={handleCategoryChange}
                  >
                    <SelectTrigger className="max-h-[32px] min-h-[32px] w-full rounded-lg border border-gray-150 px-3 py-0 lg:min-w-[240px] lg:max-w-[240px]">
                      <div className="flex w-full items-center justify-between gap-2">
                        <div className="flex items-center justify-start">
                          <span className="line-clamp-1 text-label-lg text-gray-300">
                            {currentSelectedCategory?.name ?? 'Categorias'}
                          </span>
                        </div>
                      </div>
                    </SelectTrigger>
                    <SelectContent className="z-[999px] flex w-full lg:min-w-[240px] lg:max-w-[240px]">
                      <SelectItem
                        className={clsx('flex items-center px-3 py-1', {
                          'bg-blue-500 text-white hover:bg-blue-500':
                            selectedCategory === 'all',
                          'bg-white': selectedCategory !== 'all',
                        })}
                        key="default"
                        value="all"
                      >
                        Todas as Categorias
                      </SelectItem>
                      {categories?.data.map((category) => (
                        <SelectItem
                          className={clsx('flex items-center px-3 py-1', {
                            'bg-blue-500 text-white hover:bg-blue-500':
                              category.id === selectedCategory,
                            'bg-white': category.id !== selectedCategory,
                          })}
                          key={category.id}
                          value={category.id}
                        >
                          {category.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={clsx({
            'cursor-wait [&>*]:opacity-50': isFetchingDashboardContents,
          })}
        >
          <TableFormationDashboard
            columns={columns}
            data={data?.data ?? []}
            isError={isError}
            isLoading={isLoadingDashboardContents}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onFilterChange={({ sortBy }) => {
              const isSameFilter = sortBy === currentColumnFilter.sortBy
              const newSort = isSameFilter
                ? toggleSortOrder(currentColumnFilter.sort)
                : 'asc'

              setCurrentColumnFilter({
                sortBy,
                sort: newSort,
              })
            }}
            onRowClick={navigateToCourseDetails}
          />
        </div>
      </div>
    </ContentWrapper>
  )
}
