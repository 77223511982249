import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import { keepPreviousData } from '@tanstack/react-query'

const categorySchema = z.object({
  categoryId: z.string(),
  name: z.string(),
})

const dataSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.enum(['COURSE', 'BOOK']),
  banner: z.string(),
  author: z.string().nullable(),
  totalInProgress: z.number(),
  totalCompleted: z.number(),
  totalSubscribed: z.number(),
  categories: z.array(categorySchema),
})

const linkSchema = z.object({
  rel: z.string(),
  href: z.string(),
})

const dashboardContentsResponseSchema = z.object({
  currentPage: z.number(),
  totalPages: z.number(),
  totalItems: z.number(),
  data: z.array(dataSchema),
  links: z.array(linkSchema),
})

export type CategoryDashboardContentsProps = z.infer<typeof categorySchema>

export type GetDashboardContentsResponse = z.infer<
  typeof dashboardContentsResponseSchema
>

export async function getDashboardContents({
  page,
  size,
  type,
  categoryId,
  sort,
  name,
  sortBy,
}: {
  page: number
  size: number
  type?: string
  categoryId?: string
  sort?: string
  name?: string
  sortBy?: string
}) {
  const url = apiUrls.dashboard.contents.getDashboardContents
  const token = Cookies.get('auth-cookie') ?? ''

  const params: Record<string, any> = { page, size }
  if (type) params.type = type
  if (categoryId) params.categoryId = categoryId
  if (sort) {
    params.sort = sort
    params.sortBy = sortBy
  }
  if (name) params.name = name

  try {
    const { data } = await axios.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data) // Adicionando log para inspecionar a resposta
    const parsedData = dashboardContentsResponseSchema.parse(data.data) // Acessando a propriedade data dentro do objeto data
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetDashboardContentsQueryKey =
  'get-dashboard-contents-query-key'

export const useGetDashboardContents = ({
  page = 1,
  size = 10,
  name,
  categoryId,
  type,
  sort,
  sortBy,
}: {
  page?: number
  size?: number
  name?: string
  categoryId?: string | undefined
  type?: string | undefined
  sort?: string
  sortBy?: string
}) => ({
  queryKey: [
    useGetDashboardContentsQueryKey,
    page,
    size,
    name,
    categoryId,
    type,
    sort,
    sortBy,
  ],
  queryFn: () =>
    getDashboardContents({ page, size, name, categoryId, type, sort, sortBy }),
  placeholderData: keepPreviousData,
})
