import { useState } from 'react'
import useDebounce from './use-debounce'
import useUpdateEffect from './helpers/use-update-effect'

interface Props<T> {
  initialState: T
  debounceTime?: number
  callback?: (debouncedState: T) => void
}

const useDebouncedState = <T>({
  debounceTime = 500,
  initialState,
  callback,
}: Props<T>) => {
  const [state, setState] = useState(initialState)
  const debouncedState = useDebounce(state, debounceTime)
  useUpdateEffect(() => {
    if (callback && callback instanceof Function) callback(debouncedState)
  }, [debouncedState])
  return { debouncedState, state, setState }
}

export default useDebouncedState
