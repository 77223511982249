import { ChartPie } from 'lucide-react'
import { motion } from 'framer-motion'

export function SectionEngajamentSkeleton() {
  return (
    <div
      role="status"
      className="flex h-[316px] max-h-[316px] w-[492px] max-w-[492px] animate-pulse items-center rounded-xl border border-gray-100 bg-gray-200 shadow-sm"
    >
      <div className="flex h-full w-full items-center justify-center">
        <motion.div
          className="rounded-xl bg-gray-200 p-4"
          animate={{
            y: [0, -5, 0], // Animação de pulo
          }}
          transition={{
            duration: 0.5,
            repeat: Infinity,
            repeatType: 'loop',
          }}
        >
          <ChartPie className="text-gray-300" />
        </motion.div>
      </div>
    </div>
  )
}
