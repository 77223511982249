import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@/shared/components/ui/avatar'
import { UserRound } from 'lucide-react'
import {
  Column,
  Data,
} from '../../components/table-people-dashboard/table-people-dashboard'
import { ChipGroupContent } from '../../components/chip-group-content/chip-group-content'
import { format } from 'date-fns'

export const columns: Column[] = [
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Nome</span>
      </div>
    ),
    accessor: 'name',
    width: '343px',
    render: (row: Data) => (
      <div className="flex cursor-pointer items-center gap-3">
        <Avatar>
          <AvatarImage src={row.picture || ''} />
          <AvatarFallback>
            <UserRound color="gray" />
          </AvatarFallback>
        </Avatar>
        <span className="line-clamp-1 px-3 text-body-md text-gray-500">
          {row.name}
        </span>
      </div>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Times</span>
      </div>
    ),
    accessor: 'teams',
    render: (row: Data) => (
      <span>
        <ChipGroupContent chipItems={row.teams || []} noItemLabel="Sem times" />
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Livros em Andamento</span>
      </div>
    ),
    accessor: 'books.progress',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">
        {row.books.in_progress}
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Livros em Concluídos</span>
      </div>
    ),
    accessor: 'books.completed',
    render: (row: Data) => (
      <span className="text-center text-body-md text-gray-500">
        {row.books.completed}
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Formações em Andamento</span>
      </div>
    ),
    accessor: 'courses.progress',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">
        {row.courses.in_progress}
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Formações em Concluídos</span>
      </div>
    ),
    accessor: 'courses.completed',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">
        {row.courses.completed}
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Início</span>
      </div>
    ),
    accessor: 'createdAt',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">
        {row.createdAt ? format(new Date(row.createdAt), 'dd/MM/yyyy') : '-'}
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Último Acesso</span>
      </div>
    ),
    accessor: 'lastLogin',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">
        {row.lastLogin ? format(new Date(row.lastLogin), 'dd/MM/yyyy') : '-'}
      </span>
    ),
  },
]
