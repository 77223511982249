import { LucideIcon } from 'lucide-react'

type Submenu = {
  href: string
  label: string
  active?: boolean
}

type Menu = {
  href: string
  label: string
  active: boolean
  icon: LucideIcon | null
  submenus?: Submenu[]
}

type Group = {
  groupLabel: string
  menus: Menu[]
}

export function getMenuList(pathname: string): Group[] {
  return [
    {
      groupLabel: '',
      menus: [
        {
          href: '/dashboard/general',
          label: 'Geral',
          active: pathname.includes('/general'),
          icon: null,
        },
        {
          href: '/dashboard/programs',
          label: 'Trilhas',
          active: pathname.includes('/programs'),
          icon: null,
        },
        {
          href: '/dashboard/contents',
          label: 'Conteúdos',
          active: pathname.includes('/contents'),
          icon: null,
        },
        {
          href: '/dashboard/courses',
          label: 'Formações',
          active: pathname.includes('/courses'),
          icon: null,
        },
        {
          href: '/dashboard/people',
          label: 'Pessoas',
          active: pathname.includes('/people'),
          icon: null,
        },
        {
          href: '/dashboard/teams',
          label: 'Times',
          active: pathname.includes('/teams'),
          icon: null,
        },
        {
          href: '/dashboard/books',
          label: 'Livros',
          active: pathname.includes('/books'),
          icon: null,
        },
      ],
    },
  ]
}
