import React from 'react'

type ContentWrapperProps = {
  children: React.ReactNode
}

export function ContentWrapper({ children }: ContentWrapperProps) {
  return (
    <div className="mt-16 flex h-[calc(100vh_-_64px)] w-full select-none flex-col items-center gap-y-6 overflow-auto bg-white p-4 sm:px-9 sm:py-8">
      {children}
    </div>
  )
}
