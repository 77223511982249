import { Chip } from '@mui/material'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip'

interface ChipGroupProps {
  chipItems: { id: string; name: string }[]
  limit?: number
  noItemLabel: string
}

export function ChipGroupContent({ chipItems, limit = 1 }: ChipGroupProps) {
  if (!chipItems || chipItems?.length === 0) {
    return (
      <div className="flex items-center">
        <div className="pr-1">
          <Chip
            className=""
            sx={{
              borderRadius: '5px',
              color: '#5A6173',
              display: 'flex',
              border: '1px solid #E6EAF0',
              background: 'white',
              fontSize: '11px',
              letterSpacing: '0em',
              lineHeight: '155%',
              fontWeight: '600',
            }}
            label="Sem Categoria"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="flex items-center">
      {chipItems?.slice(0, limit).map(({ id, name }) => (
        <div className="pr-1" key={id}>
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger>
                <Chip
                  sx={{
                    borderRadius: '5px',
                    color: '#5A6173',
                    display: 'flex',
                    border: '1px solid #E6EAF0',
                    background: 'white',
                    fontSize: '11px',
                    letterSpacing: '0em',
                    lineHeight: '155%',
                    fontWeight: '600',
                    padding: '0px',
                    '.MuiChip-label ,MuiChip-labelMedium': {
                      paddingLeft: '6px !important',
                      paddingRight: '6px !important',
                    },
                  }}
                  label={name}
                />
              </TooltipTrigger>

              <TooltipContent align="center">
                <div className="flex items-center justify-center rounded-[10px] bg-black/80 p-3">
                  <span className="text-label-sm text-white">{name}</span>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ))}

      {chipItems.length > limit && (
        <TooltipProvider>
          <Tooltip delayDuration={100}>
            <TooltipTrigger>
              <Chip
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '5px',
                  color: '#5A6173',
                  border: '1px solid #E6EAF0',
                  background: 'white',
                  fontSize: '11px',
                  letterSpacing: '0em',
                  lineHeight: '155%',
                  fontWeight: '600',
                  padding: '0px',
                  '.MuiChip-label ,MuiChip-labelMedium': {
                    paddingLeft: '6px !important',
                    paddingRight: '6px !important',
                  },
                }}
                label={`+${chipItems?.slice(limit, chipItems?.length)?.length}`}
              />
            </TooltipTrigger>

            <TooltipContent align="center">
              <div className="flex items-center justify-center rounded-[10px] bg-black/80 p-3">
                <span className="text-label-sm text-white">
                  {chipItems
                    ?.slice(limit)
                    ?.map(({ name }) => name)
                    ?.join(', ')}
                </span>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  )
}
