import { atom, useAtom } from 'jotai'
import { AlignLeft, Menu } from 'lucide-react'
import { motion } from 'framer-motion'
import { useQuery } from '@tanstack/react-query'
import { useMeQueryOptions } from '@/shared/queries/use-me'

export const sideBarMenuAtom = atom(true)

export function Header() {
  const [sideBarMenu, setSideBarMenu] = useAtom(sideBarMenuAtom)

  const { data: useMeData } = useQuery(
    useMeQueryOptions({
      enabled: true,
    })
  )

  const companyLogo = useMeData?.company?.logoUrl

  function goToLegacySklHome() {
    window.location.href = import.meta.env.VITE_LEGACY_APP_URL
  }

  function handleSideBarMenu() {
    if (sideBarMenu) {
      return setSideBarMenu(false)
    }

    return setSideBarMenu(true)
  }

  return (
    <div className="fixed top-0 z-[999] flex max-h-[64px] min-h-[64px] w-full items-center gap-4 border-b border-gray-100 bg-white px-7 py-3">
      <button onClick={handleSideBarMenu}>
        {sideBarMenu ? (
          <motion.div
            key="alignLeft"
            initial={{ opacity: 0, rotate: -90 }}
            animate={{ opacity: 1, rotate: 0 }}
            exit={{ opacity: 0, rotate: 90 }}
            transition={{ duration: 0.4, ease: 'easeInOut' }} // Aumentei a duração para 0.5 segundos
          >
            <AlignLeft />
          </motion.div>
        ) : (
          <motion.div
            key="menu"
            initial={{ opacity: 0, rotate: -90 }}
            animate={{ opacity: 1, rotate: 0 }}
            exit={{ opacity: 0, rotate: 90 }}
            transition={{ duration: 0.4, ease: 'easeInOut' }} // Aumentei a duração para 0.5 segundos
          >
            <Menu />
          </motion.div>
        )}
      </button>
      <img
        onClick={goToLegacySklHome}
        src={companyLogo}
        style={{ height: '16px', width: 'auto' }}
        className="cursor-pointer"
      />
    </div>
  )
}
