import { ContentWrapper } from '@/features/dashboard/components/content-wrapper/content-wrapper'
import { TableFormationDetailUsersDashboard } from '@/features/dashboard/components/table-formation-detail-users-dashboard/table-formation-detail-users-dashboard'
import { Button } from '@/shared/components/ui/button'
import { SearchTextField } from '@/shared/components/ui/search-text-field'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/shared/components/ui/select'
import useDebouncedState from '@/shared/hooks/use-debounced-state'
import { useGetContentUsers } from '@/shared/queries/dashboard/courses/use-course-users'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from '@tanstack/react-router'
import clsx from 'clsx'
import { ArrowLeft } from 'lucide-react'
import { useState } from 'react'
import { ProgramsCard } from '../components/programs-card'
import { columns } from './columns'

export function CourseDetailPage() {
  const navigate = useNavigate()

  const { courseId } = useParams({
    from: '/_authenticated/dashboard/courses/$courseId',
  })

  const [currentColumnFilter, setCurrentColumnFilter] = useState<{
    sortBy: string
    sort: 'asc' | 'desc'
  }>({
    sortBy: 'name',
    sort: 'asc',
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedProgress, setSelectedProgress] = useState<string | undefined>(
    ''
  )

  const optionsProgressFilter = [
    { id: 'inProgress', name: 'Em Progresso' },
    { id: 'completed', name: 'Finalizado' },
    { id: 'notStarted', name: 'Não Iniciado' },
  ]

  const {
    state: searchValue,
    setState: setSearchValue,
    debouncedState: debouncedSearch,
  } = useDebouncedState({
    initialState: '',
  })

  const {
    data: formationUsersData,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    useGetContentUsers({
      id: courseId,
      page: currentPage,
      name: debouncedSearch,
      progress: selectedProgress,
      sort: currentColumnFilter.sort,
      sortBy: currentColumnFilter.sortBy,
    })
  )

  const toggleSortOrder = (currentSort: 'asc' | 'desc'): 'asc' | 'desc' => {
    return currentSort === 'asc' ? 'desc' : 'asc'
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    setSelectedProgress('')
    setCurrentPage(1)
  }

  const handleProgressChange = (value: string) => {
    if (value === 'all') {
      setSelectedProgress(undefined)
    } else {
      setSelectedProgress(value)
    }
    setSearchValue('')
    setCurrentPage(1) // Redefinir página para 1 quando a pesquisa mudar
  }

  const currentSelectedProgress = optionsProgressFilter?.find(
    (type) => type.id === selectedProgress
  )

  const totalPages = formationUsersData ? formationUsersData.totalPages : 1

  function handleGoBackToCourses() {
    navigate({
      to: '/dashboard/courses',
      replace: true,
    })
  }

  return (
    <ContentWrapper>
      <div className="flex w-full items-center gap-3">
        <Button
          variant="outlined"
          className="flex max-h-8 items-center justify-center"
          onClick={handleGoBackToCourses}
        >
          <ArrowLeft size={18} />
          <span className="text-label-lg text-gray-500">Voltar</span>
        </Button>

        <span className="text-title-md text-gray-500">
          Detalhamento de Formações
        </span>
      </div>

      <ProgramsCard />
      <div
        className={clsx('flex w-full flex-col', {
          'border-0': isLoading,
          'rounded-xl border border-gray-100': !isLoading,
        })}
      >
        {!isLoading && !isError ? (
          <div className="flex w-full flex-col items-center p-3 lg:flex-row lg:justify-between">
            <div className="flex h-full max-h-max w-full flex-col items-center justify-end gap-2 lg:h-8 lg:max-h-8 lg:flex-row">
              <div className="flex h-8 max-h-8 w-full lg:max-w-[250px]">
                <SearchTextField
                  onChange={handleSearchChange}
                  value={searchValue}
                  placeholder="Pesquisar"
                />
              </div>
              <div className="flex w-full flex-col gap-2 sm:flex-row lg:justify-end">
                <div className="flex w-full lg:w-auto">
                  <Select
                    value={selectedProgress}
                    onValueChange={handleProgressChange}
                  >
                    <SelectTrigger className="max-h-[32px] min-h-[32px] rounded-lg border border-gray-150 px-3 py-0 lg:min-w-[164px] lg:max-w-[164px]">
                      <div className="flex w-full items-center justify-between gap-2">
                        <div className="flex items-center justify-start">
                          <span className="text-label-lg text-gray-300">
                            {currentSelectedProgress?.name ?? 'Progresso'}
                          </span>
                        </div>
                      </div>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem
                        className={clsx('flex items-center px-3 py-1', {
                          'bg-blue-500 text-white hover:bg-blue-500':
                            selectedProgress === 'all',
                          'bg-white': selectedProgress !== 'all',
                        })}
                        key="default"
                        value="all"
                        onClick={() => setSelectedProgress('all')}
                      >
                        Todos
                      </SelectItem>
                      {optionsProgressFilter.map((option) => (
                        <SelectItem
                          className={clsx('flex items-center px-3 py-1', {
                            'bg-blue-500 text-white hover:bg-blue-500':
                              option.id === selectedProgress,
                            'bg-white': option.id !== selectedProgress,
                          })}
                          key={option.id}
                          value={option.id}
                          onClick={() => setSelectedProgress(option.id)}
                        >
                          {option.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={clsx({
            'cursor-wait [&>*]:opacity-50': isFetching,
          })}
        >
          <TableFormationDetailUsersDashboard
            columns={columns}
            data={formationUsersData?.data ?? []}
            isError={isError}
            isLoading={isLoading}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onFilterChange={({ sortBy }) => {
              const isSameFilter = sortBy === currentColumnFilter.sortBy
              const newSort = isSameFilter
                ? toggleSortOrder(currentColumnFilter.sort)
                : 'asc'

              setCurrentColumnFilter({
                sortBy,
                sort: newSort,
              })
            }}
          />
        </div>
      </div>
    </ContentWrapper>
  )
}
