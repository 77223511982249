/* --------------------------------------- Unauthenticated ----------------------------------------*/

/* --------------------------------------- Authenticated ----------------------------------------*/

//Assessment 📃
export const ASSESSMENT_ROUTE = '/diagnosis'
export const ASSESSMENT_ROUTE_QUESTIONS = '/diagnosis/questions'
export const ASSESSMENT_QUESTIONS_BUSINESS_DATA_ROUTE = `${ASSESSMENT_ROUTE}/questions/business-data`
export const ASSESSMENT_QUESTIONS_BUSINESS_MANAGEMENT_ROUTE = `${ASSESSMENT_ROUTE}/questions/business-management`
export const ASSESSMENT_QUESTIONS_MARKETING_AND_GROWTH_ROUTE = `${ASSESSMENT_ROUTE}/questions/marketing-and-growth`
export const ASSESSMENT_QUESTIONS_SALES_ROUTE = `${ASSESSMENT_ROUTE}/questions/sales`
export const ASSESSMENT_QUESTIONS_CULTURE_AND_LEADERSHIP_ROUTE = `${ASSESSMENT_ROUTE}/questions/culture-and-leadership`
export const ASSESSMENT_QUESTIONS_CUSTOMER_EXPERIENCE_ROUTE = `${ASSESSMENT_ROUTE}/questions/customer-experience`
export const ASSESSMENT_RESULT_ROUTE = `${ASSESSMENT_ROUTE}/result`
