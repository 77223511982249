const LinearGradientFill = () => (
  <svg width="0" height="0">
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" style={{ stopColor: '#4C61B5', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#2D4A8C', stopOpacity: 1 }} />
      </linearGradient>
    </defs>
  </svg>
)

export default LinearGradientFill
