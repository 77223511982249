import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../api-urls'

const topicSchema = z.object({
  topicName: z.string(),
  topicScore: z.number(),
})

const outputSchema = z.object({
  id: z.string(),
  categoryId: z.string(),
  categoryName: z.string().optional().nullable(),
  categoryScore: z.string(),
  topics: z.array(topicSchema),
  createdAt: z.string().optional().nullable(),
  createdBy: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  updatedBy: z.string().optional().nullable(),
  deletedAt: z.string().optional().nullable(),
  deletedBy: z.string().optional().nullable(),
})

const schema = z.array(
  z.object({
    id: z.string(),
    outputs: z.array(outputSchema),
    aiOutput: z.string().optional().nullable(),
    companyId: z.string(),
    organizationId: z.string(),
    createdAt: z.string(),
    createdBy: z.string(),
    deletedAt: z.string().optional().nullable(),
    deletedBy: z.string().optional().nullable(),
  })
)

type GetDiagnosis = {
  organizationId: string
}

export async function getDiagnosis({ organizationId }: GetDiagnosis) {
  const url = apiUrls.diagnosis.getDiagnosis
  const token = Cookies.get('auth-cookie') ?? ''

  try {
    const { data } = await axios.get(url, {
      params: {
        organizationId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return schema.parse(data)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Erro específico do Axios
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      // Outros erros
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

type useMeType = {
  organizationId: string
}

export const useGetDiagnosisQueryKey = 'get-diagnosis-query-key'

export const useGetDiagnosis = ({ organizationId }: useMeType) => ({
  queryKey: [useGetDiagnosisQueryKey],
  queryFn: () => getDiagnosis({ organizationId }),
  enabled: !!organizationId,
})
