import clsx from 'clsx'
import { useAtomValue } from 'jotai'
import { ContentWrapper } from '../../components/content-wrapper/content-wrapper'
import { sideBarMenuAtom } from '../../components/header/header'
import { SectionAccess } from './components/section-access/section-access'
import { SectionAreasInterest } from './components/section-areas-interest/section-areas-interest'
import SectionEngajament from './components/section-engajament/section-engajament'
import { SectionHoursConsumed } from './components/section-hours-consumed/section-hours-consumed'

export function DashboardGeneral() {
  const sideBarMenu = useAtomValue(sideBarMenuAtom)

  return (
    <ContentWrapper>
      <div
        className={clsx('grid grid-cols-1 gap-6', {
          'lg:grid-cols-1 xl:grid-cols-2': sideBarMenu,
          'lg:grid-cols-2': !sideBarMenu,
        })}
      >
        <div className="flex h-full max-h-max min-h-[316px] w-full max-w-[500px] lg:max-h-[316px]">
          <SectionEngajament />
        </div>
        <div className="flex h-full max-h-max min-h-[316px] w-full max-w-[500px] lg:max-h-[316px]">
          <SectionAccess />
        </div>
        <div className="flex h-full max-h-[316px] min-h-[316px] w-full max-w-[500px] overflow-hidden">
          <SectionAreasInterest pageSize={5} />
        </div>
        <div className="flex h-full max-h-[316px] min-h-[316px] w-full max-w-[500px] overflow-hidden">
          <SectionHoursConsumed />
        </div>
        <div className="hidden w-full max-w-[500px] overflow-hidden lg:flex"></div>
      </div>
    </ContentWrapper>
  )
}
