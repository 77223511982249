import { Dialog, DialogContent } from '@mui/material'
import clsx from 'clsx'
import { Button } from './button'
import { CircleX } from 'lucide-react'

interface ConfirmationDialogProps {
  title: string
  message: string | JSX.Element
  open: boolean
  okLabel?: string
  loadingText?: string
  cancelLabel?: string
  isLoading: boolean
  inverted?: boolean
  onClick: () => void
  handleClose: () => void
}

const ConfirmationDialog = ({
  title,
  message,
  open,
  okLabel = 'Sim',
  cancelLabel = 'Cancelar',
  inverted,
  isLoading,
  loadingText,
  onClick,
  handleClose,
}: ConfirmationDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '12px',
        },
      }}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent sx={{ p: 0 }}>
        <div className="flex min-h-12 flex-col items-start gap-2 bg-brand-white-100">
          <div className="flex w-full items-center justify-between border-b border-brand-gray-200 p-2 pl-6">
            <span className="text-title-md text-body-gray-500">{title}</span>
            <CircleX
              style={{
                width: 20,
                color: '#666666',
                margin: '8px',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            />
          </div>

          <span className="px-6 text-body-sm text-body-gray-400">
            {message}
          </span>

          <div
            className={clsx('flex w-full flex-wrap items-end gap-2 p-3', {
              'flex-row-reverse': inverted,
              'justify-end': !inverted,
            })}
            style={{ flexDirection: inverted ? 'row-reverse' : 'row' }}
          >
            <Button
              variant={inverted ? 'filled' : 'outlined'}
              onClick={handleClose}
              className="flex h-10 max-h-10 w-full items-center justify-center lg:w-min"
            >
              <span
                className={`text-label-lg ${
                  inverted ? 'text-white' : 'text-body-gray-400'
                }`}
              >
                {cancelLabel}
              </span>
            </Button>

            <Button
              variant={inverted ? 'outlined' : 'filled'}
              onClick={onClick}
              className="flex h-10 max-h-10 w-full items-center justify-center lg:w-min"
              isLoading={isLoading}
              loadingText={loadingText}
            >
              <span
                className={`text-label-lg ${
                  inverted ? 'text-body-gray-400' : 'text-white'
                }`}
              >
                {okLabel}
              </span>
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmationDialog
