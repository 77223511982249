import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import { keepPreviousData } from '@tanstack/react-query'

const mentorSchema = z.object({
  id: z.string(),
  name: z.string(),
})

const courseDetailSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
  banner: z.string(),
  author: z.string().nullable(),
  totalInProgress: z.number(),
  totalCompleted: z.number(),
  totalModules: z.number(),
  totalLessons: z.number(),
  totalChapters: z.number(),
  duration: z.string(),
  mentors: z.array(mentorSchema),
})

export type CourseDetailProps = z.infer<typeof courseDetailSchema>

export async function getCourseDetail({ id }: { id: string }) {
  const url = apiUrls.dashboard.contents.getDashboardContents
  const token = Cookies.get('auth-cookie') ?? ''

  try {
    const { data } = await axios.get(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data) // Adicionando log para inspecionar a resposta
    const parsedData = courseDetailSchema.parse(data) // Acessando a propriedade data dentro do objeto data
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetFormationUsersQueryKey = 'get-course-detail-query-key'

export const useCourseDetail = ({ id }: { id: string }) => ({
  queryKey: [useGetFormationUsersQueryKey, id],
  queryFn: () => getCourseDetail({ id }),
  placeholderData: keepPreviousData,
})
