import { UserListDialog } from '@/features/dashboard/components/user-list-dialog/user-list-dialog'
import {
  accessDialogAtom,
  selectedDateAtom,
} from '@/shared/components/chart-bar'
import { useGetUserVisitsUsers } from '@/shared/queries/dashboard/access/use-access-list'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useState } from 'react'

export function UserAccessListDialog() {
  const [selectedDate] = useAtom(selectedDateAtom)
  const [currentPage, setCurrentPage] = useState(1)
  const [isOpenAccessDialog, setIsOpenAccessDialog] = useAtom(accessDialogAtom)

  const formattedDate = selectedDate
    ? selectedDate.split('/').reverse().join('-')
    : ''

  const {
    data: usersData,
    isFetching: isFetchingDashboardAccessUserList,
    isLoading: isLoadingDashboardAccessUserList,
  } = useQuery(
    useGetUserVisitsUsers({
      date: formattedDate,
      size: 10,
      page: currentPage,
    })
  )

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <UserListDialog
      isOpen={isOpenAccessDialog && selectedDate ? true : false}
      onClose={() => setIsOpenAccessDialog(false)}
      title={`Acessos: ${selectedDate}`}
      data={usersData?.data}
      isLoading={isLoadingDashboardAccessUserList}
      isFetching={isFetchingDashboardAccessUserList}
      emptyState={{
        title: 'Nenhum usuário acessou neste dia',
        description: 'Selecione outra data para visualizar os acessos',
      }}
      onPageChange={handlePageChange}
    />
  )
}
