import clsx from 'clsx'
import {
  Check,
  Flag,
  GraduationCap,
  Mail,
  Star,
  TrendingUp,
  User,
} from 'lucide-react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'

import { ComponentError } from '@/shared/components/ui/component-error'
import { sideBarMenuAtom } from '@/features/dashboard/components/header/header'
import { useAtomValue } from 'jotai'
import { usePeopleDetail } from '@/shared/queries/dashboard/people/use-get-people-details'
import { format } from 'date-fns'
import { PeopleDetailsSkeleton } from './people-details-skeleton'

export function PeopleDetailsCard() {
  const sideBarMenu = useAtomValue(sideBarMenuAtom)

  const { peopleId } = useParams({
    from: '/_authenticated/dashboard/people/$peopleId',
  })

  const {
    data: peopleDetailData,
    isLoading: isLoadingCourseDetail,
    isError: isErrorPeopleDetail,
  } = useQuery(usePeopleDetail({ id: peopleId }))

  function SquareIcon({ children }: { children: React.ReactNode }) {
    return (
      <div className="flex h-9 w-9 items-center justify-center rounded-[6px] bg-brand-coral-150 text-coral-400">
        {children}
      </div>
    )
  }

  if (isErrorPeopleDetail)
    return (
      <div className="flex h-max max-h-[204px] min-h-[204px] w-full flex-col">
        <ComponentError />
      </div>
    )
  if (isLoadingCourseDetail) return <PeopleDetailsSkeleton />

  return (
    <div className="flex h-max w-full flex-col md:max-h-[204px] md:min-h-[204px] md:flex-row">
      <div
        className={clsx('flex h-max w-full flex-col gap-4 rounded-xl', {
          'sm:flex-col md:flex-col lg:flex-row': sideBarMenu,
          'xs:flex-col sm:flex-col md:flex-row': !sideBarMenu,
        })}
      >
        <div className="flex-1 rounded-lg border border-gray-150 bg-white p-4 shadow-sm">
          <div className="space-y-4">
            <div className="flex items-center gap-4">
              <div className="flex h-[102px] w-[102px] items-center justify-center rounded-full bg-gray-100">
                {peopleDetailData?.picture ? (
                  <div
                    className={clsx(
                      'flex max-h-[240px] w-full rounded-[8px] md:max-w-[306px]',
                      {
                        'object-cover': peopleDetailData?.picture !== null,
                      }
                    )}
                  >
                    <img
                      src={peopleDetailData?.picture}
                      alt={peopleDetailData?.name}
                      className={clsx('rounded-[8px]', {
                        'rounded-full object-cover':
                          peopleDetailData?.picture !== null,
                      })}
                    />
                  </div>
                ) : (
                  <User className="h-11 w-11 text-gray-400" />
                )}
              </div>
              <div className="flex-grow space-y-4">
                <div className="flex flex-col">
                  <p className="text-body-xs text-gray-250">Colaborador</p>
                  <h2 className="text-title-md text-gray-500">
                    {peopleDetailData?.name}
                  </h2>
                </div>

                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2 text-gray-600">
                    <SquareIcon>
                      <Mail className="h-6 w-6" />
                    </SquareIcon>

                    <span className="text-body-md">
                      {peopleDetailData?.email}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-600">
                    <SquareIcon>
                      <Flag className="h-6 w-6" />
                    </SquareIcon>
                    {peopleDetailData?.teams.length ? (
                      peopleDetailData.teams.map((item) => (
                        <span
                          key={item.id}
                          className="rounded-full bg-gray-100 px-2 py-1 text-label-sm"
                        >
                          {item.name}
                        </span>
                      ))
                    ) : (
                      <span className="rounded-full bg-gray-100 px-2 py-1 text-label-sm">
                        Sem time
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <hr className="border-gray-150" />

            <div className="flex items-center justify-end gap-4 pt-2">
              <div className="flex items-center gap-2">
                <div className="h-3 w-3 rounded-full bg-green-500" />
                <span className="text-body-xs text-gray-250">
                  Iniciou{' '}
                  {peopleDetailData?.createdAt
                    ? format(
                        new Date(peopleDetailData?.createdAt),
                        'dd/MM/yyyy'
                      )
                    : '-'}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <div className="h-3 w-3 rounded-full bg-blue-500" />
                <span className="text-body-xs text-gray-250">
                  Último acesso{' '}
                  {peopleDetailData?.lastLogin
                    ? format(
                        new Date(peopleDetailData?.lastLogin),
                        'dd/MM/yyyy'
                      )
                    : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div
            className={clsx(
              'flex h-full max-h-[107px] flex-col items-start gap-[6px] rounded-xl border border-gray-150 p-4 shadow-sm md:w-[150px] lg:w-[170px]',
              {
                'md:w-full': sideBarMenu,
              }
            )}
          >
            <span className="text-body-xs text-gray-250">Pontuação</span>
            <div className="flex items-center justify-center gap-2">
              <SquareIcon>
                <Star />
              </SquareIcon>
              <div className="flex items-center gap-1">
                <span className="text-title-md text-gray-500">
                  {peopleDetailData?.rank.points} pts
                </span>
              </div>
            </div>
          </div>

          <div
            className={clsx(
              'flex h-full max-h-[107px] flex-col items-start gap-[6px] rounded-xl border border-gray-150 p-4 shadow-sm md:w-[150px] lg:w-[170px]',
              {
                'md:w-full': sideBarMenu,
              }
            )}
          >
            <span className="text-body-xs text-gray-250">Horas Assistidas</span>
            <div className="flex items-center justify-center gap-2">
              <SquareIcon>
                <Check />
              </SquareIcon>
              <div className="flex items-center gap-1">
                <span className="text-title-md text-gray-500">
                  {peopleDetailData?.userTotalHour}h
                </span>
              </div>
            </div>
          </div>

          <div
            className={clsx(
              'flex h-full max-h-[107px] flex-col items-start gap-[6px] rounded-xl border border-gray-150 p-4 shadow-sm md:w-[150px] lg:w-[170px]',
              {
                'md:w-full': sideBarMenu,
              }
            )}
          >
            {' '}
            <span className="text-body-xs text-gray-250">Ranking</span>
            <div className="flex items-center justify-center gap-2">
              <SquareIcon>
                <TrendingUp />
              </SquareIcon>
              <div className="flex items-center gap-1">
                <span className="text-title-md text-gray-500">
                  {peopleDetailData?.rank.position}º
                </span>
              </div>
            </div>
          </div>

          <div
            className={clsx(
              'flex h-full max-h-[107px] flex-col items-start gap-[6px] rounded-xl border border-gray-150 p-4 shadow-sm',
              {
                'md:w-full': sideBarMenu,
                'md:w-[150px] lg:w-[170px]': !sideBarMenu,
              }
            )}
          >
            {' '}
            <span className="text-body-xs text-gray-250">Aulas Assistidas</span>
            <div className="flex items-center justify-center gap-2">
              <SquareIcon>
                <GraduationCap />
              </SquareIcon>
              <div className="flex items-center gap-1">
                <span className="text-title-md text-gray-500">
                  {peopleDetailData?.totalLessonsCompleted}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
