import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import Cookies from 'js-cookie'
import axios from 'axios'

const dashboardContentsConsumedResponseSchema = z.object({
  totalHours: z.number(),
  totalUsers: z.number(),
})

export type GetDashboardContentsConsumedResponse = z.infer<
  typeof dashboardContentsConsumedResponseSchema
>

export async function getDashboardContentsConsumed() {
  const url = apiUrls.dashboard.contents.getDashboardContentsConsumed
  const token = Cookies.get('auth-cookie') ?? ''

  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data) // Adicionando log para inspecionar a resposta
    const parsedData = dashboardContentsConsumedResponseSchema.parse(data) // Acessando a propriedade data dentro do objeto data
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetDashboardContentsConsumedQueryKey =
  'get-dashboard-contents-consumed-query-key'

export const useGetDashboardContentsConsumed = () => ({
  queryKey: [useGetDashboardContentsConsumedQueryKey],
  queryFn: () => getDashboardContentsConsumed(),
})
