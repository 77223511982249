import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

const searchParamsSchema = z.object({
  authCookie: z.string(),
  path: z.string(),
})

type SearchParams = z.infer<typeof searchParamsSchema>

export const Route = createFileRoute('/_not-authenticated/redirect/')({
  validateSearch: (search: Record<string, unknown>): SearchParams =>
    searchParamsSchema.parse(search),
})
