import { cn } from '@/shared/helpers/shadcn'
import { CircularProgress } from '@mui/material'
import { cva, VariantProps } from 'class-variance-authority'
import {
  ButtonHTMLAttributes,
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useRef,
} from 'react'
import { AriaButtonProps, useButton } from 'react-aria'

const buttonClassVariance = cva('rounded-lg text-label-lg transition-all', {
  variants: {
    variant: {
      filled:
        'outline-none bg-brand-blue-400 hover:bg-brand-blue-600 active:bg-brand-blue-500 text-white disabled:bg-black disabled:bg-opacity-10 disabled:hover:bg-black disabled:hover:bg-opacity-10 disabled:text-black disabled:text-opacity-30',
      outlined:
        'bg-transparent outline-none text-black text-opacity-60 border-solid border border-gray-150 hover:bg-brand-gray-100 active:bg-brand-gray-50 disabled:text-opacity-30 disabled:border-opacity-90 disabled:hover:border-opacity-90 disabled:hover:text-opacity-30 disabled:hover:bg-transparent',
      text: 'bg-transparent outline-none text-body-gray-500 text-opacity-70 hover:bg-brand-blue-150 active:bg-brand-gray-100 disabled:text-opacity-30 disabled:hover:text-opacity-30 disabled:hover:bg-transparent px-4',
      tonal:
        'bg-tonal-blue-200 text-brand-blue-600 hover:bg-tonal-blue-500 active:bg-tonal-blue-200 disabled:bg-transparent disabled:hover:bg-transparent disabled:text-opacity-50 disabled:hover:text-opacity-50 border-none outline-none focus:outline-none',
    },
    size: {
      small: 'px-3 h-8 max-h-8',
      medium: 'px-4 h-10 max-h-10',
      large: 'px-5 h-12 max-h-12',
    },
    fullWidth: {
      true: 'px-0 w-full',
      false: 'w-auto',
    },
    isLoading: {
      true: 'cursor-progress',
    },
  },
  defaultVariants: {
    variant: 'filled',
    size: 'medium',
    fullWidth: false,
  },
})

type NewComponentProps = {
  children: ReactNode
  className?: string
  isLoading?: boolean
  loadingText?: string
  startIcon?: ReactNode
  endIcon?: ReactNode
}

type ButtonClassVarianceProps = VariantProps<typeof buttonClassVariance>

type ButtonProps = NewComponentProps &
  AriaButtonProps &
  ButtonClassVarianceProps &
  ButtonHTMLAttributes<HTMLButtonElement>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const buttonRef = useRef<HTMLButtonElement>(null)
    useImperativeHandle(ref, () => buttonRef.current!)
    const { buttonProps } = useButton(props, buttonRef)

    const {
      children,
      className,
      startIcon,
      endIcon,
      isLoading,
      loadingText = 'Carregando...',
      variant,
      size,
      fullWidth,
      ...rest
    } = props

    return (
      <button
        ref={ref}
        className={cn(
          ` ${buttonClassVariance({
            variant,
            size,
            fullWidth,
            isLoading,
          })} ${className} `
        )}
        {...buttonProps}
        {...rest}
        onClick={props.disabled || props.isLoading ? () => {} : props.onClick}
      >
        <div className="flex h-full w-full items-center justify-center gap-x-[6px] whitespace-nowrap">
          {isLoading ? (
            <>
              <CircularProgress
                size={16}
                sx={{ color: variant === 'filled' ? '#fff' : '#2D4A8C' }}
              />
              {loadingText ?? children}
            </>
          ) : (
            <>
              {startIcon ? <div>{startIcon}</div> : null}
              {children}
              {endIcon ? <div>{endIcon}</div> : null}
            </>
          )}
        </div>
      </button>
    )
  }
)
