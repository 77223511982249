import { TriangleAlert } from 'lucide-react'

export function ComponentError({ size = 36 }: { size?: number }) {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 rounded-xl bg-blue-100 p-3 text-gray-400 shadow-md">
      <TriangleAlert size={size} />
      <span className="text-center text-body-sm text-gray-400">
        Dados indisponíveis temporariamente.
      </span>
    </div>
  )
}
