import { EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from './components/carousel-arrow-buttons/carousel-arrow-buttons'
import './styles/embla.css'

type PropType = {
  thumb?: string
  children: React.ReactNode
}

export function Carousel({ children }: PropType) {
  const optionsCarousel: EmblaOptionsType = {
    align: 'start',
    slidesToScroll: 'auto',
    dragFree: true,
  }

  const [emblaRef, emblaApi] = useEmblaCarousel(optionsCarousel)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi)

  return (
    <section className="embla cursor-pointer select-none">
      <div ref={emblaRef}>
        <div className="embla__container select-none gap-3">{children}</div>
      </div>

      <div className="embla__controls hidden w-full items-center sm:flex">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      </div>
    </section>
  )
}
