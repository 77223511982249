import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@/shared/components/ui/avatar'
import { UserRound } from 'lucide-react'
import {
  Column,
  Data,
} from '../../../components/table-formation-detail-users-dashboard/table-formation-detail-users-dashboard'
import { format } from 'date-fns'
import { ChipGroupContent } from '@/features/dashboard/components/chip-group-content/chip-group-content'
import ProgressBarTailwind, {
  ProgressIndicator,
  ProgressLabel,
} from '@/shared/components/ui/progress-bar'

export const columns: Column[] = [
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Nome</span>
      </div>
    ),
    accessor: 'name',
    width: '40%',
    render: (row: Data) => (
      <div className="flex cursor-pointer items-center gap-3">
        <Avatar>
          <AvatarImage src={row.picture || ''} />
          <AvatarFallback>
            <UserRound color="gray" />
          </AvatarFallback>
        </Avatar>
        <span className="line-clamp-1 px-3 text-body-md text-gray-500">
          {row.name}
        </span>
      </div>
    ),
  },
  {
    header: () => (
      <div className="flex items-center gap-2">
        <span className="title-sm text-gray-400">Times</span>
      </div>
    ),
    accessor: 'teams',
    width: '30%',
    render: (row: Data) => (
      <span>
        <ChipGroupContent chipItems={row.teams || []} noItemLabel="Sem times" />
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Início</span>
      </div>
    ),
    accessor: 'start',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">
        {row.createdAt ? format(new Date(row.createdAt), 'dd/MM/yyyy') : '-'}
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Progresso</span>
      </div>
    ),
    accessor: 'progress',
    width: '15%',
    render: (row: Data) => (
      <span className="flex items-center justify-center gap-2 text-center text-body-md text-gray-500">
        <ProgressBarTailwind>
          <ProgressIndicator progress={row.progress} height="8" />
        </ProgressBarTailwind>
        <ProgressLabel progressLabel={row.progress} />
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Último acesso</span>
      </div>
    ),
    accessor: 'lastSeen',
    render: (row: Data) => (
      <span className="text-body-md text-gray-500">
        {row.lastSeen ? format(new Date(row.lastSeen), 'dd/MM/yyyy') : '-'}
      </span>
    ),
  },
]
