import axios from 'axios'
import { z } from 'zod'

const url = 'http://localhost:3001/actions'

const resourceSchema = z
  .object({
    id: z.number(),
    name: z.string(),
  })
  .nullable()

const actionSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  isEditable: z.boolean(),
  resource: resourceSchema,
})

type getActionsType = {
  name?: string
}

async function getActions({ name }: getActionsType) {
  const { data } = await axios(url, {
    params: {
      name,
    },
  })
  return actionSchema.array().parse(data)
}

export const useActionsQueryOption = (params: getActionsType) => ({
  queryKey: ['actions', params.name],
  queryFn: () => getActions(params),
})
