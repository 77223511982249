import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../api-urls'

// Novo schema baseado na tipagem fornecida
const licencesSchema = z.object({
  count: z.number(),
  percentage: z.number(),
})

const usersWhoHaveFinishedProgramSchema = z.object({
  count: z.number(),
  percentage: z.number(),
})

const userWhoAreNotStudyingSchema = z.object({
  count: z.number(),
  percentage: z.number(),
})

const dashboardEngagementSchema = z.object({
  licences: licencesSchema,
  usersWhoHaveFinishedProgram: usersWhoHaveFinishedProgramSchema,
  userWhoAreNotStudying: userWhoAreNotStudyingSchema,
  consumedHours: z.number(),
})

export type GetDashboardEngagementResponse = z.infer<
  typeof dashboardEngagementSchema
>

export async function getDashboardEngagement() {
  const url = apiUrls.dashboard.getDashboardEngagement
  const token = Cookies.get('auth-cookie') ?? ''

  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    // Adicionar valor padrão se `usersWhoAreNotStudying` estiver ausente
    const parsedData = dashboardEngagementSchema.parse({
      ...data,
    })
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Erro específico do Axios
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      // Outros erros
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetDashboardEngagementQueryKey =
  'get-dashboard-engagement-query-key'

export const useGetDashboardEngagement = () => ({
  queryKey: [useGetDashboardEngagementQueryKey],
  queryFn: () => getDashboardEngagement(),
})
