import { Toaster } from '@/shared/components/ui/sonner'
import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { toast } from 'sonner'
import './index.css'

import { routeTree } from './routeTree.gen'
import { ErrorPage } from './shared/components/error-page.tsx'
import NotFoundPage from './shared/components/not-found-page.tsx'
import { ThemeProvider } from './shared/components/theme-provider.tsx'
import { auth } from './utils.auth.ts'

// Create a new router instance

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const storageKey = 'g4-accounts-front-end-ui-theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      Bugsnag.notify(new Error(error.message))
      toast.error(`Erro tanstack-query -> ${error.message}`)
    },
  }),
})

// const authStore = useAuthStore();

const router = createRouter({
  routeTree,
  context: { queryClient, authStore: auth },
  // defaultPendingComponent: () => <h1>Loading global...</h1>,
  defaultErrorComponent: ({ error, reset }) => {
    console.log({ error })
    return <ErrorPage reset={reset} error={error} />
  },
  defaultNotFoundComponent: NotFoundPage,
  defaultPreloadStaleTime: 0,
  defaultPendingMs: 0,
  // defaultPreload: "intent",
})

Bugsnag.start({
  apiKey: 'ae2b173cd79b37d79094cffc081f2aff',
  plugins: [new BugsnagPluginReact()],
})
BugsnagPerformance.start({ apiKey: 'ae2b173cd79b37d79094cffc081f2aff' })

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ThemeProvider defaultTheme="light" storageKey={storageKey}>
      <QueryClientProvider client={queryClient}>
        <Toaster
          richColors
          toastOptions={{
            classNames: {
              error: 'bg-red-400',
              success: 'text-green-400',
              warning: 'text-yellow-400',
              info: 'bg-blue-400',
            },
          }}
        />
        {ErrorBoundary ? (
          <ErrorBoundary>
            <RouterProvider
              router={router}
              context={{ queryClient, authStore: auth }}
            />
            <ReactQueryDevtools initialIsOpen={false} />
          </ErrorBoundary>
        ) : (
          <>
            <RouterProvider
              router={router}
              context={{ queryClient, authStore: auth }}
            />
            <ReactQueryDevtools initialIsOpen={false} />
          </>
        )}
      </QueryClientProvider>
    </ThemeProvider>
    {import.meta.env.VITE_BUILD_ENV === 'development' ? (
      <div className="fixed bottom-0 right-0 z-[999999] bg-blue-100 p-1 text-purple-600">
        <div className="block sm:hidden">xs</div>
        <div className="hidden sm:block md:hidden">sm</div>
        <div className="hidden md:block lg:hidden">md</div>
        <div className="hidden lg:block xl:hidden">lg</div>
        <div className="hidden xl:block 2xl:hidden">xl</div>
        <div className="hidden 2xl:block">2xl</div>
      </div>
    ) : null}
  </StrictMode>
)
