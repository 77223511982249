import { ButtonValue } from '@/features/diagnosis/components/answer-button/answer-button'
import {
  AnswerProps,
  answerQuestionsAtom,
  AnswerQuestionsStateProps,
  InputProps,
  navigationOnStepsDiagnosisAtom,
} from '@/routes/_authenticated/diagnosis/(questions)/questions'
import { GetAllQuestionsDiagnosisResponseProps } from '@/shared/queries/diagnosis/use-all-questions-diagnosis'
import { useCreateDiagnosis } from '@/shared/queries/diagnosis/use-create-diagnosis'
import { ASSESSMENT_RESULT_ROUTE } from '@/shared/routes'
import { useNavigate } from '@tanstack/react-router'
import { useAtom } from 'jotai'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'

interface UseBodyQuestionsProps {
  category: GetAllQuestionsDiagnosisResponseProps[0]
}

export function useBodyQuestions({ category }: UseBodyQuestionsProps) {
  const [selectedValues, setSelectedValues] = useState<{
    [key: string]: ButtonValue | null
  }>({})
  const [textAnswers, setTextAnswers] = useState<{ [key: string]: string }>({})
  const [shouldAnimateIcon, setShouldAnimateIcon] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  // Obtém o estado atual dos átomos
  const [currentStep, setCurrentStep] = useAtom(navigationOnStepsDiagnosisAtom)
  const [answerQuestionsState, setAnswerQuestionsState] =
    useAtom(answerQuestionsAtom)

  const navigate = useNavigate()

  const { mutate: createDiagnosis } = useCreateDiagnosis({
    onSuccess: () => {
      toast.success('Sucesso ao criar diagnóstico')
      navigate({
        to: ASSESSMENT_RESULT_ROUTE,
      })
    },
    onError: () => {
      toast.error('Erro ao criar diagnóstico')
    },
  })

  // Efeito para observar a visibilidade do ícone
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShouldAnimateIcon(true)
        }
      },
      { threshold: 0.1 }
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [])

  // Atualiza a resposta de uma pergunta
  const handleAnswerUpdate = (
    questionId: string,
    questionName: string,
    topicId: string,
    topicName: string,
    questionType: string,
    answer: ButtonValue | string | null
  ) => {
    setAnswerQuestionsState((prevState: AnswerQuestionsStateProps) => {
      // Filtra inputs válidos
      const cleanedInputs = prevState.inputs.filter(
        (input: InputProps) =>
          input.categoryId !== '' && input.categoryName !== ''
      )

      // Encontra o índice da categoria atual
      const categoryIndex = cleanedInputs.findIndex(
        (input: InputProps) => input.categoryId === category.id
      )

      let updatedInputs: InputProps[]
      if (categoryIndex >= 0) {
        // Encontra o índice da resposta existente
        const existingAnswerIndex = cleanedInputs[
          categoryIndex
        ].answers.findIndex((a: AnswerProps) => a.questionId === questionId)

        let updatedAnswers: AnswerProps[]
        if (existingAnswerIndex >= 0) {
          // Atualiza ou remove a resposta existente
          if (answer === null || answer === '') {
            updatedAnswers = cleanedInputs[categoryIndex].answers.filter(
              (a: AnswerProps) => a.questionId !== questionId
            )
          } else {
            updatedAnswers = [...cleanedInputs[categoryIndex].answers]
            updatedAnswers[existingAnswerIndex] = {
              questionId,
              questionName,
              questionType,
              topicId,
              topicName,
              answer: answer as string | number,
            }
          }
        } else {
          // Adiciona uma nova resposta
          updatedAnswers = [
            ...cleanedInputs[categoryIndex].answers,
            {
              questionId,
              questionName,
              questionType,
              topicId,
              topicName,
              answer: answer as string | number,
            },
          ]
        }

        // Atualiza os inputs com as respostas atualizadas
        updatedInputs = [...cleanedInputs]
        updatedInputs[categoryIndex] = {
          ...cleanedInputs[categoryIndex],
          answers: updatedAnswers,
        }
      } else {
        // Adiciona uma nova categoria com a resposta
        updatedInputs = [
          ...cleanedInputs,
          {
            categoryId: category.id,
            categoryName: category.categoryName,
            answers:
              answer === null || answer === ''
                ? []
                : [
                    {
                      questionId,
                      questionName,
                      questionType,
                      topicId,
                      topicName,
                      answer: answer as string | number,
                    },
                  ],
          },
        ]
      }

      // Retorna o estado atualizado
      return {
        ...prevState,
        inputs: updatedInputs,
        companyId: 'cdd9517b-ed58-48aa-b4ba-e748ee54e83a',
        organizationId: 'cdd9517b-ed58-48aa-b4ba-e748ee54e83a',
      }
    })
  }

  // Manipula o clique em um botão de resposta
  const handleButtonClick = (
    value: ButtonValue,
    questionId: string,
    questionName: string,
    topicId: string,
    topicName: string,
    questionType: string
  ) => {
    // Atualiza o estado dos valores selecionados
    setSelectedValues((prevValues) => {
      const newValue = prevValues[questionId] === value ? null : value
      return {
        ...prevValues,
        [questionId]: newValue,
      }
    })
    // Atualiza a resposta da pergunta
    handleAnswerUpdate(
      questionId,
      questionName,
      topicId,
      topicName,
      questionType,
      selectedValues[questionId] === value ? null : value
    )
  }

  // Manipula a mudança de texto em uma resposta textual
  const handleTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    questionId: string,
    questionName: string,
    topicId: string,
    topicName: string,
    questionType: string
  ) => {
    const value = event.target.value
    // Atualiza o estado das respostas textuais
    setTextAnswers((prevValues) => ({
      ...prevValues,
      [questionId]: value,
    }))
    // Atualiza a resposta da pergunta
    handleAnswerUpdate(
      questionId,
      questionName,
      topicId,
      topicName,
      questionType,
      value
    )
  }

  // Rola para a primeira pergunta não respondida
  const scrollToFirstUnansweredQuestion = () => {
    for (let i = 0; i < category.questions.length; i++) {
      const question = category.questions[i]
      const answer = answerQuestionsState.inputs
        .find((input: InputProps) => input.categoryId === category.id)
        ?.answers.find((a: AnswerProps) => a.questionId === question.id)
      if (!answer || answer.answer === null || answer.answer === '') {
        const questionElement = document.getElementById(`question-${i}`)
        if (questionElement) {
          const yOffset = -100
          const y =
            questionElement.getBoundingClientRect().top +
            window.scrollY +
            yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        }
        break
      }
    }
  }

  // Efeito para rolar para o topo ao mudar de etapa
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [currentStep])

  // Função para avançar de página e exibir toasts personalizados
  const handleNextPage = () => {
    const allQuestionsAnswered = category?.questions?.every((question) => {
      const categoryInput = answerQuestionsState.inputs.find(
        (input: InputProps) => input.categoryId === category.id
      )
      const answer = categoryInput?.answers.find(
        (a: AnswerProps) => a.questionId === question.id
      )
      if (question.type === 'open-ai-input') {
        if (
          !answer ||
          answer.answer === null ||
          typeof answer.answer !== 'string' ||
          answer.answer.length < 100
        ) {
          toast.warning(
            `Você não alcançou o mínimo de caracteres na última pergunta da categoria ${category.categoryName}.`
          )
          return false
        }
      } else if (question.type === 'agreement-choice') {
        if (!answer || answer.answer === null || answer.answer === '') {
          toast.warning(
            'Por favor, responda todas as perguntas antes de avançar.'
          )
          return false
        }
      }
      return true
    })

    if (allQuestionsAnswered) {
      switch (currentStep) {
        case 'BusinessManagement':
          setCurrentStep('MarketingAndGrowth')
          break
        case 'MarketingAndGrowth':
          setCurrentStep('Sales')
          break
        case 'Sales':
          setCurrentStep('CultureAndLeadership')
          break
        case 'CultureAndLeadership':
          setCurrentStep('CustomerExperience')
          break
        case 'CustomerExperience':
          createDiagnosis(answerQuestionsState)
          break
        default:
          console.error('Passo desconhecido:', currentStep)
      }
    } else {
      scrollToFirstUnansweredQuestion()
    }
  }

  function handleBackPage() {}

  // Retorna estados e funções para uso no componente
  return {
    selectedValues,
    textAnswers,
    shouldAnimateIcon,
    ref,
    handleBackPage,
    handleButtonClick,
    handleTextChange,
    setShouldAnimateIcon,
    handleNextPage, // Adiciona a função handleNextPage ao retorno
  }
}
