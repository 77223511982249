import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@/shared/components/ui/avatar'
import { UserRound } from 'lucide-react'
import {
  Column,
  IncomingData,
} from '../../components/table-programs-dashboard/table-programs-dashboard'

export const columns: Column[] = [
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Nome</span>
      </div>
    ),
    accessor: 'userName',
    width: '383px',
    render: (row: IncomingData) => (
      <div className="flex items-center gap-3">
        <Avatar>
          <AvatarImage src={row.picture || ''} />
          <AvatarFallback>
            <UserRound color="gray" />
          </AvatarFallback>
        </Avatar>
        <span className="line-clamp-1 px-3 text-body-md text-gray-500">
          {row.userName}
        </span>
      </div>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Trilhas</span>
      </div>
    ),
    accessor: 'programs',
    render: (row: IncomingData) => <span>{row.programName}</span>,
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Início</span>
      </div>
    ),
    accessor: 'startDate',
    render: (row: IncomingData) => (
      <span className="text-body-md text-gray-500">{row.startDate}</span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Fim</span>
      </div>
    ),
    accessor: 'endDate',
    render: (row: IncomingData) => (
      <span className="text-center text-body-md text-gray-500">
        {row.endDate || '-'}
      </span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Dias</span>
      </div>
    ),
    accessor: 'daysInProgress',
    render: (row: IncomingData) => (
      <span className="text-body-md text-gray-500">{row.daysInProgress}</span>
    ),
  },
  {
    header: () => (
      <div className="flex cursor-pointer items-center gap-2">
        <span className="title-sm text-gray-400">Progresso</span>
      </div>
    ),
    accessor: 'progress',
    render: (row: IncomingData) => (
      <span className="text-body-md text-gray-500">{row.progress}%</span>
    ),
  },
]
