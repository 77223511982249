import { UserErrorPage } from '@/features/user/error'
import { useUserQueryOption } from '@/shared/queries/use-user'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

export const Route = createFileRoute('/_authenticated/users/$userId')({
  wrapInSuspense: true,
  component: UserPage,
  params: {
    parse: ({ userId }) => ({
      userId: z.number().parse(Number(userId)),
    }),
    stringify: ({ userId }) => ({ userId: `${userId}` }),
  },
  loader: async ({ params, context: { queryClient } }) => {
    const { userId } = params
    return queryClient.ensureQueryData(useUserQueryOption(userId))
  },
  errorComponent: ({ reset }) => <UserErrorPage reset={reset} />,
})

function UserPage() {
  const { userId } = Route.useParams()
  const { data: user } = useSuspenseQuery(useUserQueryOption(userId))
  return <h1>user: {user.name}</h1>
}
