import { AssessmentErrorPage } from '@/features/diagnosis/error'
import { AssessmentLoading } from '@/features/diagnosis/loading'
import { AssessmentPage } from '@/features/diagnosis/page'
import { useGetDiagnosis } from '@/shared/queries/diagnosis/use-diagnosis'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_authenticated/diagnosis/')({
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(
      useGetDiagnosis({
        organizationId: 'cdd9517b-ed58-48aa-b4ba-e748ee54e83a',
      })
    ),
  pendingComponent: () => AssessmentLoading(),

  component: AssessmentPage,
  errorComponent: ({ reset }) => <AssessmentErrorPage reset={reset} />,
})
