import { Textarea } from '@/features/diagnosis/components/text-area/text-area'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/shared/components/tooltip'
import { Button } from '@/shared/components/ui/button'
import { GetAllQuestionsDiagnosisResponseProps } from '@/shared/queries/diagnosis/use-all-questions-diagnosis'
import { useCreateDiagnosis } from '@/shared/queries/diagnosis/use-create-diagnosis'
import { motion } from 'framer-motion'
import { Brain } from 'lucide-react'
import { useBodyQuestions } from '../../hooks/use-body-questions'
import { AnswerButtonGroup, ButtonValue } from '../answer-button/answer-button'

interface BodyQuestionProps {
  category: GetAllQuestionsDiagnosisResponseProps[0]
}

export function BodyQuestions({ category }: BodyQuestionProps) {
  const {
    selectedValues,
    textAnswers,
    shouldAnimateIcon,
    ref,
    handleButtonClick,
    handleNextPage,
    handleBackPage,
    handleTextChange,
    setShouldAnimateIcon,
  } = useBodyQuestions({ category })

  const { isPending } = useCreateDiagnosis()

  return (
    <div className="my-28 flex min-h-[calc(100vh_-_136px)] w-full justify-center">
      <div className="flex h-full w-full max-w-3xl flex-col items-center justify-center">
        {category?.questions?.map(
          (
            item: GetAllQuestionsDiagnosisResponseProps[0]['questions'][0],
            index: number
          ) => (
            <div
              key={index}
              id={`question-${index}`}
              className="flex w-full flex-col items-center justify-center gap-5 p-12"
            >
              <div
                className="flex select-none items-start justify-center text-center text-body-lg text-gray-450"
                key={item.topicId}
              >
                <span>
                  <span className="mr-1 text-brand-coral-500">
                    {item.order + 1}.
                  </span>
                  {item.title}
                  <span className="text-brand-coral-500">*</span>
                </span>
              </div>
              <div className="flex w-full flex-col items-center last:border-b-0">
                {item.type === 'agreement-choice' ? (
                  <div className="flex items-center justify-center">
                    <span className="mr-3 text-title-md text-brand-coral-500 lg:text-title-lg">
                      Discordo
                    </span>
                    <AnswerButtonGroup
                      selectedValue={selectedValues[item.id] || null}
                      onClick={(value: ButtonValue) =>
                        handleButtonClick(
                          value,
                          item.id, // Id da pergunta
                          item.title, // Nome da pergunta
                          item.topicId,
                          item.topicName, // Nome do tópico
                          item.type
                        )
                      }
                    />
                    <span className="ml-3 text-title-md text-blue-700 lg:text-title-lg">
                      Concordo
                    </span>
                  </div>
                ) : (
                  <div
                    className="relative flex h-[180px] w-full flex-col"
                    ref={ref}
                  >
                    <Textarea
                      value={textAnswers[item.id] || ''}
                      onChange={(event) =>
                        handleTextChange(
                          event,
                          item.id, // Id da pergunta
                          item.title, // Nome da pergunta
                          item.topicId,
                          item.topicName, // Nome do tópico
                          item.type
                        )
                      }
                    />
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <motion.div
                            className="absolute right-2 top-2 ml-3 h-auto w-auto cursor-pointer"
                            animate={
                              shouldAnimateIcon
                                ? { y: [0, -10, 0, -10, 0] }
                                : {}
                            }
                            transition={{
                              duration: 3,
                              repeat: 3,
                              repeatType: 'loop',
                              times: [0, 0.2, 0.4, 0.6, 1],
                            }}
                            onAnimationComplete={() =>
                              setShouldAnimateIcon(false)
                            }
                          >
                            <Brain width={20} />
                          </motion.div>
                        </TooltipTrigger>
                        <TooltipContent side="top" align="center">
                          <span>
                            Sua resposta será avaliada pela inteligência
                            artificial
                          </span>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <div className="mt-1 flex w-full items-center justify-between text-label-md text-gray-250">
                      <span>Mínimo de 100 caracteres</span>
                      <span>{textAnswers[item.id]?.length} caracteres</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        )}
        <div className="flex w-full items-center justify-between">
          <Button variant="text" onClick={handleBackPage}>
            Voltar
          </Button>
          <Button
            variant="filled"
            onClick={handleNextPage}
            disabled={isPending}
          >
            Avançar
          </Button>
        </div>
      </div>
    </div>
  )
}
