import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'

const programsCountSchema = z.object({
  inProgress: z.object({
    totalPrograms: z.number(),
    filteredProgramsCount: z.number(),
    percentage: z.number(),
  }),
  finished: z.object({
    totalPrograms: z.number(),
    filteredProgramsCount: z.number(),
    percentage: z.number(),
  }),
})

export type GetProgramsCountResponse = z.infer<typeof programsCountSchema>

export async function getProgramsCount() {
  const url = apiUrls.dashboard.programs.getProgramsCount
  const token = Cookies.get('auth-cookie') ?? ''

  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('API Response:', data)

    const parsedData = programsCountSchema.parse(data)
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useProgramsCountQueryKey = 'programs-count-query-key'

export const useProgramsCount = () => ({
  queryKey: [useProgramsCountQueryKey],
  queryFn: getProgramsCount,
})
