import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

type RadarChartProps = {
  series: number[]
  width?: string
  height?: string
  hiddenCategories?: boolean
  colorCategories?: string
  fontSize?: string
  size?: number
  theme?: 'light' | 'dark'
}

const categories = [
  'Gestão de Negócios',
  ['Marketing e', 'Growth'],
  'Vendas',
  ['Cultura e', 'Liderança'],
  ['Experiência', 'do cliente'],
]

const RadarChartDefault = ({
  series,
  width = '600px',
  height = '375px',
  hiddenCategories = false,
  colorCategories = '#000000A6',
  size = 100,
  fontSize = '11px',
  theme = 'light',
}: RadarChartProps) => {
  const options: ApexOptions = {
    chart: {
      type: 'radar',
      toolbar: { show: false },
    },
    plotOptions: {
      radar: {
        size: size,
        polygons: {
          strokeColors: 'rgba(117, 117, 117, 0.5)',
          connectorColors: 'rgba(117, 117, 117, 0.5)',
          fill: {
            colors:
              theme === 'light'
                ? [
                    'rgba(0, 0, 0, 0.05)',
                    'rgba(0, 0, 0, 0.05)',
                    'rgba(0, 0, 0, 0.07)',
                    'rgba(0, 0, 0, 0.1)',
                  ]
                : [
                    'rgba(255, 255, 255, 0.05)',
                    'rgba(255, 255, 255, 0.05)',
                    'rgba(255, 255, 255, 0.07)',
                    'rgba(255, 255, 255, 0.1)',
                  ],
          },
        },
      },
    },
    markers: {
      size: 0,
      colors: ['#fff'],
      strokeWidth: 2,
    },
    yaxis: {
      show: false,
      tickAmount: 4,
      min: 0,
      max: 100,
    },
    xaxis: {
      categories: !hiddenCategories ? categories : [],
      labels: {
        show: !hiddenCategories,
        trim: true,
        style: {
          colors: categories.flat().map(() => colorCategories), // Ajuste aqui para colorir categorias
          fontSize: fontSize,
          fontFamily: 'Arial',
          fontWeight: 600,
        },
      },
    },
  }

  return (
    <Chart
      options={options}
      series={[{ data: series }]}
      type="radar"
      height={height}
      width={width}
    />
  )
}

export default RadarChartDefault
