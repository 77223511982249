import { cn } from '../../helpers/shadcn'

interface ProgressBarTailwindProps {
  children: React.ReactNode
}
interface ProgressIndicatorProps {
  progress: number
  height: string
  classname?: string
}
interface ProgressLabelProps {
  progressLabel: number
}

export const ProgressIndicator = ({
  height,
  progress,
  classname,
}: ProgressIndicatorProps) => {
  function getProgress() {
    if (progress > 100) return 100
    if (progress < 0) return 0
    return progress
  }

  const roundedProgress = Math.round(getProgress())

  return (
    <div
      className={cn('rounded-full', classname, {
        'bg-blue-500': progress < 100,
        'bg-status-green-500': progress >= 100 && !classname,
      })}
      style={{ width: `${roundedProgress}%`, height: `${height}px` }}
    />
  )
}

export const ProgressLabel = ({ progressLabel }: ProgressLabelProps) => {
  return (
    <span className="text-label-xs text-gray-400">
      {Math.round(progressLabel)}%
    </span>
  )
}

const ProgressBarTailwind = ({ children }: ProgressBarTailwindProps) => {
  return (
    <div className="flex h-auto w-full overflow-hidden rounded-full bg-[#2D4A8C26]">
      {children}
    </div>
  )
}

export default ProgressBarTailwind
