import { accessDialogAtom } from '@/shared/components/chart-bar'
import { keepPreviousData } from '@tanstack/react-query'
import axios from 'axios'
import { useAtomValue } from 'jotai'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'

const userSchema = z.object({
  id: z.string(),
  name: z.string(),
  picture: z.string().nullable(),
})

const dataSchema = z.object({
  currentPage: z.number(),
  totalPages: z.number(),
  totalItems: z.number(),
  data: z.array(userSchema),
  links: z.array(
    z.object({
      rel: z.string(),
      href: z.string(),
    })
  ),
})

const dashboardGeneralUserVisitsUsersResponseSchema = z.object({
  data: dataSchema,
})

export type UserDashboardGeneralProps = z.infer<typeof userSchema>
export type UserDashboardGeneralListProps = z.infer<typeof dataSchema>

export type GetFormationUsersResponse = z.infer<
  typeof dashboardGeneralUserVisitsUsersResponseSchema
>

export async function getUserVisitUsers({
  date,
  size,
  page,
}: {
  date: string
  size: number
  page: number
}) {
  const url = apiUrls.dashboard.getUserVisitUsers
  const token = Cookies.get('auth-cookie') ?? ''

  const params: Record<string, any> = { date, size, page }
  try {
    const { data } = await axios.get(`${url}`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data)
    const parsedData = dashboardGeneralUserVisitsUsersResponseSchema.parse(data)
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetUserVisitsUsersQueryKey =
  'get-dashboard-user-visits-users-query-key'

export const useGetUserVisitsUsers = ({
  date,
  size,
  page,
}: {
  date: string
  size: number
  page: number
}) => {
  const isOpenAccessDialog = useAtomValue(accessDialogAtom)

  return {
    queryKey: [useGetUserVisitsUsersQueryKey, date, size, page],
    queryFn: () => getUserVisitUsers({ date, size, page }),
    placeholderData: keepPreviousData,
    enabled: isOpenAccessDialog,
  }
}
