import clsx from 'clsx'
import { Menu } from './menu'
import { useAtomValue } from 'jotai'
import { motion } from 'framer-motion'
import { sideBarMenuAtom } from '../header/header'
import useBreakpoint from '@/shared/hooks/use-breakpoint'

export function SidebarDashboard() {
  const sideBarMenu = useAtomValue(sideBarMenuAtom)

  const breakpoint = useBreakpoint()

  const sidebarWidth = breakpoint === 'xs' ? '100vw' : '14rem'

  return (
    <>
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: sideBarMenu ? sidebarWidth : '0px' }}
        transition={{ duration: 0.2, ease: [0.42, 0.3, 0.8, 1] }}
        className={clsx(
          'duration-800 z-[99] h-full transition-transform ease-in-out',
          {
            'translate-x-full bg-none': !sideBarMenu,
            'translate-x-0 bg-white': sideBarMenu,
          }
        )}
      >
        <div
          className={clsx('mt-16 flex h-full flex-col shadow-md', {
            'w-0 px-0 py-0': !sideBarMenu,
            'w-screen px-3 py-4': sideBarMenu && breakpoint === 'xs',
            'w-auto px-3 py-4': sideBarMenu && breakpoint !== 'xs',
          })}
        >
          <Menu isOpen={sideBarMenu} />
        </div>
      </motion.div>
    </>
  )
}
