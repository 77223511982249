import { ChartAreaLinear } from '@/shared/components/chart-area-linear'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/shared/components/ui/select'
import { useGetDashboardWatchedHours } from '@/shared/queries/dashboard/contents/use-dashboard-watched-hours'
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { atom, useAtom } from 'jotai'
import { CardShadow } from '../../../../components/card-shadow/card-shadow'
import { SectionHoursConsumedSkeleton } from './loading'
import useBreakpoint from '@/shared/hooks/use-breakpoint'
import { ComponentError } from '@/shared/components/ui/component-error'

const pathname = location.pathname

export type SelectedPeriodWatchedHoursProps = 'month' | 'year' | 'quarter'

export const selectedPeriodWatchedHours =
  atom<SelectedPeriodWatchedHoursProps>('year')

export function SectionHoursConsumed() {
  const [selectedOption, setSelectedOption] = useAtom(
    selectedPeriodWatchedHours
  )

  const breakpoint = useBreakpoint()

  const {
    data: dashboardWatchedHoursData,
    isLoading,
    isError,
    isFetching: isFetchingDashboardWatchedHours,
  } = useQuery(
    useGetDashboardWatchedHours({
      period: selectedOption,
    })
  )

  const mapOptionToLabel = (option: 'month' | 'year' | 'quarter'): string => {
    const mapping: Record<string, string> = {
      month: 'Mês',
      year: 'Ano',
      quarter: 'Trimestre',
    }
    return mapping[option] || option
  }

  const options = [
    { id: 'month', name: 'Mês' },
    { id: 'quarter', name: 'Trimestre' },
    { id: 'year', name: 'Ano' },
  ]

  if (isError)
    return (
      <div className="flex h-full min-h-[316px] w-full flex-col items-center justify-center">
        <ComponentError />
      </div>
    )
  if (isLoading) return <SectionHoursConsumedSkeleton />

  return (
    <div
      className={clsx('flex w-full flex-col gap-3 overflow-hidden', {
        'h-full': pathname === '/dashboard/contents',
        'h-[316px]': pathname !== '/dashboard/contents',
      })}
    >
      <CardShadow>
        <div className="mb-3 flex w-full items-center justify-between">
          <div className="flex w-full items-center">
            <span className="items-center text-title-md text-gray-500">
              Horas Consumidas
            </span>
          </div>

          <div className="flex w-full justify-end">
            <Select
              value={selectedOption}
              disabled={isFetchingDashboardWatchedHours}
              onValueChange={(value) =>
                setSelectedOption(value as SelectedPeriodWatchedHoursProps)
              }
            >
              <SelectTrigger
                className={clsx(
                  'max-h-[32px] min-h-[32px] min-w-[164px] max-w-[164px] rounded-md border border-gray-150 px-3 py-0',
                  {
                    'min-w-[120px] max-w-[120px]': breakpoint === 'xs',
                  }
                )}
              >
                <div className="flex w-full items-center justify-between gap-2">
                  <div className="flex items-center justify-start">
                    <span className="text-label-lg text-gray-300">
                      {mapOptionToLabel(selectedOption)}
                    </span>
                  </div>
                </div>
              </SelectTrigger>
              <SelectContent
                className={clsx('', {
                  'min-w-[120px] max-w-[120px]': breakpoint === 'xs',
                  'min-w-[164px] max-w-[164px]': breakpoint !== 'xs',
                })}
              >
                {options.map((option) => (
                  <SelectItem
                    className={clsx('flex items-center px-3 py-1', {
                      'bg-blue-500 text-white hover:bg-blue-500':
                        option.id === selectedOption,
                      'bg-white': option.id !== selectedOption,
                    })}
                    key={option.id}
                    value={option.id}
                    onClick={() =>
                      setSelectedOption(
                        option.id as SelectedPeriodWatchedHoursProps
                      )
                    }
                  >
                    {option.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex h-full w-full">
          <ChartAreaLinear
            height={200}
            data={
              dashboardWatchedHoursData?.map((item) => ({
                date: item.date,
                hourCount: item.hourCount,
                label: item.label,
                userCount: item.userCount,
                miniLabel: item.miniLabel,
              })) ?? []
            }
          />
        </div>
      </CardShadow>
    </div>
  )
}
