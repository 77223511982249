import axios from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'
import { apiUrls } from '../../api-urls'
import { keepPreviousData } from '@tanstack/react-query'

const teamSchema = z.object({
  id: z.string(),
  name: z.string(),
})

const dataSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  createdAt: z.string().nullable(),
  lastLogin: z.string().nullable(),
  picture: z.string().nullable(),
  books: z.object({
    in_progress: z.number(),
    completed: z.number(),
  }),
  courses: z.object({
    in_progress: z.number(),
    completed: z.number(),
  }),
  teams: z.array(teamSchema).nullable(),
})

const linkSchema = z.object({
  rel: z.string(),
  href: z.string(),
})

const dashboardPeopleResponseSchema = z.object({
  currentPage: z.number(),
  totalPages: z.number(),
  totalItems: z.number(),
  data: z.array(dataSchema),
  links: z.array(linkSchema),
})

export type GetDashboardPeopleResponse = z.infer<
  typeof dashboardPeopleResponseSchema
>

export async function getDashboardPeople({
  page,
  size,
  name,
  teamId,
  sort,
  sortBy,
}: {
  page: number
  size: number
  name?: string
  teamId?: string
  sort?: string
  sortBy?: string
}) {
  const url = apiUrls.dashboard.people.getDashboardPeople
  const token = Cookies.get('auth-cookie') ?? ''

  const params: Record<string, any> = { page, size }
  if (name) params.name = name
  if (teamId) params.teamId = teamId
  if (sort) {
    params.sort = sort
    params.sortBy = sortBy
  }

  try {
    const { data } = await axios.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Response data:', data) // Adicionando log para inspecionar a resposta
    const parsedData = dashboardPeopleResponseSchema.parse(data.data) // Acessando a propriedade data dentro do objeto data
    return parsedData
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Erro na requisição Axios:',
        error.response?.data || error.message
      )
    } else {
      console.error('Erro inesperado:', error)
    }
    throw error
  }
}

export const useGetDashboardPeopleQueryKey = 'get-dashboard-people-query-key'

export const useGetDashboardPeople = ({
  page = 1,
  size = 10,
  name,
  teamId,
  sort,
  sortBy,
}: {
  page: number
  size?: number
  name?: string
  teamId?: string
  sort?: string
  sortBy?: string
}) => ({
  queryKey: [
    useGetDashboardPeopleQueryKey,
    page,
    size,
    name,
    teamId,
    sort,
    sortBy,
  ],
  queryFn: () => getDashboardPeople({ page, size, name, teamId, sort, sortBy }),
  placeholderData: keepPreviousData,
})
